import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ProgressBar.scss';

const ProgressBar = ({
  currentStep,
  totalSteps,
  upload,
  error,
  complete,
  barClassName,
  fillClassName,
  fillDotClassName,
  fillDot,
  blue
}) => {
  const progressWidth = (currentStep / totalSteps) * 100;
  const progressBarClassName = classNames(barClassName, {
    'tmx-progressBar': !upload,
    'tmx-progressBar--upload': upload
  });
  const progressFillClassName = classNames(fillClassName, {
    'tmx-progressBar__fill': !upload,
    'tmx-progressBar--upload__fill': upload && !complete,
    'tmx-progressBar--upload__fill--green': upload && complete && !error,
    'tmx-progressBar--upload__fill--red': error
  });

  const progressFillDotClassName = classNames('tmx-progressBar__fillDot', fillDotClassName, {
    'tmx-progressBar__fillDot--blue': blue
  });

  return (
    <div className={progressBarClassName}>
      <div className={progressFillClassName} style={{ width: `${progressWidth}%` }}>
        {' '}
        {fillDot && <span className={progressFillDotClassName}> </span>}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  upload: PropTypes.bool,
  error: PropTypes.bool,
  complete: PropTypes.bool,
  barClassName: PropTypes.string,
  fillClassName: PropTypes.string,
  fillDotClassName: PropTypes.string,
  blue: PropTypes.bool,
  fillDot: PropTypes.bool
};

export default ProgressBar;

import {
  UPLOAD_SUCCESS,
  IS_UPLOADING,
  UPLOAD_FAILURE,
  MEDIA_ID,
  LOADING_DETAILS,
  SET_CONTENT_DETAILS
} from '../../constants/action-types';
import {
  ADD_ORGS_TO_LIST,
  SEARCH_URL,
  UNIVERSAL_UPLOADER,
  UPLOAD_GET_COMPANIES
} from '../../functionality/networking';
import { callAjaxWCognito } from '../../functionality/CognitoTMX';

const isLoading = (payload) => {
  return {
    type: IS_UPLOADING,
    payload
  };
};

const uploadStatus = (payload) => {
  return {
    type: UPLOAD_SUCCESS,
    payload
  };
};

const uploadFailure = (payload) => {
  return {
    type: UPLOAD_FAILURE,
    payload
  };
};

const setMediaId = (payload) => {
  return {
    type: MEDIA_ID,
    payload
  };
};

const loadingDetails = (payload) => {
  return {
    type: LOADING_DETAILS,
    payload
  };
};

const setContentDetails = (payload) => {
  return {
    type: SET_CONTENT_DETAILS,
    payload
  };
};

export const publish = (values) => (dispatch) => {
  dispatch(isLoading(true));
  dispatch(uploadFailure(false));
  if (!values.files.length) {
    delete values.files;
  }
  callAjaxWCognito(
    UNIVERSAL_UPLOADER,
    (res) => {
      const response = JSON.parse(res);
      if (response.success === true) {
        dispatch(setMediaId(response.media_id));
        dispatch(uploadStatus(true));
        dispatch(isLoading(false));
        dispatch(uploadFailure(false));
      }
    },
    () => {
      dispatch(isLoading(false));
      dispatch(uploadStatus(false));
      dispatch(uploadFailure(true));
    },
    JSON.stringify(values)
  );
};
export const searchContent = (mediaId) => (dispatch) => {
  dispatch(loadingDetails(true));
  // dispatch(uploadFailure(false))
  callAjaxWCognito(
    SEARCH_URL,
    (res) => {
      const response = JSON.parse(res);
      if (response.length) {
        dispatch(setContentDetails({ ...response[0] }));
        dispatch(loadingDetails(false));
      }
    },
    () => {
      dispatch(loadingDetails(false));
    },
    JSON.stringify({ mediaId })
  );
};

export const getOriginalOrgData = (handleSuccess) => {
  callAjaxWCognito(UPLOAD_GET_COMPANIES, handleSuccess, () => {}, JSON.stringify({}));
};

export const addOrg = (org, handleSuccess, handleError) => {
  callAjaxWCognito(ADD_ORGS_TO_LIST, handleSuccess, handleError, JSON.stringify({ org_name: org }));
};

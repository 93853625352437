import IS_TEST from './IsTest';

export const API_BASE_URL = IS_TEST
  ? 'https://ggl0gkxvob.execute-api.us-west-2.amazonaws.com/dev/'
  : 'https://vajrozyb08.execute-api.us-west-2.amazonaws.com/dev/';

export const GET_USER_DETAILS = API_BASE_URL + 'get-user-details';
export const ADD_ORGS_TO_LIST = API_BASE_URL + 'admin-original-uploader';
export const GET_ADMIN_ORGS = API_BASE_URL + 'admin-orgs';
export const SEARCH_URL = API_BASE_URL + 'search-function';
export const GET_EMBED_QUICKSIGHT = API_BASE_URL + 'embed-quicksight';
export const SIGN_UP_INVITE = API_BASE_URL + 'sign-up-invite';
export const UPLOAD_GET_COMPANIES = API_BASE_URL + 'upload-get-companies';
export const PAYMENT_METHOD_INVITE = API_BASE_URL + 'stripe-send-paymethod-invite';
export const NEWSROOM_PAYMENT_METHOD = API_BASE_URL + 'admin-org-update-fee-cansellflag';
export const EDIT_MEMBERSHIP = API_BASE_URL + 'add-memberships-edit-log';
export const GET_PAYMENT_STATUS = API_BASE_URL + 'stripe-get-cust-sub-paymethod-status';
export const VERIFY_BANK_ACCOUNT = API_BASE_URL + 'stripe-org-bank-account-verify';
export const RECOMMENDER_API = API_BASE_URL + 'recommender';
export const GET_NON_MEMBER_SELLERS = API_BASE_URL + 'get-list-non-member-sellers';
export const GET_NON_MEMBER_SELLER_DETAILS = API_BASE_URL + 'non-member-seller-details';
export const CREATE_NON_MEMBER_SELLER = API_BASE_URL + 'non-member-seller-create';
export const SEND_BANK_ACCOUNT_INVITE = API_BASE_URL + 'non-member-seller-invite-for-bank-account';
export const SELLER_MEDIA_DETAILS = API_BASE_URL + 'non-member-seller-media-details';
export const SELLER_MEDIA_COST = API_BASE_URL + 'non-member-seller-media-cost';
export const SELLER_TRANSFER = API_BASE_URL + 'non-member-seller-transfer';
export const ADD_ASSOCIATE_CONTENT = API_BASE_URL + 'non-member-seller-associate-media';
export const NON_MEMBER_REVENUE_SHARE = API_BASE_URL + 'non-member-seller-revenueshare';
export const MARKETING_CAROUSEL = API_BASE_URL + 'marketing-carousel';
export const EMAIL_ALERTS = API_BASE_URL + 'summary-email';
export const EXCHANGE_GET_COLLECTIONS_PROFILE = API_BASE_URL + 'exchange-get-collections-profile';
export const FILE_UPLOAD = API_BASE_URL + 'contacts-upload-file';
export const UNIVERSAL_UPLOADER = API_BASE_URL + 'contacts-upload-complete';
export const EXPERT_LIST = API_BASE_URL + 'expert-requests';
export const ACCESS_PROFILES = API_BASE_URL + 'access-profiles';
export const ACCESS_UPLOAD_FILE = API_BASE_URL + 'access-upload-file';
// Cognito
export const USER_POOL_ID = IS_TEST ? 'us-west-2_2eOqayrwM' : 'us-west-2_2q7z0jxtK';
export const CLIENT_ID = IS_TEST ? '1gqrah811f3kpkt4hvkss9rg0h' : '2l1t5cma4daeat1rhejq4uocqf';

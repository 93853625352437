import {
  ADMIN_ORG_RESPONSE,
  ADMIN_ORGS_ERROR,
  ADMIN_ORGS_LOADING,
  ADMIN_ORGS_RESPONSE,
  CLEAR_CREATE_ADMIN_ORG_ERROR,
  CREATE_ADMIN_ORG_ERROR,
  EDIT_MEMBERSHIP_ERROR,
  EDIT_MEMBERSHIP_LOADING,
  EDIT_MEMBERSHIP_RESPONSE,
  EDIT_ORG_LOADING,
  EDIT_ORG_RESPONSE,
  EMBED_QUICKSIGHT_ERROR,
  EMBED_QUICKSIGHT_LOADING,
  EMBED_QUICKSIGHT_RESPONSE,
  INVITE_ERROR,
  INVITE_LOADING,
  INVITES_SENT_SUCCESS,
  LOADING_MULTISELECT_LIST,
  NUMBER_OF_INVITES,
  PAYMENT_INVITE_ERROR,
  PAYMENT_INVITE_LOADING,
  PAYMENT_INVITE_RESPONSE,
  PAYMENT_STATUS_ERROR,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_RESPONSE,
  NEWSROOM_PAYMENT_STATUS_RESPONSE,
  NEWSROOM_PAYMENT_STATUS_ERROR,
  NEWSROOM_PAYMENT_STATUS_LOADING,
  RECEIVE_ALL_MULTI_SELECT_LISTS,
  RESET_INVITES_SENT_SUCCESS,
  VERIFY_BANK_ERROR,
  VERIFY_BANK_LOADING,
  VERIFY_BANK_RESPONSE
} from '../../constants/action-types';

const initialState = {
  createError: false,
  editLoading: false,
  editMembershipData: {},
  editMembershipError: false,
  editMembershipLoading: false,
  editMembershipSuccess: false,
  editSuccess: false,
  error: false,
  inviteError: false,
  inviteLoading: false,
  invitesSentSuccess: false,
  loading: false,
  loadingDetails: false,
  loadingMulti: false,
  loadingQuickSight: false,
  locationsArray: [],
  numberOfInvites: 0,
  organizations: {},
  orgArray: [],
  paymentError: false,
  paymentInviteData: {},
  paymentInviteError: false,
  paymentInviteLoading: false,
  paymentInviteSuccess: false,
  NewsroomPaymentSuccess: false,
  NewsroomPaymentError: false,
  NewsroomPaymentLoading: false,
  paymentLoading: false,
  paymentStatusData: {},
  paymentSuccess: false,
  quickSight: {},
  quickSightError: false,
  quickSightSuccess: false,
  singleOrg: {},
  tagsArray: [],
  verifyBankData: {},
  verifyBankError: false,
  verifyBankLoading: false,
  verifyBanktSuccess: false,
  verifyError: '',
  paymentInviteErrorMsg: ''
};

export default function orgReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_ORGS_LOADING:
      return {
        ...initialState,
        loading: true,
        error: false,
        success: false
      };
    case PAYMENT_STATUS_LOADING:
      return {
        ...state,
        paymentLoading: true,
        paymentError: false,
        paymentSuccess: false
      };
    case NEWSROOM_PAYMENT_STATUS_LOADING:
      return {
        ...state,
        NewsroomPaymentLoading: true,
        NewsroomPaymentSuccess: false,
        NewsroomPaymentError: false
      };
    case VERIFY_BANK_LOADING:
      return {
        ...state,
        verifyBankLoading: true,
        verifyBankError: false,
        verifyBanktSuccess: false
      };
    case VERIFY_BANK_ERROR:
      return {
        ...state,
        verifyBankLoading: false,
        verifyBankError: true,
        verifyError: action?.verifyError?.errmsg,
        verifyBanktSuccess: false
      };
    case PAYMENT_INVITE_LOADING:
      return {
        ...state,
        paymentInviteLoading: true,
        paymentInviteError: false,
        paymentInviteSuccess: false
      };
    case EDIT_MEMBERSHIP_LOADING:
      return {
        ...state,
        editMembershipLoading: true,
        editMembershipError: false,
        editMembershipSuccess: false
      };
    case EDIT_MEMBERSHIP_RESPONSE:
      return {
        ...state,
        editMembershipLoading: false,
        editMembershipError: false,
        editMembershipSuccess: true,
        editMembershipData: action.value
      };
    case VERIFY_BANK_RESPONSE:
      return {
        ...state,
        verifyBankLoading: false,
        verifyBankError: false,
        verifyBanktSuccess: true,
        verifyBankData: action.value
      };
    case PAYMENT_INVITE_RESPONSE:
      return {
        ...state,
        paymentInviteLoading: false,
        paymentInviteError: false,
        paymentInviteSuccess: true,
        paymentInviteData: action.value
      };
    case PAYMENT_INVITE_ERROR:
      return {
        ...state,
        paymentInviteLoading: false,
        paymentInviteError: true,
        paymentInviteSuccess: false,
        paymentInviteErrorMsg: action?.paymentInviteErrorMsg?.errmsg
      };
    case EDIT_MEMBERSHIP_ERROR:
      return {
        ...state,
        editMembershipLoading: false,
        editMembershipError: true,
        editMembershipSuccess: false
      };
    case EDIT_ORG_LOADING: {
      return {
        ...state,
        editLoading: true,
        error: false,
        success: false
      };
    }
    case INVITE_LOADING: {
      return {
        ...state,
        inviteLoading: true,
        error: false,
        success: false
      };
    }
    case ADMIN_ORGS_ERROR:
      return {
        ...state,
        error: true,
        createError: false,
        loading: false,
        success: false,
        editLoading: false
      };
    case PAYMENT_STATUS_ERROR:
      return {
        ...state,
        paymentLoading: false,
        paymentError: true,
        paymentSuccess: false
      };
    case NEWSROOM_PAYMENT_STATUS_ERROR:
      return {
        ...state,
        NewsroomPaymentError: true,
        NewsroomPaymentLoading: false,
        NewsroomPaymentSuccess: false
      };
    case INVITE_ERROR:
      return { ...state, inviteError: true, loading: false, success: false, inviteLoading: false };
    case NUMBER_OF_INVITES:
      return {
        ...state,
        numberOfInvites: action.numberOfInvites
      };
    case RESET_INVITES_SENT_SUCCESS:
      return {
        ...state,
        invitesSentSuccess: false,
        inviteError: false
      };
    case INVITES_SENT_SUCCESS:
      return {
        ...state,
        invitesSentSuccess: true,
        inviteLoading: false,
        inviteError: false
      };
    case CREATE_ADMIN_ORG_ERROR:
      return {
        ...state,
        error: false,
        createError: true,
        loading: false,
        success: false,
        editLoading: false
      };
    case CLEAR_CREATE_ADMIN_ORG_ERROR:
      return {
        ...state,
        error: false,
        createError: false,
        loading: false,
        success: false,
        editLoading: false
      };
    case ADMIN_ORG_RESPONSE:
      return {
        ...state,
        error: false,
        createError: false,
        loading: false,
        editLoading: false,
        success: true,
        singleOrg: action.value
      };
    case PAYMENT_STATUS_RESPONSE:
      return {
        ...state,
        paymentLoading: false,
        paymentError: false,
        paymentSuccess: true,
        paymentStatusData: action.value
      };
    case NEWSROOM_PAYMENT_STATUS_RESPONSE:
      return {
        ...state,
        NewsroomPaymentSuccess: true,
        NewsroomPaymentError: true,
        NewsroomPaymentLoading: false
      };
    case ADMIN_ORGS_RESPONSE:
      return {
        ...state,
        error: false,
        loading: false,
        createError: false,
        editLoading: false,
        success: true,
        organizations: action.value
      };
    case EDIT_ORG_RESPONSE:
      return {
        error: false,
        loading: false,
        createError: false,
        editLoading: false,
        editSuccess: true,
        singleOrg: state.singleOrg,
        organizations: state.organizations
      };
    case LOADING_MULTISELECT_LIST:
      return { ...state, loadingMulti: true };
    case RECEIVE_ALL_MULTI_SELECT_LISTS:
      return {
        ...state,
        orgArray: action.multiSelectLists.orgArray,
        locationsArray: action.multiSelectLists.locationsArray,
        tagsArray: action.multiSelectLists.tagsArray,
        mediaMarkets: action.multiSelectLists.mediaMarkets,
        loadingMulti: false
      };
    case EMBED_QUICKSIGHT_LOADING:
      return {
        ...state,
        loadingQuickSight: true,
        quickSightError: false,
        quickSightSuccess: false
      };
    case EMBED_QUICKSIGHT_ERROR:
      return {
        ...state,
        quickSightError: true,
        loadingQuickSight: false,
        quickSightSuccess: false
      };
    case EMBED_QUICKSIGHT_RESPONSE:
      return {
        ...state,
        quickSightError: false,
        loadingQuickSight: false,
        quickSightSuccess: true,
        quickSight: action.value
      };
    default:
      return state;
  }
}

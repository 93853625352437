import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Dropdown from '../../atoms/DropDown/DropDown';
import Input from '../../atoms/Input/Input';
import TextArea from '../../atoms/TextArea/TextArea';
import { ReactComponent as Delete } from '../../../images/delete.svg';

import './NewsroomsUsingContent.scss';

const NewsroomsUsingContent = ({
  index,
  newsroomsUsing,
  newsroomUsingDesc,
  newsroomUsingLink,
  newsroomUsingName,
  setNewsroomsUsing
}) => {
  const { orgArray } = useSelector((state) => state.orgReducer);

  const sortedOrgArray = orgArray.sort((a, b) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const handleNewsroomUsingDetailChange = (event, field) => {
    const values = [...newsroomsUsing];
    values[index][field] = event.target.value;
    setNewsroomsUsing(values);
  };

  const handleRemoveForm = (index) => {
    const values = [...newsroomsUsing];
    values.splice(index, 1);
    setNewsroomsUsing(values);
  };

  return (
    <div className="tmx-newsroomsUsing">
      <div className="tmx-newsroomsUsing__labelDeleteWrapper">
        <div className="tmx-newsroomsUsing__labelWrapper">Name of newsroom</div>
        {index > 0 && (
          <div className="tmx-newsroomsUsing__deleteWrapper">
            <Delete style={{ cursor: 'pointer' }} onClick={() => handleRemoveForm(index)} />
          </div>
        )}{' '}
      </div>
      <Dropdown
        onChange={(event) => {
          handleNewsroomUsingDetailChange(event, 'name');
        }}
        className="tmx-newsroomsUsing__newsroomDropdown grey"
        data={sortedOrgArray}
        optionSelected={newsroomUsingName}
        name="Select newsroom"
        setOptionsToUpperCase
        allowReset
      />

      <Input
        id="NewsroomLink"
        className={'tmx-newsroomsUsing__linkInput'}
        type="text"
        disablePastTime
        disabled={false}
        onChange={(event) => {
          handleNewsroomUsingDetailChange(event, 'link');
        }}
        value={newsroomUsingLink}
        greyBorder
        label={'Newsroom link about content'}
        labelClassName={'tmx-newsroomsUsing__linkInputLabel'}
      />

      <TextArea
        id="Newsroom using description"
        className={'tmx-newsroomsUsing__description'}
        maxRows={100}
        onChange={(event) => handleNewsroomUsingDetailChange(event, 'description')}
        darkBorder
        placeholder={'Description from the creator'}
        value={newsroomUsingDesc}
        statusBar
        maxCharacters={10000}
        label={'Newsroom’s description about content'}
        htmlFor="Newsroom using description"
      />
    </div>
  );
};

NewsroomsUsingContent.propTypes = {
  index: PropTypes.number,
  newsroomUsingDesc: PropTypes.string,
  newsroomUsingLink: PropTypes.string,
  newsroomUsingName: PropTypes.string,
  newsroomsUsing: PropTypes.array,
  setNewsroomsUsing: PropTypes.func,
  setNewsroomUsingDesc: PropTypes.func,
  setNewsroomUsingLink: PropTypes.func
};

export default NewsroomsUsingContent;

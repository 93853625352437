import React from 'react';

import './Springer.scss';

const Springer = () => {
  return (
    <section className="tmx-springer">
      <div className="tmx-springer__overallContainer">
        <h3 className="tmx-dashboard__title">Springer</h3>
        <iframe src="https://springer.tmx.news" className="tmx-springer__iframe" title="springer" />
      </div>
    </section>
  );
};

export default Springer;

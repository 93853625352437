import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../atoms/Button/Button';
import { toLocalTime } from '../UploadForm/UploadForm';
import PropTypes from 'prop-types';
import { ReactComponent as Add } from '../../../images/plusIcon.svg';
import { formattedDate } from '../../../utils/formatDateAndTime';
import './uploadSuccess.scss';

const UploadSuccess = ({ mediaId, scheduled, date }) => {
  const { media_id } = useSelector((state) => state.universalUploader);

  const currentDate = toLocalTime(new Date()).slice(0, new Date().toISOString().lastIndexOf(':'));

  function checkIsAfter(currentDate, selectedDate) {
    return moment(selectedDate).isAfter(currentDate);
  }

  return (
    <div className="upload_success_container">
      <h1>Success!</h1>
      <p>
        {!scheduled || !checkIsAfter(currentDate, date)
          ? 'Your content was uploaded successfully to TMX!'
          : scheduled && checkIsAfter(currentDate, date)
          ? `This will be uploaded on ${formattedDate(date)} EST`
          : ''}
      </p>
      {!scheduled || !checkIsAfter(currentDate, date) ? (
        <p className="media_link">
          View:{' '}
          <a
            href={`https://usetmx.com/details?cId=${
              media_id || mediaId
            }`}>{`usetmx.com/details?cId=${media_id || mediaId}`}</a>
        </p>
      ) : null}
      <Button className="upload_another" handleClick={() => window.location.reload()}>
        <Add />
        Upload another
      </Button>
    </div>
  );
};

export default UploadSuccess;

UploadSuccess.propTypes = {
  mediaId: PropTypes.any,
  date: PropTypes.string,
  scheduled: PropTypes.bool
};

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import './Navigation.scss';

import Button from '../../atoms/Button/Button';

import { logOut } from '../../../api/actions/signIn-actions';
import useDetectDevice from '../../../functionality/Hooks/detectDevice';

const Navigation = () => {
  const history = useHistory();
  const isMobile = useDetectDevice(430);
  const isTablet = useDetectDevice(806);

  const [location, setLocation] = useState(history.location.pathname);
  const [navBar, setNavbar] = useState(false);

  const dispatch = useDispatch();

  const { loggedIn } = useSelector((state) => state.signInReducer);

  const handleShadow = useCallback(() => {
    if (window.scrollY >= 10 && history.location.pathname !== '/details') {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }, [history.location.pathname]);
  useEffect(() => {
    window.addEventListener('scroll', handleShadow);
  }, [handleShadow]);

  const navClassName = classNames('tmx-navigation', {
    'tmx-navigation--shadow': navBar
  });

  return (
    <>
      {loggedIn && (
        <>
          <nav className={navClassName}>
            <div className="tmx-navigation__actions">
              <Button
                iconSize="medium"
                className={classNames(
                  'tmx-navigation__disableLink tmx-navigation__disableLink--helperText'
                )}>
                Admin
              </Button>
              <Button
                iconSize="medium"
                className="tmx-navigation__link"
                handleClick={() => {
                  setLocation('/organization-settings');
                  history.push('/organization-settings');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active':
                      location === '/organization-settings' || location === '/organization-details'
                  })}>
                  {isMobile || isTablet ? 'Orgs' : 'Org Settings'}
                </span>
              </Button>
              <Button
                iconSize="medium"
                className="tmx-navigation__link"
                handleClick={() => {
                  setLocation('/expert-profile-settings');
                  history.push('/expert-profile-settings');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active':
                      location === '/expert-profile-settings' ||
                      location === '/expert-profile-details/:id'
                  })}>
                  {isMobile || isTablet ? 'Access' : 'Access'}
                </span>
              </Button>
              <Button
                iconSize="medium"
                className={classNames('tmx-navigation__link', {
                  'tmx-navigation__link--active':
                    location === '/non-member-sellers' ||
                    location === '/seller-details' ||
                    location === '/seller-media-details'
                })}
                handleClick={() => {
                  setLocation('/non-member-sellers');
                  history.push('/non-member-sellers');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active':
                      location === '/non-member-sellers' ||
                      location === '/seller-details' ||
                      location === '/seller-media-details'
                  })}>
                  {isMobile || isTablet ? 'Sellers' : 'Non-member Sellers'}
                </span>
              </Button>
              {/* <Button
                iconSize="medium"
                className={classNames('tmx-navigation__link', {
                  'tmx-navigation__link--active': location === '/dashboard'
                })}
                handleClick={() => {
                  setLocation('/dashboard');
                  history.push('/dashboard');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active': location === '/dashboard'
                  })}>
                  {isMobile || isTablet ? 'KPIs' : 'KPI Dashboard'}
                </span>
              </Button> */}
              <Button
                iconSize="medium"
                className={classNames('tmx-navigation__link', {
                  'tmx-navigation__link--active':
                    location === '/tools' || location === '/marketing-carousel'
                })}
                handleClick={() => {
                  setLocation('/tools');
                  history.push('/tools');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active':
                      location === '/tools' || location === '/marketing-carousel'
                  })}>
                  Tools
                </span>
              </Button>
              <Button
                iconSize="medium"
                className={classNames('tmx-navigation__link', {
                  'tmx-navigation__link--active': location === '/springer'
                })}
                handleClick={() => {
                  setLocation('/springer');
                  history.push('/springer');
                }}>
                <span
                  className={classNames('tmx-navigation__linkATag', {
                    'tmx-navigation__linkATag--active': location === '/springer'
                  })}>
                  {isMobile ? 'Spr' : isTablet ? 'Springer' : 'Springer'}
                </span>
              </Button>
              <Button
                iconSize="medium"
                className={classNames('tmx-navigation__link', {
                  'tmx-navigation__link--active': location === '/springer'
                })}
                handleClick={() => {
                  dispatch(logOut());
                }}>
                <span className={classNames('tmx-navigation__linkATag')}>Sign Out</span>
              </Button>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default Navigation;

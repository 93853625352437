import {
  GET_CAROUSEL,
  RECEIVE_CAROUSEL,
  GET_CAROUSEL_ERROR,
  GET_EXPERTLIST,
  RECEIVE_EXPERTLIST,
  GET_EXPERTLIST_ERROR,
  GET_EMAIL_ALERT_ITEMS,
  GET_EMAIL_ALERTS_ERROR,
  RECEIVE_EMAIL_ALERT_ITEMS
} from '../../constants/action-types';

import { MARKETING_CAROUSEL, EMAIL_ALERTS, EXPERT_LIST } from '../../functionality/networking';

import { callAjaxWCognito } from '../../functionality/CognitoTMX';

function getCarousel() {
  return {
    type: GET_CAROUSEL
  };
}

function getCarouselError() {
  return {
    type: GET_CAROUSEL_ERROR
  };
}

function receiveCarousel(value) {
  return {
    type: RECEIVE_CAROUSEL,
    value
  };
}

function getExpertList() {
  return {
    type: GET_EXPERTLIST
  };
}

function getExpertListError() {
  return {
    type: GET_EXPERTLIST_ERROR
  };
}

function receiveExpertList(value) {
  return {
    type: RECEIVE_EXPERTLIST,
    value
  };
}

function getEmailAlertItems() {
  return {
    type: GET_EMAIL_ALERT_ITEMS
  };
}

function getEmailAlertsError() {
  return {
    type: GET_EMAIL_ALERTS_ERROR
  };
}

function receiveEmailAlertItems(value) {
  return {
    type: RECEIVE_EMAIL_ALERT_ITEMS,
    value
  };
}

export function marketingCarousel(payload, callBack) {
  return (dispatch) => {
    if (!callBack) {
      dispatch(getCarousel());
    }

    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      dispatch(receiveCarousel(parsedResponse.bricks));
      if (callBack) {
        callBack();
      }
    };
    const handleError = (response) => {
      console.warn(response);
      dispatch(getCarouselError());
      if (callBack) {
        callBack();
      }
    };

    callAjaxWCognito(MARKETING_CAROUSEL, handleSuccess, handleError, JSON.stringify(payload));
  };
}

export function emailAlerts(payload, callBack) {
  return (dispatch) => {
    if (!callBack) {
      dispatch(getEmailAlertItems());
    }

    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      dispatch(receiveEmailAlertItems(parsedResponse.bricks));
      if (callBack) {
        callBack();
      }
    };
    const handleError = (response) => {
      console.warn(response);
      dispatch(getEmailAlertsError());
      if (callBack) {
        callBack();
      }
    };

    callAjaxWCognito(EMAIL_ALERTS, handleSuccess, handleError, JSON.stringify(payload));
  };
}

export function expertListAPI(payload, callBack) {
  return (dispatch) => {
    if (!callBack) {
      dispatch(getExpertList());
    }

    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      dispatch(receiveExpertList(parsedResponse));
      if (callBack) {
        callBack();
      }
    };
    const handleError = (response) => {
      console.warn(response);
      dispatch(getExpertListError());
      if (callBack) {
        callBack();
      }
    };

    callAjaxWCognito(EXPERT_LIST, handleSuccess, handleError, JSON.stringify(payload));
  };
}

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import './SignInForm.scss';

import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';

const SignInForm = ({ handleLogin, loading }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { error } = useSelector((state) => state.signInReducer);

  const loginCallback = useCallback(
    (event) => {
      event.preventDefault();

      handleLogin(username, password);
    },
    [handleLogin, username, password]
  );
  const disableLogin = !(username && password);

  return (
    <section className="tmx-signInForm">
      <div className="tmx-signInForm__formError">
        {error ? 'Your email and password do not match' : ''}
      </div>
      <form className="tmx-signInForm__form" onSubmit={loginCallback}>
        <div className="tmx-signInForm__formControl">
          <Input
            darkBorder
            type="text"
            name="username"
            id="username"
            onChange={(event) => setUsername(event.target.value)}
            value={username}
            label={'Username'}
            htmlFor={'username'}
          />
        </div>
        <div className="tmx-signInForm__formControl">
          <Input
            darkBorder
            type="password"
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            label={'Password'}
            htmlFor={'password'}
          />
        </div>
        {!loading ? (
          <Button disabled={disableLogin} className="tmx-signInForm__loginButton" buttonType="blue">
            Sign In
          </Button>
        ) : (
          <Button
            type="button"
            className="tmx-signInForm__loginLoadingButton"
            spinner={loading}
            disabled={loading}
            buttonType="blue">
            Sign In
          </Button>
        )}
      </form>
    </section>
  );
};

SignInForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default SignInForm;

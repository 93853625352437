import React, { useState, useEffect } from 'react';

import './AddOrgs.scss';

import Button from '../../components/atoms/Button/Button';
import DropDown from '../../components/atoms/DropDown/DropDown';

import { addOrg, getOriginalOrgData } from '../../api/actions/universal-uploader-actions';

const AddOrgs = () => {
  const [orgName, setOrgName] = useState('');
  const [orgsData, setOrgsData] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const setMediaData = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.success) {
      setOrgsData(parsedResponse.companies);
    }
  };

  const setError = (error) => {
    setSuccess(false);
    setLoading(false);
    const parsedError = JSON.parse(error);
    if (parsedError.errmsg.includes('Duplicate')) {
      setErrorMsg('Duplicate org');
    }
  };

  const handleSuccess = () => {
    setLoading(false);
    setSuccess(true);
  };

  useEffect(() => {
    getOriginalOrgData(setMediaData, setError);
  }, []);

  return (
    <div className="tmx-uploadOrg">
      <div className="tmx-uploadOrg__Container">
        <h1>Add Org to Dropdown</h1>
        {errorMsg.length ? <p className="tmx-uploadOrg__duplicateOrg">Org already exists</p> : null}
        {success ? <p className="tmx-uploadOrg__OrgAdded">Org added</p> : null}
        <div className="tmx-uploadOrg__SelectOrg">
          <div className="tmx-uploadOrg__SelectOrg__label">
            Choose existing org <sup>*</sup>
          </div>
          <DropDown
            onChange={(event) => {
              setOrgName(event.target.value);
            }}
            className="tmx-selectOrgForm__orgDropDown grey"
            data={orgsData}
            name="Select an org"
            targetName={'company_name'}
            targetId={'company_id'}
            setOptionsToUpperCase
          />
        </div>
        <Button
          className="tmx-uploadOrg__button"
          spinner={loading}
          disabled={!orgName.length}
          handleClick={() => {
            setLoading(true);
            setErrorMsg('');
            setSuccess(false);
            addOrg(orgName, handleSuccess, setError);
          }}>
          {loading ? 'Uploading Org...' : 'Add to upload dropdown'}{' '}
        </Button>
        <p>
          Note: this is to add only existing orgs into the dropdown list for the admin uploaders on
          Cleared Content and Pitches. If you require a dummy org (like PopNation, Weather Traker,
          etc.) be added, please contact Kun.{' '}
        </p>
      </div>
    </div>
  );
};

export default AddOrgs;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DropDown.scss';
import dropDownArrow from './Image/chevron.svg';

const DropDown = ({
  className,
  data,
  disabled,
  disableOnBlur,
  enableValue,
  greyBorder,
  name,
  onChange,
  optionSelected,
  setOptionsToUpperCase,
  targetId,
  targetName,
  allowReset
}) => {
  const classes = classNames('tmx-dropDown', className, { grey: greyBorder });
  const callback = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <select
      style={{ backgroundImage: `url(${dropDownArrow})` }}
      className={classes}
      disabled={disabled}
      onBlur={!disableOnBlur ? callback : null}
      defaultValue={optionSelected}
      value={enableValue ? optionSelected : null}
      onChange={callback}>
      {name ? (
        <option value="" disabled={allowReset ? !allowReset : true} selected>
          {name}
        </option>
      ) : null}
      {data.map((data, key) => {
        if (typeof data === 'object') {
          return (
            <option key={key} value={data[targetId]}>
              {setOptionsToUpperCase ? data[targetName].toUpperCase() : data[targetName]}
            </option>
          );
        } else {
          return (
            <option selected={optionSelected === data ? 'selected' : ''} key={key} value={data}>
              {setOptionsToUpperCase ? data.toUpperCase() : data}
            </option>
          );
        }
      })}
    </select>
  );
};

DropDown.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disableOnBlur: PropTypes.bool,
  enableValue: PropTypes.bool,
  greyBorder: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionSelected: PropTypes.any,
  setOptionsToUpperCase: PropTypes.bool,
  targetId: PropTypes.string,
  targetName: PropTypes.string,
  allowReset: PropTypes.bool
};

DropDown.defaultProps = {
  defaultValue: '',
  disabled: false,
  disableOnBlur: false,
  enableValue: false,
  greyBorder: false,
  name: '',
  optionSelected: null,
  setOptionsToUpperCase: false,
  allowReset: false
};

export default DropDown;

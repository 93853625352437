import {
  GET_SELLERS,
  RECEIVE_SELLERS,
  GET_SELLER,
  RECEIVE_SELLER,
  GET_SELLERS_ERROR,
  CREATE_SELLER_ERROR,
  CREATE_SELLER,
  SEND_SELLER_ACCOUNT_INVITE,
  COMPLETE_SELLER_ACCOUNT_INVITE,
  GET_SELLER_MEDIA_DETAILS,
  GET_SELLER_MEDIA_DETAILS_ERROR,
  RECEIVE_SELLER_MEDIA_DETAILS
} from '../../constants/action-types';

const initialState = {
  createError: false,
  error: false,
  loading: false,
  loadingDetails: false,
  sellers: [],
  sellerDetails: {},
  sendingBankInvite: false,
  mediaDetails: {},
  loadingMediaDetails: false
};

export default function sellersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SELLERS:
      return {
        ...state,
        loading: true,
        error: false,
        success: false
      };
    case GET_SELLER:
      return {
        ...state,
        loadingDetails: true,
        error: false,
        success: false
      };
    case CREATE_SELLER:
      return {
        ...state,
        loadingDetails: true,
        error: false,
        success: false
      };
    case SEND_SELLER_ACCOUNT_INVITE:
      return {
        ...state,
        sendingBankInvite: true
      };
    case COMPLETE_SELLER_ACCOUNT_INVITE:
      return {
        ...state,
        sendingBankInvite: false
      };
    case CREATE_SELLER_ERROR:
      return {
        ...state,
        loadingDetails: false,
        error: true,
        success: false
      };
    case GET_SELLERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false
      };
    case RECEIVE_SELLERS:
      return {
        ...state,
        loading: false,
        error: false,
        sellers: action.value
      };
    case RECEIVE_SELLER:
      return {
        ...state,
        loadingDetails: false,
        error: false,
        sellerDetails: action.value
      };

    case RECEIVE_SELLER_MEDIA_DETAILS:
      return {
        ...state,
        loadingMediaDetails: false,
        error: false,
        mediaDetails: action.value
      };
    case GET_SELLER_MEDIA_DETAILS:
      return {
        ...state,
        loadingMediaDetails: true,
        error: false,
        success: false
      };
    case GET_SELLER_MEDIA_DETAILS_ERROR:
      return {
        ...state,
        loadingMediaDetails: false,
        error: true,
        success: false
      };
    default:
      return state;
  }
}

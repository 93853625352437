import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Panel.scss';

import Icon from '../Icon/Icon';
import Button from '../Button/Button';

const Panel = ({
  backButton,
  children,
  className,
  close,
  closeIcon,
  contentRef,
  customModalWrapper,
  onBack,
  title,
  titleClassName
}) => {
  const modalContentRef = useRef(null);
  const wrapperRef = useRef(null);

  const closeCallback = useCallback(() => {
    if (close) {
      close();
    }
  }, [close]);

  const backCallback = useCallback(() => {
    if (onBack) {
      onBack();
    }
  }, [onBack]);

  return (
    <section className={classNames(className, 'tmx-overlay')}>
      <div
        className={classNames('tmx-overlay__wrapper', customModalWrapper, {
          'tmx-overlay__wrapper--active': close
        })}
        ref={wrapperRef}>
        <div className="tmx-overlay__container">
          {closeIcon ? (
            <div className="tmx-overlay__closeIcon">
              <Icon
                name="close"
                size="small"
                className="tmx-overlay__close"
                onClick={closeCallback}
              />
            </div>
          ) : null}

          {backButton ? (
            <div className="tmx-overlay__backButton">
              <Button
                buttonIcon="chevron"
                iconSize="medium"
                className="tmx-modal__back black"
                handleClick={() => backCallback()}>
                {' '}
                {backButton}
              </Button>
            </div>
          ) : null}
          {title && (
            <div
              className={classNames('tmx-overlay__title', titleClassName)}
              dangerouslySetInnerHTML={{ __html: title }}
              titleClassName></div>
          )}

          <div ref={contentRef || modalContentRef}>{children}</div>
        </div>
      </div>
    </section>
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'large']),
  header: PropTypes.string,
  vertical: PropTypes.bool,
  close: PropTypes.func,
  closeIcon: PropTypes.bool,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  customModalWrapper: PropTypes.string,
  backButton: PropTypes.string,
  onBack: PropTypes.func,
  logo: PropTypes.bool
};

Panel.defaultProps = {
  size: 'small'
};

export default Panel;

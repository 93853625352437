import { callAjaxWCognito } from '../../functionality/CognitoTMX';
import {
  FILE_UPLOAD,
  EXCHANGE_GET_COLLECTIONS_PROFILE,
  ACCESS_UPLOAD_FILE
} from '../../functionality/networking';

export function getSignedUrl(file, callback) {
  const videoType = 'video';
  const filetype = file.type.split('/').shift();
  const options = {
    contentType: file.type,
    type: filetype === videoType ? videoType : 'photo',
    fileExtension: file.name.split('.').pop(),
    contentLength: file.size
  };
  const handleResponse = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.success && parsedResponse.url) {
      file.key = parsedResponse.key;
      file.customType = filetype;

      callback({
        signedUrl: parsedResponse.url
      });
    }
  };
  const handleError = () => {};

  callAjaxWCognito(FILE_UPLOAD, handleResponse, handleError, JSON.stringify(options));
}

export function getAccessSignedUrl(file, callback) {
  const videoType = 'video';
  const filetype = file.type.split('/').shift();
  const options = {
    contentType: file.type,
    type: filetype === videoType ? videoType : 'photo',
    fileExtension: file.name.split('.').pop(),
    contentLength: file.size
  };
  const handleResponse = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.success && parsedResponse.url) {
      file.key = parsedResponse.key;
      file.customType = filetype;

      callback({
        signedUrl: parsedResponse.url
      });
    }
  };
  const handleError = () => {};

  callAjaxWCognito(ACCESS_UPLOAD_FILE, handleResponse, handleError, JSON.stringify(options));
}

export function getProfileImageSignedUrl(file, callback) {
  const filetype = file.type.split('/').shift();
  const options = {
    contentType: file.type,
    type: 'photo',
    fileExtension: file.name.split('.').pop(),
    contentLength: file.size,
    update_prof_pic: true,
    profile_url: file.profileUrl
  };
  const handleResponse = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.success && parsedResponse.update_prof_pic_url) {
      file.key = parsedResponse.key;
      file.customType = filetype;

      callback({
        signedUrl: parsedResponse.update_prof_pic_url
      });
    }
  };
  const handleError = () => {};

  callAjaxWCognito(
    EXCHANGE_GET_COLLECTIONS_PROFILE,
    handleResponse,
    handleError,
    JSON.stringify(options)
  );
}

import {
  RECEIVE_CAROUSEL,
  GET_CAROUSEL,
  GET_CAROUSEL_ERROR,
  GET_EXPERTLIST,
  RECEIVE_EXPERTLIST,
  GET_EXPERTLIST_ERROR,
  GET_EMAIL_ALERT_ITEMS,
  GET_EMAIL_ALERTS_ERROR,
  RECEIVE_EMAIL_ALERT_ITEMS
} from '../../constants/action-types';

const initialState = {
  marketingCarousels: [],
  error: false,
  loading: false,
  emailAlertItems: [],
  emailAlertError: false,
  emailAlertLoading: false,
  expertList: [],
  expertListError: false,
  expertListLoading: false
};

export default function toolsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CAROUSEL:
      return { ...state, marketingCarousels: action.value, loading: false, error: false };
    case GET_CAROUSEL:
      return { ...state, loading: true, error: false };
    case GET_CAROUSEL_ERROR:
      return { ...state, error: true };
    case RECEIVE_EMAIL_ALERT_ITEMS:
      return { ...state, emailAlertItems: action.value, emailAlertLoading: false, error: false };
    case GET_EMAIL_ALERT_ITEMS:
      return { ...state, emailAlertLoading: true, error: false };
    case GET_EMAIL_ALERTS_ERROR:
      return { ...state, error: true };
    case GET_EXPERTLIST:
      return { ...state, expertListLoading: true, expertListError: false };
    case RECEIVE_EXPERTLIST:
      return {
        ...state,
        expertList: action.value.results,
        expertListLoading: false,
        expertListError: false
      };
    case GET_EXPERTLIST_ERROR:
      return { ...state, expertListError: true, expertListLoading: false };
    default:
      return state;
  }
}

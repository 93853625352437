import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.scss';

import Icon from '../Icon/Icon';

const Button = ({
  buttonIcon,
  buttonIconClassName,
  buttonType,
  children,
  className,
  disabled,
  handleClick,
  handleFocus,
  iconSize,
  spinner,
  success,
  type
}) => {
  const classes = classNames(className, {
    'tmx-button': buttonType !== 'blue',
    'tmx-button--blue': buttonType === 'blue',
    'tmx-button--grey': buttonType === 'grey',
    'tmx-button--greyBlack': buttonType === 'grey-black',
    'tmx-button--white': buttonType === 'white',
    'tmx-button--orange': buttonType === 'orange',
    spinning: spinner,
    success: success || buttonIcon === 'check1' || buttonIcon === 'check'
  });

  const callback = useCallback(
    (event) => {
      if (handleClick) {
        handleClick(event);
      }
    },
    [handleClick]
  );

  const focusCallback = useCallback(
    (event) => {
      if (handleFocus) {
        handleFocus(event);
      }
    },
    [handleFocus]
  );

  return (
    <button
      type={type}
      onClick={callback}
      className={classes}
      disabled={disabled}
      onFocus={focusCallback}>
      {spinner ? (
        <span
          className={classNames('tmx-button__spinner', `tmx-button__spinner-${iconSize}`)}></span>
      ) : (
        <></>
      )}
      {buttonIcon ? (
        <Icon
          name={buttonIcon}
          className={classNames('tmx-button__extraIcon', buttonIconClassName)}
          size={iconSize}
        />
      ) : (
        <></>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  handleClick: PropTypes.func,
  handleFocus: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  children: PropTypes.node,
  buttonType: PropTypes.oneOf(['blue', 'grey', 'white', 'grey-black', 'orange']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
  buttonIcon: PropTypes.string,
  iconSize: PropTypes.string,
  success: PropTypes.bool,
  buttonIconClassName: PropTypes.string
};

export default Button;

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import '../../../pages/UploadContainer/UploadContainer.scss';

export default function Tags({
  // count,
  disabled,
  initial,
  label,
  limit,
  locations,
  notArray,
  onChange,
  tags,
  view
}) {
  const [values, setValues] = useState([]);
  const [init, setInit] = useState(false);

  const toTagObj = (name) => (tags ? name : { title: name });

  if (!init && initial && initial.length > 0) {
    setInit(true);
    setValues(notArray ? [initial].map(toTagObj) : initial.map(toTagObj));
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100
  });

  const defaultTags =
    view === 'prOrg'
      ? [
          { title: 'Artificial intelligence' },
          { title: 'Chatbots' },
          { title: 'Advertising' },
          { title: 'Branding' },
          { title: 'Automotive' },
          { title: 'Electric vehicles' },
          { title: 'Aviation' },
          { title: 'Addiction recovery' },
          { title: 'Business' },
          { title: 'Christian empowerment' },
          { title: 'Climate change' },
          { title: 'Criminal Defense' },
          { title: 'Crisis communications' },
          { title: 'Cults' },
          { title: 'Domestic violence' },
          { title: 'Economy' },
          { title: 'Education' },
          { title: 'Entrepreneurship' },
          { title: 'Leadership Coaching' },
          { title: 'Entertainment' },
          { title: 'Fashion' },
          { title: 'Finance' },
          { title: 'Foreign relations' },
          { title: 'Health and wellness' },
          { title: 'Gaming' },
          { title: 'Immigration' },
          { title: 'Investment' },
          { title: 'Small business' },
          { title: 'Jewelry' },
          { title: 'Diamond' },
          { title: 'Labor diversity' },
          { title: 'Leadership' },
          { title: 'Legal' },
          { title: 'Life Coaching' },
          { title: 'Business coaching' },
          { title: 'Mindset' },
          { title: 'Mental health' },
          { title: 'Medical' },
          { title: 'Nutrition' },
          { title: 'Fitness' },
          { title: 'Personal Finance' },
          { title: 'Physical therapy' },
          { title: 'Plastic surgery' },
          { title: 'Political' },
          { title: 'Presidential historian' },
          { title: 'Psychiatry' },
          { title: 'Brain Health' },
          { title: 'Real estate' },
          { title: 'Relationship' },
          { title: 'Security' },
          { title: 'Defense' },
          { title: 'Social media' },
          { title: 'Sports' },
          { title: 'Supply chain' },
          { title: 'Transgender medicine' },
          { title: 'Transportation' },
          { title: 'Travel' },
          { title: 'Tech' },
          { title: 'Cybersecurity' },
          { title: 'Web3' },
          { title: 'NFT' },
          { title: 'Workplace culture' },
          { title: 'Women in the workplace' },
          { title: 'Data Analytics' },
          { title: 'Blockchain' },
          { title: 'Renewable energy' },
          { title: 'Green technology' },
          { title: 'Social impact' },
          { title: 'Public relations' },
          { title: 'Cybercrime' },
          { title: 'Data privacy' },
          { title: 'Machine learning' },
          { title: 'Robotics' },
          { title: 'Virtual reality' },
          { title: 'Augmented reality' },
          { title: 'Digital Marketing' },
          { title: 'Brand management' },
          { title: 'Cryptocurrency' },
          { title: 'Smart cities' },
          { title: 'Sustainable living' },
          { title: 'Mental wellness' },
          { title: 'Holistic healing' },
          { title: 'Self-improvement' },
          { title: 'Personal development' },
          { title: 'Human resources' },
          { title: 'Workplace productivity' },
          { title: 'Diversity and inclusion' },
          { title: 'Conflict resolution' },
          { title: 'Team building' },
          { title: 'Content creation' },
          { title: 'Social entrepreneurship' },
          { title: 'Renewable resources' },
          { title: 'Green architecture' },
          { title: 'Eco-friendly lifestyle' },
          { title: 'Parenting' },
          { title: 'Eldercare' },
          { title: 'Rehabilitation' },
          { title: 'Life transitions' },
          { title: 'Stress management' },
          { title: 'Career development' },
          { title: 'Civil rights' },
          { title: 'Sexual assault' },
          { title: 'Hazing' },
          { title: 'School Shootings' },
          { title: 'Civil trials' }
        ]
      : [
          { title: 'Animals' },
          { title: 'Celebrity' },
          { title: 'Coronavirus' },
          { title: 'Crime' },
          { title: 'Economy' },
          { title: 'Health/Science' },
          { title: 'Human Interest / Viral' },
          { title: 'Lifestyle' },
          { title: 'Military/War' },
          { title: 'News' },
          { title: 'Politics' },
          { title: 'Royals' },
          { title: 'Sports' },
          { title: 'Weather' }
        ];

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        filterOptions={filterOptions}
        multiple
        id="tags-standard"
        options={tags ? tags : defaultTags.sort((a, b) => (a.title > b.title ? 1 : -1))}
        getOptionLabel={(option) => (tags ? option : option.title)}
        onChange={(event, values) => {
          setValues(values);
          onChange(values);
        }}
        getOptionDisabled={() => (values.length > limit - 1 ? true : false)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            label={
              <span style={{ fontSize: '14px' }}>
                {label
                  ? label
                  : notArray
                  ? 'Primary city?'
                  : tags
                  ? 'Tag up to five locations'
                  : 'Assign category(s)'}
              </span>
            }
            className="videoTag"
          />
        )}
        value={locations ? locations : values}
      />
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

Tags.propTypes = {
  disabled: PropTypes.bool,
  initial: PropTypes.any,
  label: PropTypes.any,
  limit: PropTypes.number,
  locations: PropTypes.array,
  notArray: PropTypes.bool,
  onChange: PropTypes.func,
  tags: PropTypes.array,
  view: PropTypes.string
};

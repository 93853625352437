import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

const Icon = ({ name, size, className, onClick }) => {
  const iconClassName = classNames(`tmx-icon-${name}`, `icon`, size, className);

  const clickCallback = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  const renderPath = () => {
    let pathElements = [];
    // Create a loop of 20 to render multiple path based
    // on the count expected on _tmx-icon.scss
    for (let i = 1; i <= 20; i++) {
      pathElements.push(<span className={`path${i}`} key={i}></span>);
    }
    return pathElements;
  };

  return (
    <>
      {name === 'logo-big' ||
      name === 'logo-small' ||
      name === 'tmx-brand-logo-beta' ||
      name === 'tmx-brand-mark-beta-centered' ||
      name === 'tmx-brand-mark-beta' ||
      name === 'newmaker-step-two' ||
      name === 'newmaker-step-three' ||
      name === 'newmaker-step-four' ||
      name === 'newmaker-step-one' ||
      name === 'media-step-three' ||
      name === 'media-step-four' ||
      name === 'media-step-one' ||
      name === 'media-step-two' ||
      name === 'linkedin' ||
      name === 'instagram' ||
      name === 'home-page-banner' ||
      name === 'home-page-media' ||
      name === 'home-page-newsmaker' ||
      name === 'telemundo' ||
      name === 'warner-media' ||
      name === 'why-tmx-one' ||
      name === 'why-tmx-two' ||
      name === 'why-tmx-three' ||
      name === 'why-tmx-four' ||
      name === 'twitter' ||
      name === 'facebook' ||
      name === 'our-values-one' ||
      name === 'our-values-two' ||
      name === 'our-values-three' ||
      name === 'our-values-four' ||
      name === 'publicist-one' ||
      name === 'publicist-four' ||
      name === 'publicist-two' ||
      name === 'publicist-three' ||
      name === 'tiktok' ||
      name === 'youtube' ||
      name === 'reddit_circle' ||
      name === 'instagram_circle' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true">
          {renderPath()}
        </div>
      ) : name === 'dont-care' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true">
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      ) : name === 'sort-up' ||
        name === 'sort-down' ||
        name === 'twitter' ||
        name === 'facebook_circle' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true">
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      ) : (
        <span
          onClick={clickCallback}
          className={iconClassName}
          role="button"
          tabIndex="-1"
          aria-hidden="true"></span>
      )}
    </>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    '4nbc',
    'acquired',
    'amex',
    'arrow-alt-from-bottom',
    'arrow-right',
    'blocked',
    'blocked',
    'camera-1',
    'camera-2',
    'card',
    'check',
    'check-circle',
    'check1',
    'check1',
    'chevron',
    'clock',
    'clock-alt',
    'close',
    'cloud',
    'cloud-download',
    'cloud-download_small',
    'cloud-upload_big',
    'cloud-upload_small',
    'cnn',
    'coffee-pot_big',
    'coffee-pot_small',
    'combined-shape',
    'contact',
    'contact',
    'contacts',
    'content',
    'delete',
    'discover',
    'dont-care',
    'downloaded',
    'downloaded',
    'edit-org',
    'edit-organization',
    'ellipsis',
    'ellipsis',
    'ellipsis',
    'email-alerts',
    'envelope',
    'eye-slash-solid',
    'eye-slash-solid',
    'facebook',
    'facebook_circle',
    'file-alt',
    'file-error',
    'file-error',
    'fire-alt_big',
    'fire-alt_small',
    'flag',
    'flag',
    'follow-organization',
    'follow-star',
    'fox-news',
    'home',
    'home',
    'home-page-banner',
    'home-page-media',
    'home-page-newsmaker',
    'instagram',
    'instagram_circle',
    'link-solid',
    'link-solid',
    'linked-blk',
    'linkedin',
    'location-international',
    'locationBanner',
    'lock',
    'lock-open',
    'logo-big',
    'logo-small',
    'map-marked',
    'marketing-arrow',
    'marketing-arrow',
    'mastercard',
    'media-step-four',
    'media-step-one',
    'media-step-three',
    'media-step-two',
    'membership',
    'meredith',
    'message',
    'message',
    'metadata',
    'metadata',
    'minus',
    'minus-circle',
    'minus-circled',
    'nbcuniversal',
    'newmaker-step-four',
    'newmaker-step-one',
    'newmaker-step-three',
    'newmaker-step-two',
    'notification',
    'notification',
    'org-icon',
    'our-values-four',
    'our-values-one',
    'our-values-three',
    'our-values-two',
    'paper',
    'payment',
    'payment',
    'phone',
    'photo-gallery',
    'photo-gallery',
    'plus',
    'plus-circled',
    'publicist-four',
    'publicist-one',
    'publicist-three',
    'publicist-two',
    'purchased',
    'question',
    'radar',
    'radio',
    'radio-center',
    'radio-fill',
    'radio-filled',
    'recommended',
    'reddit_circle',
    'referral',
    'refresh',
    'retry',
    'retry-circled',
    'search',
    'search1',
    'send',
    'settings',
    'Settings',
    'share',
    'sign-out',
    'sitemap',
    'sort-default',
    'sort-down',
    'sort-up',
    'stop-watch',
    'stories',
    'stripe',
    'tags',
    'target',
    'tegna',
    'telemundo',
    'the-weather-company',
    'tiktok',
    'title-license',
    'title-shared',
    'tmx-brand-logo-beta',
    'tmx-brand-mark-beta',
    'tmx-brand-mark-beta-centered',
    'top-nav-upload',
    'trash',
    'trash1',
    'tray-discover',
    'tv',
    'twitter',
    'twitter',
    'ugc',
    'upgrade',
    'uploaded',
    'user',
    'user-blk',
    'user-check_big',
    'user-check_small',
    'user-icon',
    'user-plus',
    'verified',
    'views',
    'visa',
    'warner-media',
    'why-tmx-four',
    'why-tmx-one',
    'why-tmx-three',
    'why-tmx-two',
    'x-circled',
    'youtube'
  ]).isRequired,
  size: PropTypes.oneOf(['micro', 'small', 'medium', 'large', 'huge', 'ginormous', 'evenBigger']),
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Icon;

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './SlideToggle.scss';

const SlideToggle = ({
  id,
  label,
  disabled,
  onClick,
  className,
  value,
  blue,
  grey,
  green,
  showText
}) => {
  const [checked, setChecked] = useState(value);

  const onClickCallback = useCallback(() => {
    if (!disabled) {
      setChecked(!checked);
      onClick(!checked);
    }
  }, [onClick, checked, disabled]);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div className={classNames('tmx-slideToggle', className)}>
      <label htmlFor={id} className="tmx-slideToggle__label">
        <div
          className={classNames('tmx-slideToggle__bar', {
            'tmx-slideToggle__bar--selected': checked,
            'tmx-slideToggle__bar--disabled': disabled,
            'tmx-slideToggle__bar--blue': blue,
            'tmx-slideToggle__bar--grey': grey,
            'tmx-slideToggle__bar--green': green
          })}>
          <input
            className="tmx-slideToggle__input"
            type="checkbox"
            id={id}
            role="switch"
            tabIndex="0"
            aria-checked={checked}
            onChange={onClickCallback}
          />
          <div className="tmx-slideToggle__thumbWrapper">
            <div
              className={classNames('tmx-slideToggle__thumb', {
                'tmx-slideToggle__thumb--selected': checked,
                'tmx-slideToggle__thumb--disabled': disabled,
                'tmx-slideToggle__thumb--blue': blue,
                'tmx-slideToggle__thumb--grey': grey,
                'tmx-slideToggle__thumb--green': green
              })}></div>
            <div
              className={classNames('tmx-slideToggle__thumbText', {
                'tmx-slideToggle__thumbText--selected': checked,
                'tmx-slideToggle__thumbText--blue': blue,
                'tmx-slideToggle__thumbText--grey': grey,
                'tmx-slideToggle__thumbText--green': green
              })}>
              {checked && showText ? 'On' : !checked && showText ? 'Off' : ''}
            </div>
          </div>
        </div>
        <span className="tmx-slideToggle__content">{label}</span>
      </label>
    </div>
  );
};

SlideToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.bool,
  blue: PropTypes.bool,
  grey: PropTypes.bool,
  green: PropTypes.bool,
  showText: PropTypes.bool
};

SlideToggle.defaultProps = {
  value: false
};

export default SlideToggle;

import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './UploadForm.scss';

import TextArea from '../../atoms/TextArea/TextArea';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Button from '../../atoms/Button/Button';
import Tags from '../../atoms/Tags/Tags';
import DropDown from '../../atoms/DropDown/DropDown';
import ContactForm from '../ContactForm/ContactForm';
import Input from '../../atoms/Input/Input';
import Icon from '../../atoms/Icon/Icon';
import NewsroomsUsingContent from '../NewsroomsUsingContent/NewsroomsUsingContent';
import NewLocationInputs from './NewLocationInputs';

import { ReactComponent as Contact } from '../../../images/contactIcon.svg';
import { ReactComponent as Delete } from '../../../images/delete.svg';
import { ReactComponent as Upload } from '../../../images/upload.svg';

import { UserContext } from '../../../pages/UploadContainer/UploadContainer';

import { publish } from '../../../api/actions/universal-uploader-actions';
import { shouldFetchLists } from '../../../api/actions/org-actions';

export function toLocalTime(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

const UploadForm = ({
  addNewURLField,
  allowDownload,
  date,
  handleAddNewContact,
  handleAddNewField,
  handleBasicContactChange,
  handleContactDetailChange,
  handleRemoveContact,
  handleRemoveField,
  handleURLChange,
  isDownloadableContent,
  removeURLField,
  setAlertCategory,
  setAllowDownload,
  setBlockList,
  setContactFields,
  setCreatorDescription,
  setCredit,
  setDate,
  setGraphicType,
  setHideTMX,
  setIsGraphicContent,
  setIsInboxContentDownlodable,
  setIsUgc,
  setLocations,
  setScheduled,
  setSenderOrg,
  setShowContact,
  setShowDateAndTime,
  setSummary,
  setTags,
  setWaterMark,
  setHidePost
}) => {
  const vals = useContext(UserContext);

  const reactQuillRef = useRef(null);
  const route = useRouteMatch();
  const { isLoading } = useSelector((state) => state.universalUploader);
  const dispatch = useDispatch();

  const { contentDetails } = useSelector((state) => state.universalUploader);

  const [newsroomsUsing, setNewsroomsUsing] = useState([
    {
      description: '',
      link: '',
      name: ''
    }
  ]);
  const [showTMXPublishableSummary, setShowTMXPublishableSummary] = useState(false);
  const [showAddLocationInputs, setShowAddLocationInputs] = useState(false);

  // Create a function to set the placeholder dynamically
  const updateQuillPlaceholder = () => {
    const quillContainer = reactQuillRef?.current?.getEditor();
    const placeholderText =
      vals.view === 'contact'
        ? 'Story summary'
        : vals.view === 'INBOX'
        ? 'Message summary'
        : 'Enter publishable copy';

    if (quillContainer) {
      quillContainer.root.dataset.placeholder = placeholderText;
    }
  };

  useEffect(() => {
    if (contentDetails['newsrooms_using']) {
      // Populate the first form with the first item from backend data
      const firstForm = {
        name: contentDetails['newsrooms_using'][0]?.name || '',
        description: contentDetails['newsrooms_using'][0]?.description || '',
        link: contentDetails['newsrooms_using'][0]?.link || ''
      };

      // Map the remaining backend data starting from the second item
      const updatedNewsrooms = contentDetails['newsrooms_using'].slice(1).map((item) => ({
        name: item.name || '',
        description: item.description || '',
        link: item.link || ''
      }));

      // Set the newsroomsUsing state with the first form populated and the rest of the forms
      setNewsroomsUsing([firstForm, ...updatedNewsrooms]);
    }
  }, [contentDetails, setNewsroomsUsing]);

  useEffect(() => {
    // Call the function to set the initial placeholder
    updateQuillPlaceholder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals.view]); // Update when 'vals.view' changes

  useEffect(() => {
    dispatch(shouldFetchLists());
  }, [dispatch]);

  const contentId = route.params.id || '';
  const currentDate = toLocalTime(new Date()).slice(0, new Date().toISOString().lastIndexOf(':'));

  function checkIsBefore(currentDate, selectedDate) {
    return moment(selectedDate).isBefore(currentDate);
  }

  var datePickerMax = new Date(new Date().setDate(new Date().getDate() + 7))
    .toISOString()
    .split('Z')[0];

  const sOrg = vals?.senderOrgData?.uploaders?.sort((a, b) => a.localeCompare(b)).map((x) => x);

  const reqPayload = {
    is_downloadable_content:
      vals.view === 'INBOX'
        ? vals.isInboxContentDownlodable
        : vals.view === 'content'
        ? allowDownload
        : isDownloadableContent,
    title: vals.title,
    content_warning: vals.isGraphicContent,
    content_warning_type: vals.graphicType,
    allow_download: vals.view === 'INBOX' ? vals.isInboxContentDownlodable : false,
    original_uploader_org: vals.senderOrg,
    watermark: vals.waterMark,
    credit: vals.hideTMX ? vals.credit : `${vals.credit} /TMX`,
    link_to_article: vals.postURL,
    creators_description: vals.creatorDescription,
    description: vals.summary.split('<br>').join(''),
    source_orgs: [vals.blockList],
    contacts: vals.contactFields,
    tags: vals.tags,
    location: vals.locations,
    alert_category: vals.alertCategory,
    newsrooms_using: newsroomsUsing,
    datetime: contentId
      ? vals.date
      : !contentId && vals.date
      ? new Date(new Date(vals.date).toUTCString()).toISOString().split('Z')[0]
      : new Date(new Date().toUTCString()).toISOString().split('Z')[0],
    is_contact: vals.view === 'contact',
    photos: vals.view !== 'content' && vals.view !== 'INBOX' ? true : vals.isPhotos,
    dynamic_price: true,
    date_unknown: false,
    files: [...vals.files, ...vals.thumbnail].map((file) => ({
      type: file.type === 'image' ? 'photo' : 'video',
      key: file.key,
      name: file.name
    })),
    ...(contentId && {
      edit: true,
      media_id: Number(contentId)
    }),
    ...((vals.view === 'pitches' ||
      vals.view === 'boost' ||
      vals.view === 'placement' ||
      vals.view === 'INBOX') && {
      type: vals.view === 'INBOX' ? 'INBOX' : vals.view === 'placement' ? 'PLACEMENTS' : 'PITCHES'
    }),
    ...(vals.view === 'boost' && {
      boosted_orgs: vals.boostedOrgs
    }),
    ...(vals.view === 'placement' && {
      company_name: vals.boostedOrgs[0]
    }),
    ...(vals.view === 'content' && {
      ugc: vals.isUgc
    }),
    ...(vals.view === 'INBOX' && {
      is_tmx_expert: vals.isTmxExpert,
      do_not_place_on_top: vals.doNotPlaceOntop,
      inbox_type: vals.inboxType
    }),
    ...((vals.view === 'content' || vals.view === 'article') && {
      type: vals.view === 'content' ? 'ORIGINAL' : vals.view === 'article' ? 'CLEAREDARTICLES' : ''
    }),
    ...(vals.view === 'article' && {
      short_title: vals.shortTitle
    }),
    hide_post: vals.hidePost
  };

  const canUpload = useMemo(() => {
    const emptyContactFields = vals?.contactFields.some((contactField) =>
      contactField.contactDetails.some(
        (field) => !field.contactFieldTypes || !field.contactFieldData
      )
    );
    const emptyContactDetails = vals?.contactFields.some(
      (contactField) => !contactField.contactName || !contactField.contactTitle
    );
    const emptyURLs = vals?.postURL.some((url) => !url.length);
    if (
      !vals.title.length ||
      (!vals.shortTitle.length && vals.view === 'article') ||
      (!vals.summary.replace(/(<([^>]+)>)/gi, '').length && vals.view === 'article') ||
      !vals.postURL ||
      (vals.scheduled && !vals.date) ||
      !vals.tags.length ||
      !vals.locations.length
    ) {
      return false;
    } else if (vals.view !== 'contact' && vals.view !== 'INBOX' && vals.senderOrg === '') {
      return false;
    } else if (
      (vals.view === 'contact' ||
        vals.view === 'boost' ||
        (vals.view === 'content' && vals.isUploadingImage)) &&
      vals.thumbnail.length < 1 &&
      !contentId
    ) {
      return false;
    } else if (
      ((vals.view === 'content' && !vals.isUploadingImage) ||
        (vals.view === 'INBOX' && !vals.isUploadingImage)) &&
      vals.files.length < 1 &&
      !contentId
    ) {
      return false;
    } else if (vals.view === 'content' && emptyURLs) {
      return false;
    } else if (
      (vals.view === 'content' || vals.view === 'INBOX') &&
      (vals.isVideo || (vals.view === 'INBOX' && vals.isUploadingImage && !contentId)) &&
      !vals.thumbnail.length
    ) {
      return false;
    } else if (
      vals.view === 'content' &&
      (!vals.senderOrg.length || !vals.credit || !vals.locations.length)
    ) {
      return false;
    } else if (
      (vals.view === 'contact' || vals.view === 'boost' || vals.view === 'pitches') &&
      emptyContactFields
    ) {
      return false;
    } else if ((vals.view === 'contact' || vals.view === 'boost') && emptyContactDetails) {
      return false;
    } else if (vals.view === 'boost' && !vals.boostedOrgs.length) {
      return false;
    } else if (vals.view === 'INBOX' && !vals.credit && vals.isInboxContentDownlodable) {
      return false;
    }
    return true;
  }, [vals, contentId]);

  const modules = {
    toolbar: [['link']]
  };

  const formats = ['link'];

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() >= 10000 && event.key !== 'Backspace')
      event.preventDefault();
  };

  const showCreditFieldOnInboxForm = vals.view === 'INBOX' && vals.isInboxContentDownlodable;

  const handleAddNewNewsroomForm = () => {
    setNewsroomsUsing([
      ...newsroomsUsing,
      {
        description: '',
        link: '',
        name: ''
      }
    ]);
  };

  const showPublishableSummary =
    (vals.view === 'content' && showTMXPublishableSummary) ||
    vals.view === 'article' ||
    vals.view === 'INBOX' ||
    vals.view === 'contact';

  return (
    <div className="tmx-upload">
      {vals.view === 'content' || vals.view === 'article' || vals.view === 'INBOX' ? (
        <>
          {vals.view === 'content' && (
            <div className="tmx-uploadForm__checkboxContainer field">
              <div className="tmx-uploadForm__graphicContent">
                <Checkbox
                  label=""
                  id="isGraphicContent"
                  type="checkbox"
                  size="small"
                  value={vals.isGraphicContent}
                  isChecked={vals.isGraphicContent}
                  iconClassName="tmx-uploadForm__checkboxIcon"
                  onClick={() => {
                    if (!vals.isGraphicContent) {
                      setIsGraphicContent(true);
                      setAllowDownload(true);
                    } else {
                      setIsGraphicContent(false);
                    }
                  }}
                />
                <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                  Contains graphic content
                </span>
              </div>
              <DropDown
                onChange={(event) => {
                  setGraphicType(event.target.value);
                }}
                optionSelected={vals.graphicType}
                className="tmx-uploadForm__orgDropDown grey"
                data={['Language', 'Content']}
                disabled={!vals.isGraphicContent}
              />
            </div>
          )}
          {vals.view === 'INBOX' && (
            <div className="tmx-uploadForm__graphicContent field">
              <Checkbox
                onClick={(event) => {
                  setIsInboxContentDownlodable(event);
                }}
                label=""
                id="isInboxContentDownlodable"
                type="checkbox"
                value={vals.isInboxContentDownlodable}
                isChecked={vals.isInboxContentDownlodable}
                // disabled={vals.isGraphicContent}
                size="small"
                iconClassName="tmx-uploadForm__checkboxIcon"
              />
              <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                Allow content downloads.
              </span>
            </div>
          )}

          {vals.view === 'content' ? (
            <>
              <div className="tmx-uploadForm__graphicContent field">
                <Checkbox
                  onClick={(event) => {
                    setIsUgc(event);
                  }}
                  label=""
                  id="isUgc"
                  type="checkbox"
                  value={vals.isUgc}
                  isChecked={vals.isUgc}
                  // disabled={vals.isGraphicContent}
                  size="small"
                  iconClassName="tmx-uploadForm__checkboxIcon"
                />
                <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                  This is UGC content
                </span>
              </div>

              <div className="tmx-uploadForm__graphicContent field">
                <Checkbox
                  onClick={() => {
                    // if (!vals.isGraphicContent) {
                    // setIsGraphicContent(true);
                    setAllowDownload(!vals.allowDownload);
                    // setWaterMark(true);
                    // } else {
                    //   setIsGraphicContent(false);
                    //   setAllowDownload(false);
                    //   setWaterMark(false);
                    // }
                  }}
                  label=""
                  id="allowDownload"
                  type="checkbox"
                  value={vals.allowDownload}
                  isChecked={vals.allowDownload}
                  disabled={vals.isGraphicContent}
                  size="small"
                  iconClassName="tmx-uploadForm__checkboxIcon"
                />
                <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                  Allow downloads
                </span>
              </div>
              <div className="tmx-uploadForm__graphicContent field">
                <Checkbox
                  onClick={() => {
                    setHidePost(!vals.hidePost);
                  }}
                  label=""
                  id="hidePost"
                  type="checkbox"
                  value={vals.hidePost}
                  isChecked={vals.hidePost}
                  size="small"
                  iconClassName="tmx-uploadForm__checkboxIcon"
                />
                <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                  Hide post
                </span>
              </div>
            </>
          ) : null}

          {vals.view !== 'INBOX' && (
            <div className="tmx-uploadForm__senderOrg field">
              <div className="tmx-uploadForm__labelWrapper">
                Sender Org <sup>*</sup>
              </div>
              <DropDown
                onChange={(event) => {
                  if (event.target.value === 'TMX') {
                    setSenderOrg(event.target.value);
                    setCredit('');
                  } else {
                    setSenderOrg(event.target.value);
                    setCredit(event.target.value);
                  }
                }}
                className="tmx-uploadForm__orgDropDown grey"
                data={sOrg || []}
                optionSelected={vals.senderOrg}
                name="Associate this media to an Org"
                setOptionsToUpperCase
              />
            </div>
          )}

          {vals.view === 'content' && !vals.isUploadingImage && (
            <div className="tmx-uploadForm__graphicContent field">
              <Checkbox
                label=""
                isChecked={vals.waterMark}
                // disabled={vals.water}
                id="waterMark"
                onClick={(e) => setWaterMark(e)}
                type="checkbox"
                size="small"
                iconClassName="tmx-uploadForm__checkboxIcon"
              />
              <span role="button" aria-hidden="true" className="tmx-uploadForm__checkboxLabel">
                Watermark this video
              </span>
            </div>
          )}

          {(vals.view === 'content' ||
            showCreditFieldOnInboxForm ||
            (isDownloadableContent && vals.view === 'article')) && (
            <div className="tmx-uploadForm__credits field">
              <div className="tmx-uploadForm__labelWrapper">
                {vals.view === 'article' ? 'Photo' : null} Credit <sup>*</sup>
              </div>
              <div className="tmx-uploadForm__graphicContent">
                <div className="credits-input">
                  <div className="credits-input-wrapper">
                    <TextArea
                      id="title"
                      maxRows={1}
                      onChange={(e) => setCredit(e.target.value)}
                      darkBorder
                      placeholder={vals.view === 'article' ? 'Photo credit' : 'Content creator'}
                      value={vals.credit}
                      statusBar
                      maxCharacters={100}
                      label={vals.view === 'article' ? 'Photo' : 'Content creator'}
                      htmlFor={'Credits'}
                    />
                    {!vals.hideTMX ? <div>/&nbsp;TMX</div> : null}
                  </div>
                </div>
                <div className="watermark">
                  <Checkbox
                    onClick={() => setHideTMX(!vals.hideTMX)}
                    label=""
                    id="HideTMX"
                    type="checkbox"
                    value={vals.hideTMX}
                    isChecked={vals.hideTMX}
                    size="small"
                    iconClassName="tmx-uploadForm__checkboxIcon"
                  />
                  <span
                    role="button"
                    aria-hidden="true"
                    onClick={() => {
                      setHideTMX(!vals.hideTMX);
                    }}
                    className="tmx-uploadForm__checkboxLabelNoWidth">
                    Hide /TMX
                  </span>
                </div>
              </div>
            </div>
          )}

          {vals.view === 'content' ? (
            <div className="tmx-uploadForm__credits field">
              <div className="tmx-uploadForm__labelWrapper">Creator&apos;s description</div>
              <div className="tmx-uploadForm__graphicContent">
                <div className="tmx-uploadForm__creatorDescription">
                  <TextArea
                    id="title"
                    className={'tmx-uploadForm__creatorDescriptionTextArea'}
                    maxRows={100}
                    onChange={(e) => setCreatorDescription(e.target.value)}
                    darkBorder
                    placeholder="Description from the creator"
                    value={vals.creatorDescription}
                    statusBar
                    maxCharacters={10000}
                    label={"Creator's description"}
                    htmlFor={"Creator's description"}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      {vals.view === 'content' && !showTMXPublishableSummary && (
        <button
          className="add-contact tmx-button"
          onClick={() => setShowTMXPublishableSummary(true)}>
          <Icon name="linked-blk" className="tmx-uploadForm__iconAttach" />
          <span>Add a TMX Publishable Summary</span>
        </button>
      )}

      {showPublishableSummary && (
        <div className="tmx-uploadForm__credits field">
          <div className="tmx-uploadForm__labelWrapper">
            {vals.view === 'contact'
              ? 'Story summary'
              : vals.view === 'INBOX'
              ? 'Message summary'
              : vals.view === 'article'
              ? 'Publishable copy'
              : 'Publishable TMX summary'}{' '}
            <sup>{vals.view === 'INBOX' || vals.view === 'content' ? '' : '*'}</sup>
          </div>
          <div className="tmx-uploadForm__graphicContent">
            <div className="tmx-uploadForm__pitchSummary">
              <ReactQuill
                onKeyDown={checkCharacterCount}
                ref={reactQuillRef}
                className={'pitchSummary'}
                onChange={(html) => {
                  setSummary(html);
                }}
                modules={modules}
                formats={formats}
                placeholder="Enter publishable copy"
                value={vals.summary}
                theme="snow"
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames('tmx-uploadForm__credits', {
          field: vals.view !== 'placement'
        })}>
        <div className="tmx-uploadForm__labelWrapper">
          {vals.view === 'content'
            ? 'Link to original post'
            : vals.view === 'article'
            ? 'Relevant link'
            : 'Relevant link'}{' '}
          <sup>{vals.view === 'INBOX' || vals.view === 'article' ? '' : '*'}</sup>
        </div>
        {vals.postURL.map((url, index) => (
          <div className="tmx-uploadForm__graphicContent tmx-uploadForm__URLField" key={index}>
            <div className="tmx-uploadForm__creatorDescription tmx-uploadForm__postURL">
              <TextArea
                id="postUrl"
                maxRows={30}
                onChange={(e) => handleURLChange(index, e)}
                darkBorder
                placeholder={
                  vals.view === 'contact' ? 'Enter web page' : 'Enter url where content appeared'
                }
                value={url}
                statusBar
                // maxCharacters={100000}
                label="Enter url where content appeared"
                htmlFor={'Link to original post'}
              />
              {index > 0 ? (
                <Delete
                  className="tmx-uploadForm__postURL--delete"
                  onClick={() => removeURLField(index)}
                />
              ) : null}
            </div>
          </div>
        ))}

        {vals.view === 'pitches' || vals.view === 'boost' || vals.view === 'INBOX' ? (
          <button className="tmx-uploadForm__URL" onClick={addNewURLField}>
            <Icon name="linked-blk" className="tmx-uploadForm__iconAttach" />
            <span>Add another URL</span>
          </button>
        ) : null}
      </div>

      {vals.view === 'content' ||
        (vals.view === 'article' && (
          <div className="tmx-uploadForm__senderOrg field">
            <div className="tmx-uploadForm__labelWrapper">
              Apply block list (Tag source material)
            </div>
            <DropDown
              onChange={(e) => {
                setBlockList(e.target.value);
              }}
              optionSelected={vals.blockList}
              name="Choose an org to own this content"
              className="tmx-uploadForm__orgDropDown grey"
              data={vals.senderOrgData.source_orgs || []}
              setOptionsToUpperCase
            />
          </div>
        ))}

      {(vals.showContact || vals.view === 'contact' || vals.view === 'pitches') &&
      vals.view !== 'boost' ? (
        <div className="tmx-contactFormWrapper">
          {vals.view === 'content' && (
            <Button
              className="close_contact"
              buttonIcon="close"
              handleClick={() => {
                if (!contentId) {
                  setContactFields([
                    {
                      contactName: '',
                      contactTitle: '',
                      contactDetails: [{ contactFieldTypes: '', contactFieldData: '' }]
                    }
                  ]);
                }
                setShowContact(false);
              }}
              iconSize="small"
            />
          )}
          <h1>Contact</h1>

          {vals.contactFields.map((contactField, index1) => (
            <div key={index1}>
              {index1 > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Delete
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveContact(index1)}
                  />
                </div>
              ) : null}
              <ContactForm
                contactField={contactField}
                handleBasicContactChange={handleBasicContactChange}
                handleAddNewField={handleAddNewField}
                handleContactDetailChange={handleContactDetailChange}
                handleRemoveField={handleRemoveField}
                index1={index1}
                view={vals.view}
              />
            </div>
          ))}
        </div>
      ) : null}
      {vals.view !== 'boost' && vals.view !== 'placement' ? (
        <Button
          className="add-contact"
          handleClick={() => {
            if (!vals.showContact) {
              setShowContact(true);
            } else {
              handleAddNewContact();
            }
          }}>
          <Contact /> Add a contact
        </Button>
      ) : null}
      {vals.view === 'content' && (
        <>
          <h2 className="tmx-uploadFormContainer__sectionTitle">
            About newsroom that used {vals.isUploadingImage ? 'these photos' : 'this video'}:
          </h2>
          {newsroomsUsing.map((newsroomInfo, index) => (
            <div key={index}>
              <NewsroomsUsingContent
                newsroomsUsing={newsroomsUsing}
                newsroomUsingLink={newsroomInfo.link}
                newsroomUsingDesc={newsroomInfo.description}
                newsroomUsingName={newsroomInfo.name}
                setNewsroomsUsing={setNewsroomsUsing}
                index={index}
              />
            </div>
          ))}
          <div className="tmx-uploadForm__addNewsroomFormContainer">
            <Button
              className="tmx-uploadForm__addNewsroomFormButton"
              handleClick={() => {
                handleAddNewNewsroomForm();
              }}>
              <Icon name="contacts" /> Add another newsroom
            </Button>
          </div>
        </>
      )}

      {vals.view === 'content' && (
        <h2 className="tmx-uploadFormContainer__sectionTitle">Basic tags / emails</h2>
      )}
      <div className="tags">
        <div className="tmx-uploadForm__label">
          Category
          <sup> *</sup>
        </div>
        <Tags
          label={
            vals.view === 'content'
              ? 'Select all that apply'
              : vals.view === 'article'
              ? 'Assign category(s)'
              : 'Select one or more categories'
          }
          onChange={(arr) => {
            setTags(arr.map((obj) => obj.title));
          }}
          initial={vals.tags}
          view={vals.view}
        />
      </div>

      <div className="tags">
        <div className="tmx-uploadForm__label">
          Location(s)<sup> *</sup>
          <span style={{ float: 'right' }}>{vals.locations.length}/5</span>
        </div>

        <Tags
          tags={vals.locationData}
          limit={5}
          initial={vals.locations}
          count={vals.locations.length}
          locations={vals.locations}
          onChange={(arr) => {
            setLocations(arr.map((obj) => obj));
          }}
        />
        <div className="tmx-uploadForm__locationInputContainer">
          {!showAddLocationInputs && (
            <Button handleClick={() => setShowAddLocationInputs(true)}>Add Location</Button>
          )}
          {showAddLocationInputs && <NewLocationInputs setLocations={setLocations} />}
        </div>
      </div>

      {vals.view !== 'pitches' && vals.view !== 'boost' && vals.view !== 'placement' ? (
        <div className="notify">
          <div className="tmx-uploadForm__label">Notify users</div>
          <DropDown
            onChange={(event) => {
              setAlertCategory(
                event.target.value === 'State'
                  ? 'lower'
                  : event.target.value === 'National'
                  ? 'higher'
                  : event.target.value === 'All TMX Users'
                  ? 'all'
                  : 'none'
              );
            }}
            optionSelected={
              vals.alertCategory === 'higher'
                ? 'National'
                : vals.alertCategory === 'lower'
                ? 'State'
                : vals.alertCategory === 'all'
                ? 'All TMX Users'
                : 'No email'
            }
            className="tmx-uploadForm__userDropDown grey"
            data={
              vals.view === 'INBOX'
                ? ['No Email', 'All TMX Users']
                : vals.view === 'contact'
                ? ['No Email', 'National']
                : ['No Email', 'State', 'National', 'All TMX Users']
            }
            setOptionsToUpperCase
          />
        </div>
      ) : null}
      {!vals.showDateAndTime && !contentId && (
        <div className="tmx-uploadForm__pitchDateText">
          {vals.view === 'pitches'
            ? 'This pitch will go out to all TMX dashboards immediately. If you want to send this later please '
            : 'This will be uploaded immediately. To send this later please '}
          <Button
            className="tmx-uploadForm__pitchDateTextButton"
            handleClick={() => {
              setShowDateAndTime(!vals.showDateAndTime);
              setScheduled(true);
            }}>
            select date and time.
          </Button>
        </div>
      )}

      {vals.showDateAndTime ? (
        <div className="dateInput">
          <div className="tmx-uploadForm__uploadLabel">
            <p>
              Upload date & time<sup> *</sup>
            </p>
            {vals.scheduled && (
              <button
                onClick={() => {
                  setScheduled(false);
                  setShowDateAndTime(false);
                  setDate('');
                }}>
                Send now
              </button>
            )}
          </div>

          <Input
            // onFocus={contentId ? '' : reIntTime}
            type="datetime-local"
            dateMax={datePickerMax}
            disablePastTime
            disabled={!!contentId}
            onChange={(e) => {
              setDate(e.target.value);
              setScheduled(true);
            }}
            value={vals.date}
            setScheduled={setScheduled}
            setShowDateAndTime={setShowDateAndTime}
          />
          {checkIsBefore(currentDate, date) ? (
            <div className="tmx-uploadForm__dateSubText">
              This post will be published with a past time.
            </div>
          ) : date !== currentDate && date !== '' && !checkIsBefore(currentDate, date) ? (
            <div className="tmx-uploadForm__dateSubText">
              This post will be published at a future time.
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="upload-to-tmx">
        <Button
          handleClick={() => dispatch(publish(reqPayload))}
          spinner={isLoading}
          disabled={!canUpload || isLoading}>
          {' '}
          {isLoading && !contentId
            ? 'Uploading...'
            : !isLoading && contentId
            ? 'Save Changes'
            : isLoading && contentId
            ? 'Saving changes'
            : 'Upload to TMX'}{' '}
          <Upload />
        </Button>
      </div>
    </div>
  );
};

export default UploadForm;

UploadForm.propTypes = {
  addNewURLField: PropTypes.func,
  allowDownload: PropTypes.bool,
  date: PropTypes.any,
  handleAddNewContact: PropTypes.func,
  handleAddNewField: PropTypes.func,
  handleBasicContactChange: PropTypes.func,
  handleContactDetailChange: PropTypes.func,
  handleRemoveContact: PropTypes.func,
  handleRemoveField: PropTypes.func,
  handleURLChange: PropTypes.func,
  isDownloadableContent: PropTypes.bool,
  removeURLField: PropTypes.func,
  responseUrl: PropTypes.string,
  setAlertCategory: PropTypes.func,
  setAllowDownload: PropTypes.func,
  setBlockList: PropTypes.func,
  setContactFields: PropTypes.func,
  setCreatorDescription: PropTypes.func,
  setCredit: PropTypes.func,
  setDate: PropTypes.func,
  setDoNotPlaceOntop: PropTypes.func,
  setGraphicType: PropTypes.func,
  setHideTMX: PropTypes.func,
  setIsGraphicContent: PropTypes.func,
  setIsInboxContentDownlodable: PropTypes.func,
  setIsTmxExpert: PropTypes.func,
  setIsUgc: PropTypes.func,
  setLocations: PropTypes.func,
  setPostURL: PropTypes.func,
  setScheduled: PropTypes.func,
  setSenderOrg: PropTypes.func,
  setShowContact: PropTypes.func,
  setShowDateAndTime: PropTypes.func,
  setSuccess: PropTypes.func,
  setSummary: PropTypes.func,
  setTags: PropTypes.func,
  setWaterMark: PropTypes.func,
  setHidePost: PropTypes.func
};

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import './SellerMediaDetails.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import Icon from '../../components/atoms/Icon/Icon';
import useScrollDisabling from '../../functionality/Hooks/rootComponentScroll';
import DropDown from '../../components/atoms/DropDown/DropDown';
import Transfer from '../../components/organisms/FundTransfer/transfer';

import {
  getSellerMediaDetails,
  sellerMediaCost,
  getNonMemberSellersDetails,
  transferFunds
} from '../../api/actions/sellers-actions';
import { adminOrgs } from '../../api/actions/org-actions';

const SellerMediaDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { loadingMediaDetails, mediaDetails, sellerDetails } = useSelector(
    (state) => state.sellersReducer
  );
  const { loading, organizations } = useSelector((state) => state.orgReducer);

  const dispatch = useDispatch();

  const { setDisabled } = useScrollDisabling(false);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState(null);
  const [priceOverrides, setPriceOverrides] = useState([
    { company_name: null, cost_cents: null, company_id: null },
    { company_name: null, cost_cents: null, company_id: null },
    { company_name: null, cost_cents: null, company_id: null },
    { company_name: null, cost_cents: null, company_id: null }
  ]);
  const [purchaseHistory, setPurchaseHistory] = useState([
    { newsroom: null, price: null, time: null, status: null },
    { newsroom: null, price: null, time: null, status: null },
    { newsroom: null, price: null, time: null, status: null },
    { newsroom: null, price: null, time: null, status: null }
  ]);
  const [showPriceOverrideInput, setShowPriceOverrideInput] = useState(false);
  const [priceOverrideInfo, setPriceOverrideInfo] = useState({});
  const [pricingInfo, setPricingInfo] = useState({});
  const [edit, setEdit] = useState(false);
  const [updatingPricing, setUpdatingPricing] = useState(false);
  const [searchOrg, setSearchOrg] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [transferInfo, setTransferInfo] = useState({});
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [transferResponse, setTransferResponse] = useState('');

  const [formValues, setFormValues] = useState({
    source_type: 'bank_account',
    description: '',
    share_amount_cents: '',
    seller_id: location?.state?.sellerId
  });

  const transferFund = useCallback(() => {
    setLoadingTransfer(true);
    dispatch(
      transferFunds(
        {
          ...formValues,
          sale_amount_cents: parseInt(transferInfo.saleAmount) * 100,
          stripe_payment_id: sellerDetails.stripe_account_id
        },
        (res) => {
          setLoadingTransfer(false);
          setTransferResponse(res);
          setTimeout(() => {
            if (res === 'success') setTransferModal(false);
          }, 1500);
        }
      )
    );
  }, [formValues, dispatch, transferInfo.saleAmount, sellerDetails.stripe_account_id]);

  useEffect(() => {
    if (mediaDetails.purchase_list) {
      setPurchaseHistory(
        mediaDetails.purchase_list.map((e) => ({
          newsroom: e.company_name,
          price: e.sale_amount_cents / 100,
          time: e.sale_datetime,
          status: e.is_transferred ? 'Complete' : 'Transfer'
        }))
      );
    } else if (mediaDetails.purchase_list === null) {
      setPurchaseHistory([]);
    }

    if (mediaDetails.cost_info) {
      setPriceOverrides(
        mediaDetails.cost_info.org_specific_cost ? mediaDetails.cost_info.org_specific_cost : []
      );
    }
  }, [mediaDetails.purchase_list, mediaDetails.cost_info]);

  useEffect(() => {
    setDisabled(document.getElementsByClassName('tmx-modal')[0]);
  });

  useEffect(() => {
    if (location?.state?.sellerId) {
      dispatch(
        getSellerMediaDetails({
          seller_id: location?.state?.sellerId,
          media_id: location?.state?.mediaId,
          source: location?.state?.source
        })
      );
    }
    if (!sellerDetails.stripe_account_id) {
      dispatch(getNonMemberSellersDetails({ seller_id: location?.state?.sellerId }));
    }
  }, [dispatch, location, sellerDetails.stripe_account_id]);

  const sortPriceOverrides = (by) => {
    setOrderBy(by);
    setOrder(order === -1 ? 1 : -1);

    if (by === 'title' && order === -1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.title > b.title ? -1 : 1)));
    } else if (by === 'title' && order === 1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.title > b.title ? 1 : -1)));
    } else if (by === 'contentId' && order === -1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.media_id > b.media_id ? -1 : 1)));
    } else if (by === 'contentId' && order === 1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.media_id > b.media_id ? 1 : -1)));
    } else if (by === 'source' && order === -1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.source > b.source ? -1 : 1)));
    } else if (by === 'source' && order === 1) {
      setPriceOverrides([...priceOverrides].sort((a, b) => (a.source > b.source ? 1 : -1)));
    }
  };

  return (
    <>
      {transferModal && (
        <Transfer
          dispatch={dispatch}
          sellerId={location?.state?.sellerId}
          stripePaymentId={sellerDetails.stripe_account_id}
          closeModal={() => setTransferModal(false)}
          saleAmount={transferInfo.saleAmount}
          formValues={formValues}
          setFormValues={setFormValues}
          loadingTransfer={loadingTransfer}
          transferFunds={transferFund}
          transferResponse={transferResponse}
        />
      )}
      <div className="tmx-sellerMediaDetail__backButtonContainer">
        <div
          className={classNames('tmx-sellerMediaDetail__backBtnContainer', {
            'tmx-sellerMediaDetail__backBtnContainer--loading': loadingMediaDetails
          })}>
          {!loadingMediaDetails && (
            <Button
              buttonIcon="chevron"
              iconSize="medium"
              className="tmx-sellerMediaDetail__backContent black"
              handleClick={() => history.push('/seller-details')}>
              Back to Search
            </Button>
          )}
        </div>
      </div>
      <section className="tmx-sellerMediaDetail">
        <section className="tmx-sellerMediaDetail__organizationInfo">
          <div className="tmx-sellerMediaDetail__orgSettingsHeader">
            <h1>Content details</h1>
          </div>
          <div className="tmx-sellerMediaDetail__accountInfo">
            {!loadingMediaDetails && <h1>{mediaDetails.title}</h1>}

            {mediaDetails.tmx_url && !loadingMediaDetails && (
              <p>
                {' '}
                <b>View on TMX:</b>{' '}
                <a href={mediaDetails.tmx_url} target="_blank" rel="noopener noreferrer">
                  {mediaDetails.tmx_url}
                </a>
              </p>
            )}
          </div>
        </section>
        <section className="tmx-sellerMediaDetail__detailsInfo">
          <div className="tmx-sellerMediaDetail__orgSettingsHeader">
            <h1>Pricing</h1>
            {!loadingMediaDetails && (
              <Button
                className="tmx-sellerMediaDetail__createButton"
                handleClick={() => {
                  if (
                    (pricingInfo.priceModel && pricingInfo.priceModel !== '') ||
                    (pricingInfo.price && pricingInfo.price !== '')
                  ) {
                    setUpdatingPricing(true);
                    dispatch(
                      sellerMediaCost(
                        {
                          media_id: location?.state?.mediaId,
                          dynamic_price: pricingInfo.priceMode === 'Dynamic Price',
                          seller_id: location?.state?.sellerId,
                          source: location?.state?.source,
                          default_cost_cents: pricingInfo.price
                            ? pricingInfo.price * 100
                            : mediaDetails.cost_info?.default_cost_cents
                        },
                        () => {
                          setUpdatingPricing(false);
                          setEdit(!edit);
                        }
                      )
                    );
                  } else {
                    setEdit(!edit);
                  }
                }}
                disabled={updatingPricing || loadingMediaDetails}>
                {!edit
                  ? 'Edit'
                  : (pricingInfo.priceModel && pricingInfo.priceModel !== '') ||
                    (pricingInfo.price && pricingInfo.price !== '')
                  ? 'Save'
                  : 'Cancel'}
              </Button>
            )}
          </div>
          <div className="tmx-sellerMediaDetail__formInlineWrapper">
            <div className="tmx-sellerMediaDetail__formInline space">
              <div className="tmx-sellerMediaDetail__formTitle">Content price</div>
              <div className="tmx-sellerMediaDetail__formDetails">
                This is the set price for any newsroom that does not have a unique price override..{' '}
              </div>
            </div>
            <div className="tmx-sellerMediaDetail__contentPriceContainer">
              <div
                className={classNames('tmx-sellerMediaDetail__formInline', {
                  'tmx-sellerMediaDetail__formInline--loading':
                    loadingMediaDetails || updatingPricing
                })}>
                {!loadingMediaDetails && !updatingPricing && (
                  <>
                    <label className="tmx-input__uploadFormLabel" htmlFor="pricingModel">
                      Pricing Model
                    </label>
                    <DropDown
                      onChange={(event) => {
                        setPricingInfo({ ...pricingInfo, priceModel: event.target.value });
                      }}
                      optionSelected={
                        mediaDetails.cost_info?.dynamic_price
                          ? 'Dynamic Price'
                          : pricingInfo.priceModel || 'Fixed Price'
                      }
                      className="tmx-sellerMediaDetail__dropDown grey"
                      data={['Fixed Price', 'Dynamic Price']}
                      id="pricingModel"
                      disabled={!edit || updatingPricing || loadingMediaDetails}
                    />
                  </>
                )}
              </div>
              <div
                className={classNames('tmx-sellerMediaDetail__formInline price', {
                  'tmx-sellerMediaDetail__formInline--loading':
                    loadingMediaDetails || updatingPricing
                })}>
                {!loadingMediaDetails && !updatingPricing && (
                  <Input
                    id="Price"
                    name="Price"
                    darkBorder
                    value={`$${
                      edit
                        ? pricingInfo.price || ''
                        : mediaDetails.cost_info?.default_cost_cents / 100
                    }`}
                    onChange={(event) => {
                      setPricingInfo({
                        ...pricingInfo,
                        price: event.target.value.replace('$', '')
                      });
                    }}
                    type="text"
                    label={'Price'}
                    htmlFor={'Price'}
                    disabled={!edit || updatingPricing || loadingMediaDetails}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="tmx-sellerMediaDetail__detailsInfo">
          <div className="tmx-sellerMediaDetail__orgSettingsHeader">
            <h1>Price overrides</h1>{' '}
          </div>
          <div className="tmx-sellerMediaDetail__mediaContainer priceOverrides">
            <div className="tmx-sellerMediaDetail__mediaContainerName header">
              Newsroom{' '}
              <Icon
                name={
                  orderBy === 'contentId' && order === 1
                    ? 'sort-up'
                    : orderBy === 'contentId' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('contentId')}
              />
            </div>
            <div className="tmx-sellerMediaDetail__mediaContainerEmail header">
              Price{' '}
              <Icon
                name={
                  orderBy === 'source' && order === 1
                    ? 'sort-up'
                    : orderBy === 'source' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('source')}
              />
            </div>
          </div>
          <div className="tmx-sellerMediaDetail__media">
            {priceOverrides.map((price, key) => (
              <div
                className={classNames('tmx-sellerMediaDetail__mediaContainer priceOverrides', {
                  'tmx-sellerMediaDetail__mediaContainer--loading': loadingMediaDetails
                })}
                key={key}>
                {!loadingMediaDetails && (
                  <>
                    <div
                      className={classNames('tmx-sellerMediaDetail__mediaContainerName', {
                        'tmx-sellerMediaDetail__mediaContainerName--bold': price.company_name
                      })}>
                      {price.company_name}
                    </div>
                    <div className="tmx-sellerMediaDetail__mediaContainerEmail">
                      ${price.cost_cents / 100}
                    </div>
                  </>
                )}
              </div>
            ))}
            {showPriceOverrideInput && (
              <div className="tmx-sellerMediaDetail__mediaContainer priceOverrides">
                <div>
                  <Input
                    id="newsroom"
                    name="newsroom"
                    darkBorder
                    value={priceOverrideInfo.newsroom}
                    onChange={(event) => {
                      if (!searchOrg) setSearchOrg(true);
                      dispatch(adminOrgs({ search: event.target.value }));
                      setPriceOverrideInfo({ ...priceOverrideInfo, newsroom: event.target.value });
                    }}
                    type="text"
                    label={'Newsroom'}
                    htmlFor={'newsroom'}
                    disabled={updatingPricing}
                  />
                  {!loading && searchOrg && (
                    <div className="tmx-sellerMediaDetail__orgContainer">
                      {organizations?.orgs?.map((org, ind) => (
                        <Button
                          className="tmx-sellerMediaDetail__orgBtn"
                          key={ind}
                          handleClick={() => {
                            setSearchOrg(false);
                            setPriceOverrideInfo({
                              ...priceOverrideInfo,
                              newsroom: org.org_name,
                              company_id: org.company_id
                            });
                          }}>
                          {org.org_name}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="tmx-sellerMediaDetail__priceInputContainer">
                  <Input
                    id="fixedPrice"
                    name="fixedPrice"
                    darkBorder
                    value={priceOverrideInfo.price}
                    onChange={(event) => {
                      setPriceOverrideInfo({ ...priceOverrideInfo, price: event.target.value });
                    }}
                    type="text"
                    label={'Fixed price'}
                    htmlFor={'fixedPrice'}
                    disabled={updatingPricing}
                  />
                  <Button
                    buttonType="blue"
                    className="tmx-sellerMediaDetail__btn"
                    spinner={updatingPricing}
                    handleClick={() => {
                      setUpdatingPricing(true);
                      dispatch(
                        sellerMediaCost(
                          {
                            media_id: location?.state?.mediaId,
                            dynamic_price: mediaDetails.cost_info?.dynamic_price,
                            seller_id: location?.state?.sellerId,
                            source: location?.state?.source,
                            default_cost_cents: mediaDetails.cost_info?.default_cost_cents,
                            org_specific_cost: [
                              {
                                cost_cents: priceOverrideInfo.price * 100,
                                company_id: priceOverrideInfo.company_id
                              }
                            ]
                          },
                          () => {
                            setUpdatingPricing(false);
                            setShowPriceOverrideInput(false);
                          }
                        )
                      );
                    }}
                    disabled={
                      updatingPricing ||
                      !priceOverrideInfo.price ||
                      priceOverrideInfo.price === '' ||
                      !priceOverrideInfo.company_id ||
                      priceOverrideInfo.company_id === ''
                    }>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="tmx-sellerMediaDetail__mediaContainerInviteBtn">
            <Button
              buttonType="blue"
              className="secondary tmx-sellerMediaDetail__btn"
              buttonIcon={!showPriceOverrideInput && 'plus'}
              handleClick={() => setShowPriceOverrideInput(!showPriceOverrideInput)}
              disabled={loadingMediaDetails}>
              {!showPriceOverrideInput ? 'Add Pricing Override' : 'Cancel Pricing Override'}
            </Button>
          </div>
        </section>

        <section className="tmx-sellerMediaDetail__detailsInfo">
          <div className="tmx-sellerMediaDetail__orgSettingsHeader">
            <h1>Purchase history</h1>{' '}
          </div>
          <div className="tmx-sellerMediaDetail__mediaContainer">
            <div className="tmx-sellerMediaDetail__mediaContainerName header">
              Newsroom{' '}
              <Icon
                name={
                  orderBy === 'contentId' && order === 1
                    ? 'sort-up'
                    : orderBy === 'contentId' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('contentId')}
              />
            </div>
            <div className="tmx-sellerMediaDetail__mediaContainerEmail header">
              Date/Time{' '}
              <Icon
                name={
                  orderBy === 'source' && order === 1
                    ? 'sort-up'
                    : orderBy === 'source' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('source')}
              />
            </div>

            <div className="tmx-sellerMediaDetail__mediaContainerEmail header">
              Purchase price{' '}
              <Icon
                name={
                  orderBy === 'title' && order === 1
                    ? 'sort-up'
                    : orderBy === 'title' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('title')}
              />
            </div>

            <div className="tmx-sellerMediaDetail__mediaContainerEmail header">
              Transfer status{' '}
              <Icon
                name={
                  orderBy === 'title' && order === 1
                    ? 'sort-up'
                    : orderBy === 'title' && order === -1
                    ? 'sort-down'
                    : 'sort-default'
                }
                size="micro"
                className="tmx-sellerMediaDetail__sortIcon"
                onClick={() => sortPriceOverrides('title')}
              />
            </div>
          </div>
          <div className="tmx-sellerMediaDetail__media">
            {purchaseHistory.map((purchase, key) => (
              <div
                className={classNames('tmx-sellerMediaDetail__mediaContainer', {
                  'tmx-sellerMediaDetail__mediaContainer--loading': loadingMediaDetails
                })}
                key={key}>
                {!loadingMediaDetails && (
                  <>
                    <div
                      className={classNames('tmx-sellerMediaDetail__mediaContainerName', {
                        'tmx-sellerMediaDetail__mediaContainerName--bold': purchase.newsroom
                      })}>
                      {purchase.newsroom}
                    </div>
                    <div className="tmx-sellerMediaDetail__mediaContainerEmail">
                      {purchase.time}
                    </div>
                    <div className="tmx-sellerMediaDetail__mediaContainerEmail">
                      ${purchase.price}
                    </div>
                    <div className="tmx-sellerMediaDetail__mediaContainerAction">
                      <Button
                        type="button"
                        className={`tmx-sellerMediaDetail__${purchase.status?.toLowerCase()}`}
                        handleClick={() => {
                          if (purchase.status === 'Transfer') {
                            setTransferModal(true);
                            setTransferInfo({ ...transferInfo, saleAmount: purchase.price });
                          }
                        }}
                        disabled={true}>
                        {purchase.status}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

export default SellerMediaDetails;

import {
  ADMIN_ORG_RESPONSE,
  ADMIN_ORGS_ERROR,
  ADMIN_ORGS_LOADING,
  ADMIN_ORGS_RESPONSE,
  CLEAR_CREATE_ADMIN_ORG_ERROR,
  CREATE_ADMIN_ORG_ERROR,
  EDIT_MEMBERSHIP_ERROR,
  EDIT_MEMBERSHIP_LOADING,
  EDIT_MEMBERSHIP_RESPONSE,
  EDIT_ORG_LOADING,
  EDIT_ORG_RESPONSE,
  EMBED_QUICKSIGHT_ERROR,
  EMBED_QUICKSIGHT_LOADING,
  EMBED_QUICKSIGHT_RESPONSE,
  INVITE_ERROR,
  INVITE_LOADING,
  INVITES_SENT_SUCCESS,
  LOADING_MULTISELECT_LIST,
  NEWSROOM_PAYMENT_STATUS_ERROR,
  NEWSROOM_PAYMENT_STATUS_LOADING,
  NEWSROOM_PAYMENT_STATUS_RESPONSE,
  NUMBER_OF_INVITES,
  PAYMENT_INVITE_ERROR,
  PAYMENT_INVITE_LOADING,
  PAYMENT_INVITE_RESPONSE,
  PAYMENT_STATUS_ERROR,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_RESPONSE,
  RECEIVE_ALL_MULTI_SELECT_LISTS,
  RESET_INVITES_SENT_SUCCESS,
  VERIFY_BANK_ERROR,
  VERIFY_BANK_LOADING,
  VERIFY_BANK_RESPONSE
} from '../../constants/action-types';

import {
  EDIT_MEMBERSHIP,
  GET_ADMIN_ORGS,
  GET_EMBED_QUICKSIGHT,
  GET_PAYMENT_STATUS,
  NEWSROOM_PAYMENT_METHOD,
  PAYMENT_METHOD_INVITE,
  UPLOAD_GET_COMPANIES,
  VERIFY_BANK_ACCOUNT,
  ACCESS_PROFILES
} from '../../functionality/networking';
import { callAjaxWCognito } from '../../functionality/CognitoTMX';

function setAdminOrgsLoading(value) {
  return {
    type: ADMIN_ORGS_LOADING,
    value: value
  };
}

function setPaymentStatusLoading(value) {
  return {
    type: PAYMENT_STATUS_LOADING,
    value: value
  };
}

function setEditMembershipLoading(value) {
  return {
    type: EDIT_MEMBERSHIP_LOADING,
    value: value
  };
}

function setPaymentInviteLoading(value) {
  return {
    type: PAYMENT_INVITE_LOADING,
    value: value
  };
}

function SetNewsroomPaymentLoading(value) {
  return {
    type: NEWSROOM_PAYMENT_STATUS_LOADING,
    value: value
  };
}

function setPaymentInviteError(error) {
  return {
    type: PAYMENT_INVITE_ERROR,
    paymentInviteErrorMsg: error
  };
}
function setPaymentInviteResponse(value) {
  return {
    type: PAYMENT_INVITE_RESPONSE,
    value: value
  };
}

function setVerifyBankLoading(value) {
  return {
    type: VERIFY_BANK_LOADING,
    value: value
  };
}

function setVerifyBankError(error) {
  return {
    type: VERIFY_BANK_ERROR,
    verifyError: error
  };
}

function SetNewsroomPaymentError(error) {
  return {
    type: NEWSROOM_PAYMENT_STATUS_ERROR,
    verifyError: error
  };
}

function setVerifyBankResponse(value) {
  return {
    type: VERIFY_BANK_RESPONSE,
    value: value
  };
}

function setEditOrgLoading(value) {
  return {
    type: EDIT_ORG_LOADING,
    value: value
  };
}

function setInviteLoading(value) {
  return {
    type: INVITE_LOADING,
    value: value
  };
}

function setAdminOrgsError() {
  return {
    type: ADMIN_ORGS_ERROR
  };
}

function setPaymentStatusError() {
  return {
    type: PAYMENT_STATUS_ERROR
  };
}

function setMembershipError() {
  return {
    type: EDIT_MEMBERSHIP_ERROR
  };
}

function setCreateAdminOrgError() {
  return {
    type: CREATE_ADMIN_ORG_ERROR
  };
}

export function setClearCreateAdminOrgError() {
  return {
    type: CLEAR_CREATE_ADMIN_ORG_ERROR
  };
}

function setInviteError() {
  return {
    type: INVITE_ERROR
  };
}

function setAdminOrgsResponse(value) {
  return {
    type: ADMIN_ORGS_RESPONSE,
    value: value
  };
}

function setPaymentStatusResponse(value) {
  return {
    type: PAYMENT_STATUS_RESPONSE,
    value: value
  };
}

function setMembershipResponse(value) {
  return {
    type: EDIT_MEMBERSHIP_RESPONSE,
    value: value
  };
}

function setAdminOrgResponse(value) {
  return {
    type: ADMIN_ORG_RESPONSE,
    value: value
  };
}

function SetNewsroomPaymentSuccess(value) {
  return {
    type: NEWSROOM_PAYMENT_STATUS_RESPONSE,
    value: value
  };
}

function setEditOrgsResponse(value) {
  return {
    type: EDIT_ORG_RESPONSE,
    value: value
  };
}

function setEmdbedSightLoading(value) {
  return {
    type: EMBED_QUICKSIGHT_LOADING,
    value: value
  };
}

function setEmdbedSightError() {
  return {
    type: EMBED_QUICKSIGHT_ERROR
  };
}

function setEmdbedSightResponse(value) {
  return {
    type: EMBED_QUICKSIGHT_RESPONSE,
    value: value
  };
}

function resetInvitesSentSuccessValue() {
  return {
    type: RESET_INVITES_SENT_SUCCESS
  };
}

function invitesSentSuccess() {
  return {
    type: INVITES_SENT_SUCCESS
  };
}

function saveNoOfInvitesSent(numberOfInvites) {
  return {
    type: NUMBER_OF_INVITES,
    numberOfInvites: numberOfInvites
  };
}

export function setNoOfInvitesSent(numberOfInvites) {
  return (dispatch) => {
    dispatch(saveNoOfInvitesSent(numberOfInvites));
  };
}

function setLoading() {
  return {
    type: LOADING_MULTISELECT_LIST
  };
}

function receiveMultiSelectLists(orgArray, locationsArray, tagsArray, mediaMarkets) {
  return {
    type: RECEIVE_ALL_MULTI_SELECT_LISTS,
    multiSelectLists: {
      tagsArray: tagsArray,
      orgArray: orgArray,
      locationsArray: locationsArray,
      mediaMarkets: mediaMarkets
    }
  };
}

export function verifyBankAccount(payload, callback) {
  return (dispatch) => {
    dispatch(setVerifyBankLoading(true));
    callAjaxWCognito(
      VERIFY_BANK_ACCOUNT,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(setVerifyBankResponse(parsedResponse));
          callback && callback();
        } else {
          dispatch(setVerifyBankError(parsedResponse));
        }
      },
      (error) => {
        dispatch(setVerifyBankError(JSON.parse(error)));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function sendPaymentInvite(payload, callback) {
  return (dispatch) => {
    dispatch(setPaymentInviteLoading(true));
    callAjaxWCognito(
      PAYMENT_METHOD_INVITE,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(setPaymentInviteResponse(parsedResponse));
          callback && callback();
        } else {
          dispatch(setPaymentInviteError(parsedResponse));
        }
      },
      (error) => {
        dispatch(setPaymentInviteError(JSON.parse(error)));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function sendNewsroomPayment(payload, callback) {
  return (dispatch) => {
    dispatch(SetNewsroomPaymentLoading(true));
    callAjaxWCognito(
      NEWSROOM_PAYMENT_METHOD,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(SetNewsroomPaymentSuccess(parsedResponse));
          callback && callback();
        } else {
          dispatch(SetNewsroomPaymentError(parsedResponse));
        }
      },
      (error) => {
        dispatch(SetNewsroomPaymentError(JSON.parse(error)));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function editMembership(payload, callback) {
  return (dispatch) => {
    dispatch(setEditMembershipLoading(true));
    callAjaxWCognito(
      EDIT_MEMBERSHIP,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(setMembershipResponse(parsedResponse));
          callback && callback();
        } else {
          dispatch(setMembershipError());
        }
      },
      () => {
        dispatch(setMembershipError());
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function paymentStatus(payload, callback) {
  return (dispatch) => {
    dispatch(setPaymentStatusLoading(true));
    callAjaxWCognito(
      GET_PAYMENT_STATUS,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(setPaymentStatusResponse(parsedResponse));
          callback && callback();
        } else {
          dispatch(setPaymentStatusError());
        }
      },
      () => {
        dispatch(setPaymentStatusError());
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function adminOrgs(payload, callback, loadingCallBack, type, resetState) {
  return (dispatch) => {
    if (loadingCallBack) {
      loadingCallBack(true);
    } else {
      if (payload?.update) {
        if (payload?.update?.invitees) {
          dispatch(setInviteLoading(true));
        } else {
          dispatch(setEditOrgLoading(true));
        }
      } else {
        dispatch(setAdminOrgsLoading(true));
      }
    }
    callAjaxWCognito(
      type === 'get_profile_details' ||
        type === 'update_profile_details' ||
        type === 'get_all_profile_details'
        ? ACCESS_PROFILES
        : GET_ADMIN_ORGS,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (type === 'update_profile_details' || payload?.update) {
          if (parsedResponse.success) {
            if (payload?.update?.invitees) {
              dispatch(invitesSentSuccess());
            } else {
              dispatch(setEditOrgsResponse(parsedResponse));
              if (resetState) {
                resetState();
              }
            }
          }
        } else if (type === 'get_profile_details' || payload?.get) {
          if (parsedResponse.success) {
            dispatch(setAdminOrgResponse(parsedResponse));
          }
        } else {
          if (parsedResponse.success) {
            dispatch(setAdminOrgsResponse(parsedResponse));
          }
        }
        loadingCallBack && loadingCallBack(false);
        callback && callback(parsedResponse?.org?.company_id);
      },
      () => {
        if (payload?.update?.invitees) {
          dispatch(setInviteError());
        } else if (payload?.create) {
          dispatch(setCreateAdminOrgError());
          loadingCallBack && loadingCallBack(false);
        } else {
          dispatch(setAdminOrgsError());
        }
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function emdbedQuickSight() {
  return (dispatch) => {
    dispatch(setEmdbedSightLoading(true));
    callAjaxWCognito(
      GET_EMBED_QUICKSIGHT,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(setEmdbedSightResponse(parsedResponse));
        }
      },
      () => {
        dispatch(setEmdbedSightError());
      },
      JSON.stringify({})
    );
  };
}

function getPublishLists(dispatch) {
  const payLoad = {};

  const handleError = (response) => {
    console.warn(response);
  };
  const handleSuccess = (response) => {
    const parsedResponse = JSON.parse(response);

    if (parsedResponse.success) {
      dispatch(
        receiveMultiSelectLists(
          [...parsedResponse.companies],
          [...parsedResponse.locations_all.map((x) => x.city + ', ' + x.state)],
          [...parsedResponse.tags],
          [...parsedResponse.locations.map((x) => x.city + ', ' + x.state)]
        )
      );
    }
  };

  callAjaxWCognito(UPLOAD_GET_COMPANIES, handleSuccess, handleError, JSON.stringify(payLoad));
}

export function shouldFetchLists() {
  return (dispatch) => {
    dispatch(setLoading());
    getPublishLists(dispatch);
  };
}

export function resetInvitesSentSuccess() {
  return (dispatch) => {
    dispatch(resetInvitesSentSuccessValue());
  };
}

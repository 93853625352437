import React, { useState, Suspense } from 'react';

import { useDispatch } from 'react-redux';

import './Tools.scss';

import { useHistory } from 'react-router-dom';

import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import { RecommendContext } from '../../api/context/recommend-context';

import { getRecLists } from '../../api/actions/recommend-actions';

const Recommender = React.lazy(() =>
  import('../../components/molecules/RecommenderForm/Recommender')
);
const ContentUploader = () => {
  const history = useHistory();
  const [showRecPanel, setShowRecPanel] = useState(false);

  const dispatch = useDispatch();

  return (
    <section className="tmx-tools">
      <div className="tmx-tools__uploaders">
        <div className="tmx-tools__uploader carousel">
          <Icon name="tray-discover" size="ginormous" className="tmx-tools__icon" />
          <div className="tmx-tools__title">Marketing Carousel</div>
          <div className="tmx-tools__subtitle">
            Add, edit, or remove content from the marketing carousel.
          </div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => history.push('/marketing-carousel')}>
              Edit carousel
            </Button>
          </div>
        </div>
        <div className="tmx-tools__uploader carousel">
          <Icon
            name="send"
            size="evenBigger"
            className="tmx-tools__icon tmx-tools__icon--marginChange"
          />
          <div className="tmx-tools__title tmx-tools__title--titleAlig">Best of TMX emails</div>
          <div className="tmx-tools__subtitle">
            Create curated ‘best of’ emails to send to users.
          </div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => history.push('/emailAlerts')}>
              Create email
            </Button>
          </div>
        </div>
        <div className="tmx-tools__uploader">
          <Icon name="cloud-upload_big" size="large" className="tmx-tools__icon" />
          <div className="tmx-tools__title">Uploader</div>
          <div className="tmx-tools__subtitle">Upload new content or contacts to TMX.</div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => history.push('/uploader')}>
              Upload content
            </Button>
          </div>
        </div>
        <div className="tmx-tools__uploader removeSpace">
          <Icon
            name="send"
            size="evenBigger"
            className="tmx-tools__icon tmx-tools__icon--marginChange"
          />
          <div className="tmx-tools__title tmx-tools__title--titleAlig">Recommender</div>
          <div className="tmx-tools__subtitle">
            Let specific users know about individual content.
          </div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => {
                setShowRecPanel(true);
                dispatch(getRecLists());
              }}>
              Recommend content
            </Button>
          </div>
        </div>

        <div className="tmx-tools__uploader removeSpace">
          <Icon
            name="send"
            size="evenBigger"
            className="tmx-tools__icon tmx-tools__icon--marginChange"
          />
          <div className="tmx-tools__title tmx-tools__title--titleAlig">
            Add Org to Uploader Dropdown
          </div>
          <div className="tmx-tools__subtitle">
            Add an existing org to the dropdown list on Cleared Content and Pitches
          </div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => {
                history.push('/add-orgs');
              }}>
              Add New
            </Button>
          </div>
        </div>

        <div className="tmx-tools__uploader removeSpace">
          <Icon name="user-plus" size="huge" className="tmx-tools__icon tmx-tools__icon" />
          <div className="tmx-tools__title">Add Clients to Receive Requests</div>
          <div className="tmx-tools__subtitle">
            Add clients to receive expert requests from users.
          </div>
          <div>
            <Button
              className="tmx-tools__btn arrowBtn"
              buttonIconClassName="tmx-tools__buttonIcon"
              iconSize="ginormous"
              buttonIcon="arrow-right"
              handleClick={() => history.push('/experts')}>
              Add New
            </Button>
          </div>
        </div>
      </div>
      {showRecPanel ? (
        <RecommendContext.Provider
          value={{
            closePanel: () => setShowRecPanel(false)
          }}>
          <Suspense fallback={<></>}>
            <Recommender />
          </Suspense>
        </RecommendContext.Provider>
      ) : null}
    </section>
  );
};

export default ContentUploader;

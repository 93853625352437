import React from 'react';

import './NoAccess.scss';

const NoAccess = () => {
  return (
    <section className="tmx-noAccess">
      <img src="/images/error.svg" alt="error" className="tmx-noAccess__image" />
      <div className="tmx-noAccess__textWrapper">
        <p className="tmx-noAccess__text">You do not have access because you are not an Admin </p>
      </div>
    </section>
  );
};

export default NoAccess;

import moment from 'moment';

export const formattedDate = (date) => {
  let dateTimeSplit = moment(date).format('LLLL').split(' ');
  const time = dateTimeSplit.splice(dateTimeSplit.length - 2, 2);
  time.splice(1, 1, time[1].toLowerCase());
  const formattedTime = time.join(' ').replace(/,/g, ' ');
  const dt = dateTimeSplit.join(' ');
  return `${dt} at ${formattedTime}`;
};

import {
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  RECEIVE_LOGIN_ERROR,
  LOG_OUT,
  USER_LOADING
} from '../../constants/action-types';

import { getUserPool, login, callAjaxWCognito } from '../../functionality/CognitoTMX';
import { GET_USER_DETAILS } from '../../functionality/networking';

function requestLogin() {
  return {
    type: REQUEST_LOGIN
  };
}

export function receiveLogIn(data) {
  return {
    type: RECEIVE_LOGIN,
    userData: data
  };
}

function loginError() {
  return {
    type: RECEIVE_LOGIN_ERROR
  };
}

function setLogOut() {
  return {
    type: LOG_OUT
  };
}

function setLoading() {
  return {
    type: USER_LOADING
  };
}

function signIn({ username, password }) {
  return (dispatch) => {
    dispatch(requestLogin());

    const handleSuccess = (data) => {
      dispatch(getUserData(data, true));
    };
    const handleError = () => {
      dispatch(loginError());
    };
    login(username, password, handleSuccess, handleError);
  };
}

function shouldSignIn(state) {
  return !state.signInReducer.loggedIn;
}

export function signInIfNeeded(username, password) {
  return (dispatch, getState) => {
    const state = getState();

    if (shouldSignIn(state)) {
      return dispatch(
        signIn({
          username: username,
          password: password
        })
      );
    }
  };
}

export function logOut() {
  return (dispatch) => {
    const cognitoUser = getUserPool().getCurrentUser();
    if (cognitoUser !== null) {
      dispatch(setLogOut());
      cognitoUser.signOut();
      window.localStorage.clear();
      window.location.replace('/');
    }
  };
}

export function getUserData(data, setLoggedIn) {
  return (dispatch) => {
    dispatch(setLoading());
    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      if (parsedResponse.success) {
        const currentDate = new Date();
        const userData = !data
          ? { ...JSON.parse(localStorage.getItem('userData')), ...parsedResponse }
          : { ...data, ...parsedResponse };

        if (data) {
          localStorage.setItem(
            'userData',
            JSON.stringify({
              value: userData,
              expiry: currentDate.getTime() + 31556952000,
              usage_guidelines: parsedResponse.usage_guidelines
            })
          );
        } else {
          localStorage.setItem(
            'userData',
            JSON.stringify({
              value: parsedResponse,
              expiry: currentDate.getTime() + 31556952000,
              usage_guidelines: parsedResponse.usage_guidelines
            })
          );
        }
        dispatch(receiveLogIn(userData));

        if (setLoggedIn) {
          window.location.replace('/organization-settings');
        }
      }
    };

    const handleError = (error) => {
      return error;
    };
    callAjaxWCognito(GET_USER_DETAILS, handleSuccess, handleError, JSON.stringify({}));
  };
}

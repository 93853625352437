import React, { useCallback, useState } from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FileUpload.scss';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { getSignedUrl, getAccessSignedUrl } from '../../../api/actions/file-Upload-actions';

import ProgressBar from '../ProgressBar/ProgressBar';

const FileUpload = ({
  isDownloadableContent,
  isThumbnail,
  isVideo,
  onChange,
  setThumbNailList,
  thumbNailList,
  view,
  setIsThumbnail,
  isUploadingImage,
  diabled,
  isProfile,
  page,
  profilePhotoVideoPresent
}) => {
  const [uploadList, setUploadList] = useState([]);
  const [fileType, setFileType] = useState(
    view === 'contact' ||
      view === 'article' ||
      view === 'boost' ||
      isVideo ||
      ((view === 'INBOX' || view === 'content') && isUploadingImage)
      ? 'image/*'
      : 'image/*, video/mp4, video/quicktime, video/mpeg'
  );
  const [files, setFiles] = useState([]);
  const [successFiles, setSuccessFiles] = useState([]);
  const changeCallback = useCallback(
    (keyFiles) => {
      onChange(keyFiles);
    },
    [onChange]
  );
  const handlePreProcess = (file, next) => {
    if (file.type.includes('image') && setThumbNailList) {
      setThumbNailList([...thumbNailList, { link: file.preview, name: file.name }]);
    }

    const fileType = file.type.split('/').shift();
    const fileInList = files.filter((x) => x.name === file.name).length;
    file.next = next;

    if (!fileInList) {
      next(file);
      setFiles([...files, file]);
      setFileType(fileType === 'video' ? 'video/*' : 'image/*');
      setUploadList([...uploadList, { name: file.name, progress: 0 }]);
    }
  };

  const handleProgress = (percent, status, file) => {
    setUploadList(
      uploadList.map((savedFile) => {
        if (savedFile.name === file.name) {
          savedFile.progress = percent;
        }

        return savedFile;
      })
    );
    const fileExistList = successFiles.filter((x) => x.name === file.name);
    if (percent > 0 && !fileExistList.length) {
      changeCallback([
        ...successFiles,
        { type: file.customType, key: file.key, completed: false, name: file.name }
      ]);
    }
  };

  const handleUploadError = (error, file) => {
    setUploadList(
      uploadList.map((savedFile) => {
        if (savedFile.name === file.name) {
          savedFile.failed = true;
        }

        return savedFile;
      })
    );
  };

  const handleFinish = (data) => {
    const successList = [
      ...successFiles,
      { type: data.file.customType, key: data.file.key, completed: true, name: data.file.name }
    ];

    setSuccessFiles(successList);
    changeCallback(successList);
    setUploadList(
      uploadList.map((savedFile) => {
        if (savedFile.name === data.file.name) {
          savedFile.completed = true;
          savedFile.success = true;
        }

        return savedFile;
      })
    );
  };

  return (
    <div className="tmx-fileUpload">
      {!uploadList.length ? (
        <div className="tmx-fileUpload__attachWrapper">
          <Icon
            name={fileType.includes('video') ? 'camera-2' : 'camera-1'}
            className="tmx-fileUpload__mediaAttach"
          />
          <p className="tmx-fileUpload__uploadText">
            {isProfile && profilePhotoVideoPresent
              ? 'Change profile picture'
              : isProfile && !profilePhotoVideoPresent
              ? 'Add a profile photo'
              : isVideo || view === 'contact' || (view === 'article' && !isDownloadableContent)
              ? 'Attach thumbnail image'
              : view === 'article' || ((view === 'INBOX' || view === 'content') && isUploadingImage)
              ? 'Attach picture(s)'
              : (view === 'content' && profilePhotoVideoPresent) ||
                ((view === 'INBOX' || view === 'content') &&
                  !isUploadingImage &&
                  profilePhotoVideoPresent)
              ? 'Change video'
              : view === 'content' ||
                ((view === 'INBOX' || view === 'content') && !isUploadingImage)
              ? 'Attach video'
              : 'Attach'}
          </p>
        </div>
      ) : null}
      {uploadList.length ? (
        <div className="tmx-fileUpload__fileList">
          {uploadList.map((file, index) => (
            <div className="tmx-fileUpload__file" key={index}>
              <div className="tmx-fileUpload__fileDetails">
                <div className="tmx-fileUpload__nameAndStatus">
                  <p className="tmx-fileUpload__fileName">{file.name}</p>
                  {file.completed ? (
                    <span className="tmx-fileUpload__complete">Complete</span>
                  ) : (
                    <span className="tmx-fileUpload__uploading">Attaching</span>
                  )}
                  {file.failed ? <span className="tmx-fileUpload__failed">Failed</span> : null}
                </div>
                <ProgressBar
                  currentStep={file.progress}
                  totalSteps={100}
                  upload
                  complete={file.completed}
                  error={file.failed}
                />
              </div>
              <Button
                className="tmx-fileUpload__fileDelete"
                buttonIcon={!file.failed ? 'close' : 'retry'}
                iconSize="small"
                handleClick={() => {
                  if (setIsThumbnail) {
                    setIsThumbnail(false);
                  }
                  if (setThumbNailList) {
                    const newList = thumbNailList.filter((url) => url.name !== file.name);
                    setThumbNailList(newList);
                  }
                  if (!file.failed) {
                    const successFilesEdited = uploadList.filter(
                      (savedFile) => savedFile.name !== file.name
                    );

                    const newFiles = files.filter((savedFile) => savedFile.name !== file.name);

                    if (!successFilesEdited.length) {
                      setFileType('image/*,video/*');
                    }

                    setFiles(newFiles);
                    setUploadList(successFilesEdited);
                    const successFilesCallBackValues = successFiles.filter(
                      (successFile) => successFile.name !== file.name
                    );
                    setSuccessFiles(successFilesCallBackValues);
                    changeCallback(successFilesCallBackValues);
                  } else {
                    const currentFile = files.filter((x) => x.name === file.name).pop();

                    currentFile.next(currentFile);
                  }
                }}></Button>
            </div>
          ))}
          {(((fileType === 'image/*' && uploadList.length <= 59 && !isThumbnail) ||
            view === 'article') &&
            isDownloadableContent) ||
          ((view === 'INBOX' || view === 'content') && isUploadingImage) ? (
            <p className="tmx-fileUpload__browseText">Select additional photo(s)</p>
          ) : null}
        </div>
      ) : null}
      <DropzoneS3Uploader
        className={classNames(
          diabled
            ? 'tmx-fileUpload__dropzoneBlock'
            : isThumbnail
            ? 'tmx-fileUpload__dropzoneBlock'
            : uploadList.length === 0 ||
              (fileType === 'video/*' && uploadList.length <= 0) ||
              (fileType === 'image/*' && uploadList.length <= 59)
            ? 'tmx-fileUpload__dropzone'
            : 'tmx-fileUpload__dropzoneBlock'
        )}
        accept={fileType}
        s3Url=""
        multiple={false}
        upload={{
          getSignedUrl: page === 'ExpertProfileDetails' ? getAccessSignedUrl : getSignedUrl,
          signingUrlWithCredentials: true,
          uploadRequestHeaders: {
            'x-amz-acl': 'private'
          },
          preprocess: handlePreProcess,
          contentDisposition: 'auto'
        }}
        style={{ position: 'absolute' }}
        onError={handleUploadError}
        onProgress={handleProgress}
        onFinish={handleFinish}>
        <></>
      </DropzoneS3Uploader>
    </div>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  isThumbnail: PropTypes.bool,
  thumbNailList: PropTypes.any,
  setThumbNailList: PropTypes.any,
  view: PropTypes.string,
  isVideo: PropTypes.bool,
  isDownloadableContent: PropTypes.bool,
  setIsThumbnail: PropTypes.func,
  isUploadingImage: PropTypes.bool,
  diabled: PropTypes.bool,
  isProfile: PropTypes.bool,
  page: PropTypes.string,
  profilePhotoVideoPresent: PropTypes.bool
};

export default FileUpload;

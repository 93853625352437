import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import './ExpertList.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';

import useDetectDevice from '../../functionality/Hooks/detectDevice';

import { expertListAPI } from '../../api/actions/tools-actions';
import { EMAIL_REGEX } from '../../constants/regex-validations';

const ExpertList = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [expertName, setExpertName] = useState('');
  const [repName, setRepName] = useState('');
  const [expertEmail, setExpertEmail] = useState([]);
  const [showEditFields, setShowEditFields] = useState(false);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const [editedItem, setEditedItem] = useState({
    client_name: '',
    rep_name: '',
    contact_emails: ''
  });
  const [editedItemModified, setEditedItemModified] = useState({
    client_name: false,
    rep_name: false,
    contact_emails: false
  });

  const { expertList, expertListLoading } = useSelector((state) => state.toolsReducer);

  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useDetectDevice(430);

  useEffect(() => {
    dispatch(expertListAPI({}));
  }, [dispatch]);

  const setError = (error) => {
    setSuccess(false);
    setLoading(false);
    // const parsedError = JSON.parse(error);
    setErrorMsg(error);
  };

  const setSuccessState = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  const handleSuccess = () => {
    setSuccessState();
    setExpertName('');
    setRepName('');
    setExpertEmail([]);
    setLoading(false);
    setErrorMsg('');
  };

  const handleEditInputChange = (key, value) => {
    setEditedItem({
      ...editedItem,
      [key]: value
    });
    setEditedItemModified({
      ...editedItemModified,
      [key]: true
    });
  };

  function validateEmails(emails) {
    const emailArray = emails.split(', ');

    for (const email of emailArray) {
      if (!EMAIL_REGEX.test(email)) {
        return false;
      }
    }
    return true;
  }

  function onSubmit(e) {
    e.preventDefault();

    if (!expertEmail.length) {
      setError('Please add at least one email address!');
      setLoading(false);
      return;
    }

    if (!validateEmails(expertEmail)) {
      setError('Please check the email format and try again!');
      setLoading(false);
      return;
    }

    setLoading(true);

    dispatch(
      expertListAPI(
        {
          add: {
            client_name: expertName,
            rep_name: repName,
            contact_emails: expertEmail.split(', ')
          }
        },
        handleSuccess
      )
    );
  }

  return (
    <div className="tmx-ExpertList">
      <div className="tmx-ExpertList__container">
        <Button
          buttonIcon="arrow-right"
          buttonIconClassName="tmx-ExpertList__backButton"
          iconSize="ginormous"
          className="tmx-ExpertList__backContent black"
          handleClick={() => history.push('/tools')}>
          {isMobile ? '' : 'Back to Tools'}
        </Button>
        <h1>Expert Request Recipients</h1>
        {errorMsg.length ? <p className="tmx-uploadOrg__duplicateOrg">{errorMsg}</p> : null}
        {success ? <p className="tmx-uploadOrg__OrgAdded">Client Info added!</p> : null}
        {/* <div className="tmx-uploadOrg__SelectOrg"> */}
        <form className="tmx-ExpertList__inputForm" onSubmit={onSubmit}>
          <div className="tmx-ExpertList__input">
            <Input
              id="expertName"
              name="expertName"
              className="expertNameInput"
              type={'text'}
              darkBorder
              label="Name of Client"
              labelClassName={'ExpertName'}
              onChange={(event) => setExpertName(event.target.value)}
              placeholder="First Last"
              value={showEditFields ? '' : expertName}></Input>
          </div>
          <div className="tmx-ExpertList__input">
            <Input
              id="repName"
              name="repName"
              className="expertNameInput"
              type={'text'}
              darkBorder
              label="Name of Rep"
              labelClassName={'ExpertName'}
              onChange={(event) => setRepName(event.target.value)}
              placeholder="First Last"
              value={showEditFields ? '' : repName}></Input>
          </div>
          <div className="tmx-ExpertList__input">
            <Input
              id="expertEmail"
              name="expertEmail"
              className={classNames('expertEmailInput', { error: errorMsg.length })}
              type={'text'}
              darkBorder
              label="Contact Email(s) *"
              labelClassName={'ExpertName'}
              onChange={(event) => setExpertEmail(event.target.value)}
              placeholder="Email Address(es) separate by comma"
              value={showEditFields ? '' : expertEmail}></Input>
          </div>
          <div className="tmx-ExpertList__updateContentBtnContainer">
            <Button
              className="tmx-ExpertList__updateContentBtn"
              buttonIcon={'plus-circled'}
              iconSize="huge"
              type={'submit'}
              spinner={loading}
              disabled={expertListLoading}>
              Save
            </Button>
          </div>
        </form>
        <div className="tmx-ExpertList__mediaContainer headers">
          <div className="tmx-ExpertList__mediaContainerName header">Name of Client </div>
          {/* <div className="tmx-ExpertList__mediaSubContainer"> */}
          <div className="tmx-ExpertList__mediaContainerTitle header">Name of Rep </div>
          <div className="tmx-ExpertList__mediaContainerCategory header">
            Contact emails (Client or rep){' '}
          </div>
          <div className="tmx-ExpertList__mediaContainerTitle header">Actions </div>
          {/* </div> */}
        </div>
        {expertListLoading && (
          <Button className="tmx-ExpertList__loadingBtn" spinner={true} iconSize={'big'} />
        )}
        <div className="tmx-ExpertList__media">
          {expertList?.map((expertItem, key) => (
            <div
              className={classNames('tmx-ExpertList__mediaContainer', {
                // 'tmx-ExpertList__mediaContainer--loading': expertListLoading
              })}
              key={key}>
              {
                <>
                  <div className="tmx-ExpertList__mediaSubContainer">
                    <>
                      {showEditFields && editedItemIndex === key ? (
                        <div className="tmx-ExpertList__editInput">
                          <Input
                            id="expertName"
                            name="expertName"
                            className="editNameInput"
                            darkBorder
                            type={'text'}
                            // label="Name of Client"
                            labelClassName={'ExpertName'}
                            onChange={(event) =>
                              handleEditInputChange('client_name', event.target.value)
                            }
                            placeholder="First Last"
                            value={
                              editedItemModified.client_name
                                ? editedItem.client_name
                                : expertItem.client_name
                            }></Input>
                        </div>
                      ) : (
                        <div className="tmx-ExpertList__mediaContainerTitle">
                          {expertItem.client_name}
                        </div>
                      )}
                      {showEditFields && editedItemIndex === key ? (
                        <div className="tmx-ExpertList__editInput">
                          <Input
                            id="repName"
                            name="repName"
                            className="editNameInput"
                            type={'text'}
                            darkBorder
                            // label="Name of Rep"
                            labelClassName={'ExpertName'}
                            onChange={(event) =>
                              handleEditInputChange('rep_name', event.target.value)
                            }
                            placeholder="First Last"
                            value={
                              editedItemModified.rep_name
                                ? editedItem.rep_name
                                : expertItem.rep_name
                            }></Input>
                        </div>
                      ) : (
                        <div className="tmx-ExpertList__mediaContainerCategory">
                          {expertItem.rep_name}
                        </div>
                      )}
                      {showEditFields && editedItemIndex === key ? (
                        <div className="tmx-ExpertList__editInput">
                          <Input
                            id="expertEmail"
                            name="expertEmail"
                            className="editEmailInput"
                            type={'text'}
                            darkBorder
                            // label="Contact Email(s) *"
                            labelClassName={'ExpertName'}
                            onChange={(event) =>
                              handleEditInputChange('contact_emails', event.target.value)
                            }
                            placeholder="Email Address(es) separate by comma"
                            value={
                              editedItemModified.contact_emails
                                ? editedItem.contact_emails
                                : expertItem.contact_emails.join(', ')
                            }></Input>
                        </div>
                      ) : (
                        <div className="tmx-ExpertList__mediaContainerCategory">
                          {expertItem.contact_emails.join(', ')}
                        </div>
                      )}
                    </>

                    {/* {!showEditFields && ( */}
                    <div className="tmx-ExpertList__mediaContainerAction">
                      {/* <div className="tmx-ExpertList__sortButtonsContainer">Expert Name</div> */}
                      <Button
                        type="button"
                        className={`tmx-ExpertList__deleteBrick edit`}
                        handleClick={() => {
                          if (!showEditFields) {
                            setShowEditFields(true);
                            setEditedItemIndex(key);
                          } else {
                            dispatch(
                              expertListAPI({
                                edit: {
                                  client_id: expertItem.client_id,
                                  rep_name:
                                    editedItem.rep_name === '' && !editedItemModified.rep_name
                                      ? expertItem.rep_name
                                      : editedItem.rep_name,
                                  client_name:
                                    editedItem.client_name === '' && !editedItemModified.client_name
                                      ? expertItem.client_name
                                      : editedItem.client_name,
                                  contact_emails:
                                    editedItem.contact_emails === '' &&
                                    !editedItemModified.contact_emails
                                      ? expertItem.contact_emails
                                      : editedItem.contact_emails.split(', ')
                                }
                              })
                            );
                            setShowEditFields(false);
                            setRepName('');
                            setExpertEmail([]);
                            setExpertName('');
                            setEditedItem({
                              client_name: '',
                              rep_name: '',
                              contact_emails: ''
                            });
                            setEditedItemModified({
                              client_name: false,
                              rep_name: false,
                              contact_emails: false
                            });
                          }
                          setErrorMsg('');
                        }}
                        disabled={expertListLoading}>
                        {showEditFields && editedItemIndex === key ? 'Save' : 'Edit'}
                      </Button>
                      <Button
                        type="button"
                        className={`tmx-ExpertList__deleteBrick`}
                        handleClick={() => {
                          if (!showEditFields) {
                            dispatch(
                              expertListAPI({
                                delete: { client_id: expertItem.client_id }
                              })
                            );
                          } else {
                            setShowEditFields(false);
                            setExpertEmail([]);
                            setExpertName('');
                            setRepName('');
                          }
                        }}
                        disabled={expertListLoading}>
                        {showEditFields ? 'Cancel' : 'Delete'}
                      </Button>
                    </div>
                    {/* )} */}
                  </div>
                </>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpertList;

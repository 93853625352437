import React, { useState, createContext, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import './UploadContainer.scss';

import { ReactComponent as Preview } from '../../images/preview.svg';
import { ReactComponent as ThumbnailMobile } from '../../images/thumbnail_icon_mobile.svg';

import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';

import DropDown from '../../components/atoms/DropDown/DropDown';
import TextArea from '../../components/atoms/TextArea/TextArea';
import UploadForm from '../../components/molecules/UploadForm/UploadForm';
import FileUpload from '../../components/atoms/FileUpload/FileUpload';
import { callAjaxWCognito } from '../../functionality/CognitoTMX';
import UploadTopContent from '../../components/molecules/UploadTopContent/UploadTopContent';
import Button from '../../components/atoms/Button/Button';
import UploadSuccess from '../../components/molecules/UploadSuccess/UploadSuccess';

import { UPLOAD_GET_COMPANIES, SEARCH_URL } from '../../functionality/networking';
import useDetectDevice from '../../functionality/Hooks/detectDevice';
import { searchContent } from '../../api/actions/universal-uploader-actions';

export const UserContext = createContext();

const UploadContainer = () => {
  const isMobile = useDetectDevice(500);
  const isTablet = useDetectDevice(748);
  const route = useRouteMatch();
  const media_id = route.params.id;
  const dispatch = useDispatch();
  const { uploadSuccess, loadingDetails, contentDetails } = useSelector(
    (state) => state.universalUploader
  );

  const LOCATION_DATA_RETRIEVING = 0;
  const LOCATION_DATA_RECEIVED = 1;
  const LOCATION_DATA_NOT_RECEIVED = 2;

  const SENDER_ORG_DATA_RETRIEVING = 0;
  const SENDER_ORG_DATA_RECEIVED = 1;
  const SENDER_ORG_DATA_NOT_RECEIVED = 2;

  const [showThumbNail, setShowThumbNail] = useState(false);
  const [showDateAndTime, setShowDateAndTime] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [dataState, setDataState] = useState(LOCATION_DATA_RETRIEVING); // 0 - get location data, 1 - received, 2 - not received
  const [senderOrgDataState, setSenderOrgDataState] = useState(SENDER_ORG_DATA_RETRIEVING); // 0 - get senderOrg data, 1 - received, 2 - not received
  const [senderOrgData, setSenderOrgData] = useState([]);
  const [view, setView] = useState('content');
  const [title, setTitle] = useState(contentDetails['m.title'] || '');
  const [shortTitle, setShortTitle] = useState('');
  const [isGraphicContent, setIsGraphicContent] = useState(false);
  const [graphicType, setGraphicType] = useState('Language');
  const [allowDownload, setAllowDownload] = useState(true);
  const [senderOrg, setSenderOrg] = useState('TMX');
  const [waterMark, setWaterMark] = useState(false);
  const [credit, setCredit] = useState('');
  const [hideTMX, setHideTMX] = useState(false);
  const [creatorDescription, setCreatorDescription] = useState('');
  const [postURL, setPostURL] = useState(['']);
  const [summary, setSummary] = useState('');
  const [blockList, setBlockList] = useState('');
  const [tags, setTags] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [alertCategory, setAlertCategory] = useState('none');
  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [date, setDate] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [thumbNailList, setThumbNailList] = useState([]);
  const [isPhotos, setIsPhoto] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [orgsData, setOrgsData] = useState([]);
  const [boostedOrgs, setBoostedOrgs] = useState([]);
  const [isUgc, setIsUgc] = useState(false);
  const [contactFields, setContactFields] = useState([
    {
      contactName: '',
      contactTitle: '',
      contactDetails: [{ contactFieldTypes: 'Email', contactFieldData: '' }]
    }
  ]);
  const [isDownloadableContent, setIsDownloadableContent] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(true);
  const [isInboxContentDownlodable, setIsInboxContentDownlodable] = useState(false);
  const [isTmxExpert, setIsTmxExpert] = useState(false);
  const [doNotPlaceOntop, setDoNotPlaceOntop] = useState(false);
  const [inboxType, setInboxType] = useState('NEWSROOM');
  const [hidePost, setHidePost] = useState(false);
  const vals = {
    alertCategory,
    allowDownload,
    blockList,
    boostedOrgs,
    contactFields,
    creatorDescription,
    credit,
    date,
    doNotPlaceOntop,
    files,
    graphicType,
    hideTMX,
    inboxType,
    isGraphicContent,
    isInboxContentDownlodable,
    isPhotos,
    isTmxExpert,
    isUgc,
    isUploadingImage,
    isVideo,
    locationData,
    locations,
    orgsData,
    postURL,
    scheduled,
    senderOrg,
    senderOrgData,
    shortTitle,
    showContact,
    showDateAndTime,
    summary,
    tags,
    thumbnail,
    title,
    view,
    waterMark,
    hidePost
  };

  // const sOrg = senderOrgData?.uploaders?.sort((a, b) => a.localeCompare(b)).map((x) => x);

  const setMediaData = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.success) {
      setLocationData([...parsedResponse.locations_all.map((x) => x.city + ', ' + x.state)]);
      setOrgsData(parsedResponse.companies);
      setDataState(LOCATION_DATA_RECEIVED);
    } else {
      setDataState(LOCATION_DATA_NOT_RECEIVED);
    }
  };

  const getlocationData = useCallback(() => {
    if (dataState === LOCATION_DATA_RETRIEVING) {
      callAjaxWCognito(
        UPLOAD_GET_COMPANIES,
        setMediaData,
        () => {
          setDataState(LOCATION_DATA_NOT_RECEIVED);
        },
        JSON.stringify({})
      );
    }
  }, [dataState]);

  const assignSenderOrgData = (response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.uploaders) {
      setSenderOrgData(parsedResponse);
      setSenderOrgDataState(SENDER_ORG_DATA_RECEIVED);
    } else {
      setSenderOrgDataState(SENDER_ORG_DATA_NOT_RECEIVED);
    }
  };

  const getOriginalOrgData = useCallback(() => {
    if (senderOrgDataState === SENDER_ORG_DATA_RETRIEVING) {
      callAjaxWCognito(
        SEARCH_URL,
        assignSenderOrgData,
        () => {
          setSenderOrgDataState(SENDER_ORG_DATA_NOT_RECEIVED);
        },
        JSON.stringify({ get_original_uploaders: true })
      );
    }
  }, [senderOrgDataState]);

  useEffect(() => {
    getlocationData();
  }, [getlocationData]);

  useEffect(() => {
    getOriginalOrgData();
  }, [getOriginalOrgData]);

  useEffect(() => {
    if (media_id) {
      dispatch(searchContent(media_id));
    }
  }, [dispatch, media_id]);

  useEffect(() => {
    if (contentDetails['m.title']) {
      setTitle(contentDetails['m.title'] || '');
      setShortTitle(contentDetails['m.short_title'] || '');
      let creditSplit = contentDetails?.courtesy?.split('/');
      setCredit(creditSplit?.slice(0, creditSplit.length - 1).join('/') || '');
      setAlertCategory(contentDetails.alert_category || '');
      setSummary(contentDetails['d.description'] || '');
      setPostURL(contentDetails.link_to_article || []);
      setBlockList(contentDetails['m.source_orgs'][0] || '');
      setLocations(contentDetails.location || []);
      setTags(contentDetails['t.tags'] || []);
      setSenderOrg(contentDetails['m.original_uploader_org'] || '');
      setDate(contentDetails['m.upload_time'] || '');
      setGraphicType(contentDetails['m.content_warning_type'] ? 'Content' : 'Language');
      setWaterMark(contentDetails['m.watermark'] || false);
      setAllowDownload(contentDetails['m.allow_download'] === 'ALLOWED' ? true : false);
      setIsGraphicContent(contentDetails['m.content_warning'] || false);
      setContactFields(contentDetails?.contacts);
      setCreatorDescription(contentDetails?.links[0]?.content || '');
      setIsPhoto(contentDetails['m.photos'] || false);
      setIsUgc(contentDetails['m.ugc'] || false);
      setBoostedOrgs(
        contentDetails.company_name
          ? [contentDetails.company_name]
          : contentDetails?.boosted_orgs
          ? contentDetails?.boosted_orgs
          : []
      );
      setView(
        contentDetails['m.content_type'] === 'CLEAREDARTICLES'
          ? 'article'
          : contentDetails['m.content_type'] === 'INBOX'
          ? 'INBOX'
          : contentDetails?.boosted_orgs && contentDetails?.boosted_orgs.length
          ? 'boost'
          : contentDetails.is_contact
          ? 'contact'
          : contentDetails['m.is_pitches']
          ? 'pitches'
          : 'content'
      );
      setIsUploadingImage(contentDetails['m.photos'] || false);
      setIsInboxContentDownlodable(contentDetails['m.is_downloadable_content'] || false);
      setIsTmxExpert(contentDetails['m.tmx_expert'] || false);
      setInboxType(contentDetails['m.inbox_type'] || 'NEWSROOM');
      setHidePost(contentDetails['m.hide_post']);
    }
  }, [contentDetails]);

  const handleAddNewContact = () => {
    setContactFields([
      ...contactFields,
      {
        contactName: '',
        contactTitle: '',
        contactDetails: [{ contactFieldTypes: '', contactFieldData: '' }]
      }
    ]);
  };

  const handleAddNewField = (index1) => {
    const values = [...contactFields];
    values[index1].contactDetails.push({
      contactFieldTypes: '',
      contactFieldData: ''
    });
    setContactFields(values);
  };

  const handleRemoveContact = (index1) => {
    const values = [...contactFields];
    values.splice(index1, 1);
    setContactFields(values);
  };

  const handleRemoveField = (index1, index2) => {
    const values = [...contactFields];
    values[index1].contactDetails.splice(index2, 1);
    setContactFields(values);
  };

  const handleBasicContactChange = (index1, event) => {
    const values = [...contactFields];
    values[index1][event.target.name] = event.target.value;
    setContactFields(values);
  };

  const handleContactDetailChange = (index1, index2, event, field) => {
    const values = [...contactFields];
    values[index1].contactDetails[index2][field] = event.target.value;
    setContactFields(values);
  };

  const handleURLChange = (index, e) => {
    const copy = [...postURL];
    copy[index] = e.target.value;
    setPostURL(copy);
  };

  const addNewURLField = () => {
    const copy = [...postURL];
    copy.push('');
    setPostURL(copy);
  };

  const removeURLField = (index) => {
    const copy = [...postURL];
    copy.splice(index, 1);
    setPostURL(copy);
  };

  const formatedLink = thumbNailList.length && thumbNailList[0].link.replace(/['"]+/g, '');

  return (
    <UserContext.Provider value={vals}>
      <div className="tmx-uploadFormContainer">
        {loadingDetails && route.params.id ? (
          <Button spinner={true} iconSize="big" />
        ) : (
          <div className="tmx-uploadFormContainer__uploadcontainer">
            {uploadSuccess ? (
              <UploadSuccess mediaId={media_id} date={vals.date} scheduled={scheduled} />
            ) : (
              <>
                <UploadTopContent
                  setView={setView}
                  view={view}
                  setIsThumbnail={setIsThumbnail}
                  setAlertCategory={setAlertCategory}
                  setIsDownloadableContent={setIsDownloadableContent}
                  setThumbnail={setThumbnail}
                  setThumbNailList={setThumbNailList}
                  setIsPhoto={setIsPhoto}
                  setIsVideo={setIsVideo}
                  setSenderOrg={setSenderOrg}
                  setAllowDownload={setAllowDownload}
                />
                <div className="tmx-uploadFormContainer__uploadcontainer__wrapper">
                  {/* THUMBNAIL PREVIEW */}
                  {showThumbNail && (thumbNailList.length || media_id) ? (
                    <div className="tmx-uploadFormContainer__previewThumbnail">
                      <div className="tmx-uploadFormContainer__previewThumbnail__heading">
                        <Button
                          className="tmx-uploadFormContainer__previewThumbnail__heading__close"
                          buttonIcon="close"
                          handleClick={() => setShowThumbNail(false)}
                          iconSize="small"
                        />
                      </div>
                      <div className="tmx-uploadFormContainer__previewThumbnail__content">
                        <img src={formatedLink || contentDetails['m.thumbnail']} alt="preview" />
                      </div>
                      <div className="tmx-uploadFormContainer__previewThumbnail__footer">
                        <div className="tmx-uploadFormContainer__previewThumbnail__footer__one"></div>
                        <div className="tmx-uploadFormContainer__previewThumbnail__footer__two"></div>
                        <div className="tmx-uploadFormContainer__previewThumbnail__footer__three"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {isMobile ? (
                        <Button
                          className={
                            thumbNailList.length || media_id
                              ? 'preview small-thumbnail'
                              : 'preview small-thumbnail preview-disabled'
                          }
                          handleClick={() => setShowThumbNail(true)}
                          disabled={!thumbNailList.length && !media_id}>
                          <ThumbnailMobile />
                        </Button>
                      ) : (
                        <Button
                          className={
                            thumbNailList.length || media_id
                              ? 'preview big-thumbnail'
                              : 'preview big-thumbnail preview-disabled'
                          }
                          handleClick={() => setShowThumbNail(true)}
                          disabled={!thumbNailList.length && !media_id}>
                          <Preview />
                          Preview thumbnail
                        </Button>
                      )}
                    </>
                  )}

                  <div className="tmx-uploadForm">
                    <div className="tmx-uploadForm__formControl">
                      {view === 'INBOX' ? (
                        <div className="notify">
                          <div className="tmx-uploadForm__label">Inbox Type</div>
                          <DropDown
                            onChange={(event) => {
                              setInboxType(
                                event.target.value === 'Newsroom (My TMX Inbox)'
                                  ? 'NEWSROOM'
                                  : event.target.value === 'Access (Messages from Lynn)'
                                  ? 'ACCESS'
                                  : ''
                              );
                            }}
                            optionSelected={
                              vals.inboxType === 'NEWSROOM'
                                ? 'Newsroom (My TMX Inbox)'
                                : vals.alertCategory === 'Access (Messages from Lynn)'
                            }
                            className="tmx-uploadForm__userDropDown grey"
                            data={['Newsroom (My TMX Inbox)', 'Access (Messages from Lynn)']}
                            setOptionsToUpperCase
                          />
                        </div>
                      ) : null}
                      {view === 'content' && (
                        <h2 className="tmx-uploadFormContainer__sectionTitle">
                          About {isUploadingImage ? 'these photos' : 'this video'}
                        </h2>
                      )}
                      <div className="title-field field">
                        <TextArea
                          id="title"
                          field="title"
                          label={'Title'}
                          htmlFor={'title'}
                          maxRows={3}
                          onChange={(event) => {
                            setTitle(event.target.value);
                          }}
                          darkBorder
                          placeholder={
                            view === 'content'
                              ? 'Title for content thumbnail and email alert'
                              : 'Enter a title for this media'
                          }
                          value={title}
                        />
                      </div>

                      {view === 'article' ? (
                        <div className="title-field field">
                          <TextArea
                            id="shortTitle"
                            field="shortTitle"
                            label={'Shortened title'}
                            htmlFor={'shortTitle'}
                            maxRows={3}
                            onChange={(event) => {
                              setShortTitle(event.target.value);
                            }}
                            darkBorder
                            placeholder={'Shortened title for available experts'}
                            value={shortTitle}
                            maxCharacters={32}
                            statusBar
                          />
                        </div>
                      ) : null}

                      {view === 'article' ? (
                        <div className="cleared-article-radio">
                          <Grid container xs={12} spacing={3}>
                            <Grid xs={12}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row={isTablet ? false : true}
                                  aria-label="gender"
                                  name="row-radio-buttons-group"
                                  value={isDownloadableContent}
                                  onChange={(e) => {
                                    setThumbnail([]);
                                    setIsThumbnail(false);
                                    setThumbNailList([]);
                                    if (e.target.value === 'false') {
                                      setIsDownloadableContent(false);
                                      setCredit('');
                                    } else if (e.target.value === 'true') {
                                      setIsDownloadableContent(true);
                                      setCredit('TMX');
                                    }
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Upload content to download"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No downloadable content"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}

                      {view === 'INBOX' || view === 'content' ? (
                        <div className="cleared-content-radio">
                          <Grid container xs={12} spacing={3}>
                            <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name="row-radio-buttons-group"
                                  value={isUploadingImage}
                                  onChange={(e) => {
                                    setThumbnail([]);
                                    setIsThumbnail(false);
                                    setThumbNailList([]);
                                    setIsVideo(false);
                                    if (e.target.value === 'false') {
                                      setIsUploadingImage(false);
                                    } else if (e.target.value === 'true') {
                                      setIsUploadingImage(true);
                                    }
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Pictures"
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Video"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}

                      {view === 'content' && !isUploadingImage ? (
                        <>
                          <div>
                            <FileUpload
                              setThumbNailList={setThumbNailList}
                              thumbNailList={thumbNailList}
                              view={view}
                              isUploadingImage={isUploadingImage}
                              onChange={(uploads) => {
                                setFiles([...uploads]);
                                if (!uploads.length) {
                                  setIsVideo(false);
                                  setIsThumbnail(false);
                                  setIsPhoto(true);
                                } else if (uploads[0].type === 'video') {
                                  setIsVideo(true);
                                  setIsPhoto(false);
                                } else {
                                  setIsVideo(false);
                                  setIsPhoto(true);
                                }
                              }}
                            />
                            {isVideo && view === 'content' && !isUploadingImage ? (
                              <FileUpload
                                setIsThumbnail={setIsThumbnail}
                                setThumbNailList={setThumbNailList}
                                thumbNailList={thumbNailList}
                                isVideo={isVideo}
                                view={view}
                                isThumbnail={isThumbnail}
                                onChange={(uploads) => {
                                  setIsThumbnail(!isThumbnail);
                                  setThumbnail([...uploads]);
                                }}
                              />
                            ) : null}
                          </div>
                        </>
                      ) : null}

                      {view === 'INBOX' && !isUploadingImage ? (
                        <>
                          <div>
                            <FileUpload
                              setThumbNailList={setThumbNailList}
                              thumbNailList={thumbNailList}
                              view={view}
                              isUploadingImage={isUploadingImage}
                              onChange={(uploads) => {
                                setFiles([...uploads]);
                                if (!uploads.length) {
                                  setIsVideo(false);
                                  setIsThumbnail(false);
                                  setIsPhoto(true);
                                } else if (uploads[0].type === 'video') {
                                  setIsVideo(true);
                                  setIsPhoto(false);
                                } else {
                                  setIsVideo(false);
                                  setIsPhoto(true);
                                }
                              }}
                            />
                            {isVideo && view === 'INBOX' && !isUploadingImage ? (
                              <FileUpload
                                setIsThumbnail={setIsThumbnail}
                                setThumbNailList={setThumbNailList}
                                thumbNailList={thumbNailList}
                                isVideo={isVideo}
                                view={view}
                                isThumbnail={isThumbnail}
                                onChange={(uploads) => {
                                  setIsThumbnail(!isThumbnail);
                                  setThumbnail([...uploads]);
                                }}
                              />
                            ) : null}
                          </div>
                        </>
                      ) : null}

                      {view === 'article' && isDownloadableContent ? (
                        <FileUpload
                          isDownloadableContent={isDownloadableContent}
                          isUploadingImage={isUploadingImage}
                          setIsThumbnail={setIsThumbnail}
                          setThumbNailList={setThumbNailList}
                          thumbNailList={thumbNailList}
                          view={view}
                          isThumbnail={isThumbnail}
                          onChange={(uploads) => {
                            if (view !== 'article') {
                              setIsThumbnail(!isThumbnail);
                            }
                            setThumbnail([...uploads]);
                          }}
                        />
                      ) : null}

                      {view === 'INBOX' && isUploadingImage ? (
                        <FileUpload
                          isDownloadableContent={isDownloadableContent}
                          isUploadingImage={isUploadingImage}
                          setIsThumbnail={setIsThumbnail}
                          setThumbNailList={setThumbNailList}
                          thumbNailList={thumbNailList}
                          view={view}
                          isThumbnail={isThumbnail}
                          onChange={(uploads) => {
                            setIsThumbnail(false);
                            setThumbnail([...uploads]);
                            setIsPhoto(true);
                          }}
                        />
                      ) : null}
                      {view === 'content' && isUploadingImage ? (
                        <FileUpload
                          isDownloadableContent={isDownloadableContent}
                          isUploadingImage={isUploadingImage}
                          setIsThumbnail={setIsThumbnail}
                          setThumbNailList={setThumbNailList}
                          thumbNailList={thumbNailList}
                          view={view}
                          isThumbnail={isThumbnail}
                          onChange={(uploads) => {
                            setIsThumbnail(false);
                            setThumbnail([...uploads]);
                            setIsPhoto(true);
                          }}
                        />
                      ) : null}
                      {view === 'contact' ? (
                        <FileUpload
                          isDownloadableContent={isDownloadableContent}
                          setIsThumbnail={setIsThumbnail}
                          setThumbNailList={setThumbNailList}
                          thumbNailList={thumbNailList}
                          view={view}
                          isThumbnail={isThumbnail}
                          onChange={(uploads) => {
                            if (view !== 'article') {
                              setIsThumbnail(!isThumbnail);
                            }
                            setThumbnail([...uploads]);
                          }}
                        />
                      ) : null}
                      {view === 'article' && !isDownloadableContent ? (
                        <FileUpload
                          isDownloadableContent={isDownloadableContent}
                          setIsThumbnail={setIsThumbnail}
                          setThumbNailList={setThumbNailList}
                          thumbNailList={thumbNailList}
                          view={view}
                          isThumbnail={isThumbnail}
                          onChange={(uploads) => {
                            setIsThumbnail(!isThumbnail);
                            setThumbnail([...uploads]);
                          }}
                        />
                      ) : null}
                      <UploadForm
                        setIsGraphicContent={setIsGraphicContent}
                        setGraphicType={setGraphicType}
                        setSenderOrg={setSenderOrg}
                        setBoostedOrgs={setBoostedOrgs}
                        setWaterMark={setWaterMark}
                        setCredit={setCredit}
                        setAllowDownload={setAllowDownload}
                        setHideTMX={setHideTMX}
                        setCreatorDescription={setCreatorDescription}
                        setPostURL={setPostURL}
                        setSummary={setSummary}
                        setBlockList={setBlockList}
                        setContactFields={setContactFields}
                        handleAddNewContact={handleAddNewContact}
                        handleBasicContactChange={handleBasicContactChange}
                        handleAddNewField={handleAddNewField}
                        handleRemoveContact={handleRemoveContact}
                        handleContactDetailChange={handleContactDetailChange}
                        handleRemoveField={handleRemoveField}
                        setLocations={setLocations}
                        setTags={setTags}
                        setAlertCategory={setAlertCategory}
                        setDate={setDate}
                        thumbNailList={thumbNailList}
                        setShowContact={setShowContact}
                        handleURLChange={handleURLChange}
                        addNewURLField={addNewURLField}
                        removeURLField={removeURLField}
                        date={date}
                        setShowDateAndTime={setShowDateAndTime}
                        setScheduled={setScheduled}
                        setIsUgc={setIsUgc}
                        isDownloadableContent={isDownloadableContent}
                        setIsInboxContentDownlodable={setIsInboxContentDownlodable}
                        setIsTmxExpert={setIsTmxExpert}
                        setDoNotPlaceOntop={setDoNotPlaceOntop}
                        allowDownload={allowDownload}
                        hidePost={hidePost}
                        setHidePost={setHidePost}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </UserContext.Provider>
  );
};

export default UploadContainer;

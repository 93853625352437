import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './HomePage.scss';

import Icon from '../../components/atoms/Icon/Icon';
import SignInForm from '../../components/molecules/SignInForm/SignInForm';

import { signInIfNeeded } from '../../api/actions/signIn-actions';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, loggedIn } = useSelector((state) => state.signInReducer);

  const loginCallback = (username, password) => {
    dispatch(signInIfNeeded(username.toLowerCase(), password));
  };

  useEffect(() => {
    if (loggedIn) {
      history.push('/organization-settings');
    }
  }, [loggedIn, history]);

  return (
    <section className="tmx-homePage">
      <div className="tmx-homePage__logoWrapper">
        <Icon name="logo-big" size="large" className="tmx-homePage__logo" />
      </div>
      <div className="tmx-homePage__signinWrapper">
        <SignInForm handleLogin={loginCallback} loading={loading} />
      </div>
    </section>
  );
};

export default HomePage;

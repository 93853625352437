import {
  RECEIVE_LOGIN,
  REQUEST_LOGIN,
  LOG_OUT,
  USER_LOADING,
  RESET_SIGN_IN_ERROR,
  SENDING_INVITATIONS,
  INVITES_SENT_SUCCESS,
  INVITES_SENT_ERROR,
  NUMBER_OF_INVITES,
  RESET_INVITES_SENT_SUCCESS,
  RECEIVE_LOGIN_ERROR
} from '../../constants/action-types';
import { refreshUserAttributes } from '../../functionality/CognitoTMX';

function getWithExpiry(asBoolean) {
  const userData = localStorage.getItem('userData');
  if (!userData && !asBoolean) {
    return null;
  } else if (!userData && asBoolean) {
    return false;
  }
  const userRelatedData = JSON.parse(userData);
  const currentDate = new Date();
  if (currentDate.getTime() > userRelatedData.expiry && !asBoolean) {
    localStorage.removeItem('userData');
    return null;
  } else if (currentDate.getTime() > userRelatedData.expiry && asBoolean) {
    return false;
  }
  if (!asBoolean) {
    refreshUserAttributes();
    return userRelatedData.value;
  } else {
    return true;
  }
}

const initialState = {
  loggedIn: getWithExpiry(true),
  loading: false,
  sendingInviteLoading: false,
  invitesSentSuccess: false,
  invitesSentError: false,
  numberOfInvites: 0,
  error: false,
  userData: getWithExpiry(false) ? JSON.parse(localStorage.getItem('userData')).value : null,
  sigInLoading: false,
  userLoading: false
};

export default function signInReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return { ...initialState, loading: true, sigInLoading: true };
    case RECEIVE_LOGIN:
      return {
        ...initialState,
        loggedIn: true,
        userData: action.userData,
        sigInLoading: false,
        userLoading: false
      };
    case LOG_OUT:
      return { ...initialState, loggedIn: false, userData: null };
    case USER_LOADING:
      return { ...state, userLoading: true };
    case RECEIVE_LOGIN_ERROR:
      return { ...state, error: true, loading: false, sigInLoading: false };
    case RESET_SIGN_IN_ERROR:
      return { ...state, error: false };
    case SENDING_INVITATIONS:
      return { ...state, sendingInviteLoading: true };
    case INVITES_SENT_SUCCESS:
      return {
        ...state,
        invitesSentSuccess: true,
        sendingInviteLoading: false,
        invitesSentError: false
      };
    case INVITES_SENT_ERROR:
      return {
        ...state,
        invitesSentSuccess: false,
        sendingInviteLoading: false,
        invitesSentError: true
      };
    case NUMBER_OF_INVITES:
      return {
        ...state,
        numberOfInvites: action.numberOfInvites
      };

    case RESET_INVITES_SENT_SUCCESS:
      return {
        ...state,
        invitesSentSuccess: false,
        invitesSentError: false
      };
    default:
      return state;
  }
}

import { combineReducers } from 'redux';
import signInReducer from './signIn-reducer';
import orgReducer from './org-reducer';
import sellersReducer from './sellers-reducer';
import recommendReducer from './recommend-reducer';
import toolsReducer from './tools-reducer'
import universalUploader from './universal-uploader-reducer';
export default combineReducers({
  signInReducer,
  orgReducer,
  sellersReducer,
  recommendReducer,
  toolsReducer,
  universalUploader
});

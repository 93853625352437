import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Modal.scss';

import Icon from '../Icon/Icon';
import Button from '../../atoms/Button/Button';

const Modal = ({
  className,
  contentClassName,
  children,
  size,
  header,
  close,
  closeIcon,
  contentRef,
  title,
  customModalWrapper,
  backButton,
  onBack,
  logo
}) => {
  const modalContentRef = useRef(null);
  const wrapperRef = useRef(null);
  const containerClasses = classNames(contentClassName, {
    'tmx-modal__content': size === 'small',
    'tmx-modal__content--large': size !== 'small',
    'tmx-modal__content--large--withoutHeader': size !== 'small' && !header,
    'tmx-modal__content--withBackAndHeader': backButton && header,
    'tmx-modal__content--withBack': backButton && !header
  });

  const closeCallback = useCallback(() => {
    if (close) {
      close();
    }
  }, [close]);

  const backCallback = useCallback(() => {
    if (onBack) {
      onBack();
    }
  }, [onBack]);

  return (
    <section className={classNames(className, 'tmx-modal')}>
      <div
        className={classNames('tmx-modal__wrapper', customModalWrapper, {
          'tmx-modal__wrapper--large': size === 'large'
        })}
        ref={wrapperRef}>
        {header ? (
          <div className="tmx-modal__header">
            <div className="tmx-modal__header-name">{header}</div>
            <div className="tmx-modal__close-wrapper">
              <img src="/images/logo-line.svg" alt="logo-line" />
              <Icon
                name="close"
                size="micro"
                className="tmx-modal__close-wrapper__close"
                onClick={closeCallback}
              />
            </div>
          </div>
        ) : null}
        {closeIcon ? (
          <div className="tmx-modal__header__closeIcon">
            <Icon name="close" size="small" className="tmx-modal__close" onClick={closeCallback} />
          </div>
        ) : null}

        {backButton ? (
          <div className="tmx-modal__header__backButton">
            <Button
              buttonIcon="chevron"
              iconSize="medium"
              className="tmx-modal__back black"
              handleClick={() => backCallback()}>
              {' '}
              {backButton}
            </Button>
          </div>
        ) : null}
        {!header && logo && (
          <div className="tmx-modal__logoContainer">
            <Icon
              name="tmx-brand-mark-beta-centered"
              size="large"
              className="tmx-modal__logoWrapper logo"
            />
          </div>
        )}
        {title && (
          <div className="tmx-modal__title" dangerouslySetInnerHTML={{ __html: title }}></div>
        )}

        <div className={containerClasses} ref={contentRef || modalContentRef}>
          {children}
        </div>
      </div>
    </section>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'large']),
  header: PropTypes.string,
  vertical: PropTypes.bool,
  close: PropTypes.func,
  closeIcon: PropTypes.bool,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  title: PropTypes.string,
  customModalWrapper: PropTypes.string,
  backButton: PropTypes.string,
  onBack: PropTypes.func,
  logo: PropTypes.bool
};

Modal.defaultProps = {
  size: 'small'
};

export default Modal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Search.scss';

import Input from '../../atoms/Input/Input';

const Search = ({ onSearch, onChange, disabled }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="tmx-search">
      <div className="tmx-search__box">
        <Input
          disabled={disabled}
          className="tmx-search__search searchInput"
          placeholder="Search org, user, or email"
          inputIcon="search"
          iconSize="medium"
          value={inputValue}
          onChange={(e) => onChange(setInputValue, e.target.value)}
          onKeyDown={(event) => {
            if (event.which === 13) {
              onSearch({ search: inputValue });
            }
          }}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Search;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import './ExpertProfileSettings.scss';

import Search from '../../components/molecules/Search/Search';
import Button from '../../components/atoms/Button/Button';

import { adminOrgs } from '../../api/actions/org-actions';

const ExpertProfileSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, organizations } = useSelector((state) => state.orgReducer);

  const [search, setSearch] = useState(false);

  // const orgs = search ? organizations?.result : organizations?.result?.slice(-20);

  const orgs = organizations?.result;

  useEffect(() => {
    dispatch(adminOrgs({}, '', '', 'get_all_profile_details'));
  }, [dispatch]);

  // const isPendingOrgPresent = organizations?.result?.some((e) => e.status === 'PENDING');

  const onSearch = (searchTerm) => {
    setSearch(true);
    dispatch(adminOrgs(searchTerm));
  };

  const onChange = (setSearchTerm, searchTerm) => {
    setSearchTerm(searchTerm);
    if (!searchTerm) {
      setSearch(false);
      dispatch(adminOrgs());
    }
  };

  const renderMockContent = (count) => {
    let elements = [];
    for (let i = 1; i <= count; i++) {
      elements.push(<div className="tmx-expertProfileSettings__mockContent" key={i}></div>);
    }
    return elements;
  };

  return (
    <section className="tmx-expertProfileSettings__overallContainer">
      <section className="tmx-expertProfileSettings__shell">
        <h3 className="tmx-expertProfileSettings__title">TMX Access</h3>
        {/* <div className="tmx-expertProfileSettings__subTitle">Pending</div>
        <section className="tmx-expertProfileSettings tmx-expertProfileSettings--oveflow">
          {loading ? <div className="tmx-expertProfileSettings__mock"></div> : null}
          <div className="tmx-expertProfileSettings__result">
            {loading ? (
              <>{renderMockContent(4)}</>
            ) : isPendingOrgPresent ? (
              <>
                {orgs?.map((org, index) => {
                  return org.status === 'PENDING' ? (
                    <div
                      className={classNames(
                        'tmx-expertProfileSettings__content tmx-expertProfileSettings__content--pending'
                      )}
                      key={index}>
                      <Button
                        className={classNames('tmx-expertProfileSettings__contentTitle')}
                        handleClick={() =>
                          history.push('/expert-profile-details/' + org?.profile_id)
                        }>
                        <div className="tmx-expertProfileSettings__buttonTextContainer">
                          <div className="tmx-expertProfileSettings__buttonDetailsTitle">
                            {org?.name}
                          </div>
                          <div className="tmx-expertProfileSettings__buttonDetailsSubTitle">
                            {org?.company_name}
                          </div>
                          <div className="tmx-expertProfileSettings__buttonDetailsSubTitle">
                            {org?.occupation}
                          </div>
                        </div>
                      </Button>
                    </div>
                  ) : null;
                })}
              </>
            ) : (
              <>
                <div
                  className={classNames(
                    'tmx-expertProfileSettings__content tmx-expertProfileSettings__content--pending'
                  )}>
                  <Button className={classNames('tmx-expertProfileSettings__contentTitle')}>
                    <div className="tmx-expertProfileSettings__buttonTextContainer">
                      <div className="tmx-expertProfileSettings__buttonDetailsTitle">
                        No profiles in the pending queue
                      </div>
                    </div>
                  </Button>
                </div>
              </>
            )}
          </div>
        </section> */}
        <div className="tmx-expertProfileSettings__subTitle">Live</div>
        <section className="tmx-expertProfileSettings">
          <div className="tmx-expertProfileSettings__searchWrapper">
            <Search disabled onSearch={onSearch} onChange={onChange} />
          </div>
          {loading ? (
            <div className="tmx-expertProfileSettings__mock"></div>
          ) : (
            <p className="tmx-expertProfileSettings__count">
              {!search ? '' : `${orgs?.length} Results`}
            </p>
          )}
          <div className="tmx-expertProfileSettings__result tmx-expertProfileSettings__result--scroll">
            {loading ? (
              <>{renderMockContent(10)}</>
            ) : (
              <>
                {orgs?.map((org, index) => {
                  return org.status === 'APPROVED' ? (
                    <div
                      className={classNames('tmx-expertProfileSettings__content', {
                        search: search
                      })}
                      key={index}>
                      <Button
                        className={classNames('tmx-expertProfileSettings__contentTitle', {
                          search: search
                        })}
                        handleClick={() =>
                          history.push('/expert-profile-details/' + org?.profile_id)
                        }>
                        {search ? org?.org_name : org?.name}
                      </Button>
                      {search && (
                        <div
                          className={classNames('tmx-expertProfileSettings__userDetails', {
                            search: search
                          })}>
                          <div className="tmx-expertProfileSettings__userProperty">
                            User: {org?.user}
                          </div>
                          <div className="tmx-expertProfileSettings__userProperty">
                            Email: {org?.email}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
              </>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default ExpertProfileSettings;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './functionality/ScrollToTop';
import './App.scss';

// Pages
import Navigation from './components/organisms/Navigation/Navigation';
import ContentNotFound from './pages/ContentNotFound/ContentNotFound';
import HomePage from './pages/HomePage/HomePage';
import OrganizationSetting from './pages/OrganizationSetting/OrganizationSetting';
import KPIDashboard from './pages/KPIDashboard/KPIDashboard';
import Tools from './pages/Tools/Tools';
import Springer from './pages/Springer/Springer';
import OrganizationDetails from './pages/OrganizationDetails/OrganizationDetails';
import SellerDetails from './pages/SellerDetails/SellerDetails';
import SellerMediaDetails from './pages/SellerMediaDetails/SellerMediaDetails';
import ExpertList from './pages/ExpertList/ExpertList';
import ExpertProfileDetails from './pages/ExpertProfileDetails/ExpertProfileDetails';
import ExpertProfileSettings from './pages/ExpertProfileSettings/ExpertProfileSettings';

import ContentSellers from './pages/ContentSellers/ContentSellers';
import AuthenticateUser from './functionality/middleware/AuthenticateUser';
import MarketingCarousel from './pages/MarketingCarousel/MarketingCarousel';
import EmailAlerts from './pages/EmailAlerts/EmailAlerts';
import NoAccess from './pages/NoAccess/NoAccess';

// Redux Stores
import store from './api/stores/feed-store';
import UploadContainer from './pages/UploadContainer/UploadContainer';
import AddOrgs from './pages/AddOrgs/AddOrgs';

const configuredFeedStore = store();
const App = () => {
  return (
    <Provider store={configuredFeedStore}>
      <Router>
        <ScrollToTop>
          <Navigation />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route
              exact
              path="/organization-settings"
              component={AuthenticateUser(OrganizationSetting)}
            />
            <Route
              exact
              path="/expert-profile-settings"
              component={AuthenticateUser(ExpertProfileSettings)}
            />
            <Route exact path="/non-member-sellers" component={AuthenticateUser(ContentSellers)} />
            <Route exact path="/dashboard" component={AuthenticateUser(KPIDashboard)} />
            <Route exact path="/tools" component={AuthenticateUser(Tools)} />
            <Route exact path="/springer" component={AuthenticateUser(Springer)} />
            <Route
              exact
              path="/organization-details"
              component={AuthenticateUser(OrganizationDetails)}
            />
            <Route
              exact
              path="/expert-profile-details"
              component={AuthenticateUser(ExpertProfileDetails)}
            />
            <Route
              exact
              path="/expert-profile-details/:id"
              component={AuthenticateUser(ExpertProfileDetails)}
            />
            <Route exact path="/seller-details" component={AuthenticateUser(SellerDetails)} />
            <Route
              exact
              path="/seller-media-details"
              component={AuthenticateUser(SellerMediaDetails)}
            />
            <Route
              exact
              path="/marketing-carousel"
              component={AuthenticateUser(MarketingCarousel)}
            />
            <Route exact path="/emailAlerts" component={EmailAlerts} />
            <Route exact path="/no-access" component={NoAccess} />
            <Route exact path="/uploader" component={UploadContainer} />
            <Route exact path="/uploader/:id" component={UploadContainer} />
            <Route exact path="/add-orgs" component={AddOrgs} />
            <Route exact path="/experts" component={ExpertList} />
            <Route component={ContentNotFound} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default App;

import {
  RECEIVE_LOCATIONS_NEWSROOMS,
  SENT_CONTENT_EMAIL_SUCCESS,
  SENT_CONTENT_EMAIL_ERROR,
  SENDING_EMAIL_DATA,
  RESET_INITAL_STATE
} from '../../constants/action-types';

const initialState = {
  emailList: {},
  url: '',
  success: false,
  error: false,
  loading: false
};

export default function recommendReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LOCATIONS_NEWSROOMS:
      return { ...state, emailList: action.value };
    case SENDING_EMAIL_DATA:
      return { ...state, loading: true };
    case SENT_CONTENT_EMAIL_SUCCESS:
      return { ...state, success: true, loading: false };
    case SENT_CONTENT_EMAIL_ERROR:
      return { ...state, success: false, error: true };
    case RESET_INITAL_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

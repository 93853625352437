import {
  GET_SELLERS,
  RECEIVE_SELLERS,
  GET_SELLER,
  RECEIVE_SELLER,
  GET_SELLER_ERROR,
  GET_SELLERS_ERROR,
  CREATE_SELLER,
  CREATE_SELLER_ERROR,
  SEND_SELLER_ACCOUNT_INVITE,
  COMPLETE_SELLER_ACCOUNT_INVITE,
  GET_SELLER_MEDIA_DETAILS,
  GET_SELLER_MEDIA_DETAILS_ERROR,
  RECEIVE_SELLER_MEDIA_DETAILS
} from '../../constants/action-types';

import {
  GET_NON_MEMBER_SELLERS,
  GET_NON_MEMBER_SELLER_DETAILS,
  CREATE_NON_MEMBER_SELLER,
  SEND_BANK_ACCOUNT_INVITE,
  SELLER_MEDIA_DETAILS,
  SELLER_MEDIA_COST,
  SELLER_TRANSFER,
  ADD_ASSOCIATE_CONTENT,
  NON_MEMBER_REVENUE_SHARE
} from '../../functionality/networking';
import { callAjaxWCognito } from '../../functionality/CognitoTMX';

function getSellers() {
  return {
    type: GET_SELLERS
  };
}

function getSellersError() {
  return {
    type: GET_SELLERS_ERROR
  };
}

function getSellerError() {
  return {
    type: GET_SELLER_ERROR
  };
}

function getSeller() {
  return {
    type: GET_SELLER
  };
}

function createSeller() {
  return {
    type: CREATE_SELLER
  };
}

function sendSellerAccountInvite() {
  return {
    type: SEND_SELLER_ACCOUNT_INVITE
  };
}

function completeSellerAccountInvite() {
  return {
    type: COMPLETE_SELLER_ACCOUNT_INVITE
  };
}

function createSellerError() {
  return {
    type: CREATE_SELLER_ERROR
  };
}

function receiveSellers(value) {
  return {
    type: RECEIVE_SELLERS,
    value: value
  };
}

function receiveSeller(value) {
  return {
    type: RECEIVE_SELLER,
    value: value
  };
}

function getMediaDetails() {
  return {
    type: GET_SELLER_MEDIA_DETAILS
  };
}

function getMediaDetailsError() {
  return {
    type: GET_SELLER_MEDIA_DETAILS_ERROR
  };
}

function receiveMediaDetails(value) {
  return {
    type: RECEIVE_SELLER_MEDIA_DETAILS,
    value
  };
}

export function getNonMemberSellers(payload, callback) {
  return (dispatch) => {
    dispatch(getSellers());
    callAjaxWCognito(
      GET_NON_MEMBER_SELLERS,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(receiveSellers(parsedResponse.result));
          callback && callback();
        } else {
          dispatch(getSellersError(parsedResponse));
        }
      },
      (error) => {
        dispatch(getSellersError(error));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function getNonMemberSellersDetails(payload, callback) {
  return (dispatch) => {
    dispatch(getSeller());
    callAjaxWCognito(
      GET_NON_MEMBER_SELLER_DETAILS,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(receiveSeller({ ...parsedResponse.result, sellerId: payload.seller_id }));
          callback && callback();
        } else {
          dispatch(getSellerError(parsedResponse));
        }
      },
      (error) => {
        dispatch(getSellerError(error));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function createNonMemberSeller(payload, callback) {
  return (dispatch) => {
    dispatch(createSeller());
    callAjaxWCognito(
      CREATE_NON_MEMBER_SELLER,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(getNonMemberSellersDetails({ seller_id: parsedResponse.seller_id[0] }));
          callback && callback();
        } else {
          dispatch(createSellerError(parsedResponse));
        }
      },
      (error) => {
        dispatch(createSellerError(error));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function sendBankAccountInvite(payload, callback) {
  return (dispatch) => {
    dispatch(sendSellerAccountInvite());
    callAjaxWCognito(
      SEND_BANK_ACCOUNT_INVITE,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(completeSellerAccountInvite());
          callback && callback();
        } else {
          dispatch(completeSellerAccountInvite());
        }
      },
      (error) => {
        dispatch(completeSellerAccountInvite(error));
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function getSellerMediaDetails(payload) {
  return (dispatch) => {
    dispatch(getMediaDetails());
    callAjaxWCognito(
      SELLER_MEDIA_DETAILS,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          dispatch(receiveMediaDetails({ ...parsedResponse.result, sellerId: payload.seller_id }));
        } else {
          dispatch(getMediaDetailsError(parsedResponse));
        }
      },
      () => {
        dispatch(getMediaDetailsError());
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function sellerMediaCost(payload, callback) {
  return (dispatch) => {
    callAjaxWCognito(
      SELLER_MEDIA_COST,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          callback && callback();
          dispatch(
            getSellerMediaDetails({
              seller_id: payload.seller_id,
              media_id: payload.media_id,
              source: payload.source
            })
          );
        } else {
          callback && callback();
        }
      },
      () => {
        callback && callback();
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function transferFunds(payload, callback) {
  return () => {
    callAjaxWCognito(
      SELLER_TRANSFER,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          callback && callback('success');
        } else {
          callback && callback('error');
        }
      },
      () => {
        callback && callback('error');
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

export function addNewAssociateContent(payload, callback) {
  return () => {
    callAjaxWCognito(
      ADD_ASSOCIATE_CONTENT,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          callback && callback('success');
        } else {
          callback && callback('error');
        }
      },
      () => {
        callback && callback('error');
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}


export function addRevenueShare(payload, callback) {
  return () => {
    callAjaxWCognito(
      NON_MEMBER_REVENUE_SHARE,
      (response) => {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.success) {
          callback && callback();
        } else {
          callback && callback();
        }
      },
      () => {
        callback && callback();
      },
      JSON.stringify(payload ? payload : {})
    );
  };
}

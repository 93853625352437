import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

import Icon from '../Icon/Icon';

const Checkbox = ({ label, id, onClick, size, isChecked, iconClassName, disabled = false }) => {
  const [checked, setChecked] = useState(false);

  const onClickCallback = useCallback(
    (isChecked) => {
      if (!disabled) {
        setChecked(isChecked);
        onClick(isChecked);
      }
    },
    [onClick, disabled]
  );
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="tmx-Checkbox">
      <label htmlFor={id} className="tmx-Checkbox__wrapper">
        <span
          className={classNames('tmx-Checkbox__innerWrapper', {
            'tmx-Checkbox__innerWrapper--small': size === 'small'
          })}>
          <input
            className="tmx-Checkbox__input"
            type="checkbox"
            id={id}
            tabIndex="0"
            aria-checked={checked}
            onClick={(event) => onClickCallback(event.target.checked)}
            disabled={disabled}
          />
          <span
            className={classNames('tmx-Checkbox__frame', {
              'tmx-Checkbox__frame--small': size === 'small'
            })}></span>
          <span
            className={classNames('tmx-Checkbox__background', {
              'tmx-Checkbox__background--small': size === 'small'
            })}>
            <Icon
              name="check1"
              size={size === 'small' ? 'micro' : 'medium'}
              className={classNames('tmx-Checkbox__icon', iconClassName, {
                'tmx-Checkbox__icon--visible': checked,
                'tmx-Checkbox__icon--small': size === 'small'
              })}
            />
          </span>
        </span>
        <span
          className={classNames('tmx-Checkbox__label', {
            'tmx-Checkbox__label--small': size === 'small'
          })}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;

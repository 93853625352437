import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './EmailAlerts.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import DropDown from '../../components/atoms/DropDown/DropDown';
import Panel from '../../components/atoms/Panel/Panel';
import Icon from '../../components/atoms/Icon/Icon';

import { emailAlerts } from '../../api/actions/tools-actions';

const EmailAlerts = () => {
  const [emailItems, setEmailItems] = useState([
    { brick_id: null, title: null, content_type: null },
    { brick_id: null, title: null, content_type: null },
    { brick_id: null, title: null, content_type: null }
  ]);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [contentURL, setContentURL] = useState('');
  const [updatingAlerts, setUpdatingAlerts] = useState('');
  const [amOrPmAlert, setAmOrPmAlert] = useState('am_summary');
  const [showConfirmPanel, setShowConfirmPanel] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { emailAlertItems, emailAlertLoading } = useSelector((state) => state.toolsReducer);

  useEffect(() => {
    dispatch(emailAlerts({}));
  }, [dispatch]);

  useEffect(() => {
    if (emailAlertItems.length) {
      setEmailItems(emailAlertItems);
    }
  }, [emailAlertItems]);

  return (
    <>
      <div className="tmx-emailAlerts__backButtonContainer">
        <div
          className={classNames('tmx-emailAlerts__backBtnContainer', {
            'tmx-emailAlerts__backBtnContainer--loading': emailAlertLoading
          })}>
          {!emailAlertLoading && (
            <Button
              buttonIcon="arrow-right"
              iconSize="ginormous"
              className="tmx-emailAlerts__backContent black"
              handleClick={() => history.push('/tools')}>
              Back
            </Button>
          )}
        </div>
      </div>

      <section className="tmx-emailAlerts">
        <div className="tmx-emailAlerts__header">
          <h1>Top Content Emails</h1>{' '}
        </div>
        <section className="tmx-emailAlerts__detailsInfo">
          <div className="tmx-emailAlerts__dropDownContainer">
            <p> Choose Type</p>
            <DropDown
              onChange={(event) => {
                const amOrPmString =
                  event.target.value === 'Top AM Videos from TMX' ? 'am_summary' : 'pm_summary';
                setAmOrPmAlert(amOrPmString);
              }}
              data={['Top AM Videos from TMX', 'Top PM Videos from TMX']}
              className="tmx-emailAlerts__dropDown grey"
              id="endpoint"
              disabled={emailAlertLoading}
            />
          </div>
          <div className="tmx-emailAlerts__mediaContainer headers">
            <div className="tmx-emailAlerts__mediaContainerName header">Position </div>
            <div className="tmx-emailAlerts__mediaSubContainer">
              <div className="tmx-emailAlerts__mediaContainerTitle header">Title </div>
              <div className="tmx-emailAlerts__mediaContainerTitle header">Action </div>
            </div>
          </div>
          <div className="tmx-emailAlerts__media">
            {emailItems.map((email, key) => (
              <div
                className={classNames('tmx-emailAlerts__mediaContainer', {
                  'tmx-emailAlerts__mediaContainer--loading': emailAlertLoading
                })}
                key={key}>
                {!emailAlertLoading && (
                  <>
                    <div
                      className={classNames('tmx-emailAlerts__mediaContainerPosition', {
                        'tmx-emailAlerts__mediaContainerPosition--bold': emailAlertLoading
                      })}>
                      {key + 1}
                      <Button
                        handleClick={() => {
                          dispatch(emailAlerts({ move_brick_up: email.brick_id }));
                        }}
                        disabled={key === 0}>
                        up
                      </Button>
                      <Button
                        handleClick={() => {
                          dispatch(emailAlerts({ move_brick_down: email.brick_id }));
                        }}
                        disabled={key === emailItems.length - 1}>
                        down
                      </Button>
                    </div>
                    <div className="tmx-emailAlerts__mediaSubContainer">
                      <div className="tmx-emailAlerts__mediaContainerTitle">{email.title}</div>
                      <div className="tmx-emailAlerts__mediaContainerAction">
                        <div className="tmx-emailAlerts__sortButtonsContainer">
                          <Button
                            className="tmx-emailAlerts__UpSortBtn"
                            handleClick={() => {
                              dispatch(emailAlerts({ move_brick_up: email.brick_id }));
                            }}
                            disabled={key === 0}>
                            up
                          </Button>
                          <Button
                            className="tmx-emailAlerts__DownSortBtn"
                            handleClick={() => {
                              dispatch(emailAlerts({ move_brick_down: email.brick_id }));
                            }}
                            disabled={key === emailItems.length - 1}>
                            down
                          </Button>
                        </div>
                        <Button
                          type="button"
                          className={`tmx-emailAlerts__deleteBrick`}
                          handleClick={() => {
                            dispatch(emailAlerts({ delete_brick: email.brick_id }));
                          }}
                          disabled={false}>
                          Delete
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          {showAddEmail && (
            <div className="tmx-emailAlerts__mediaContainer newCarousel">
              <p
                className={classNames('tmx-emailAlerts__mediaContainerPosition', {
                  'tmx-emailAlerts__mediaContainerPosition--bold': true
                })}>
                {emailAlertItems.length + 1}
              </p>
              <div className="tmx-emailAlerts__mediaSubContainer addNewCarousel">
                <div>
                  <Input
                    id="contentUrl"
                    className="tmx-emailAlerts__contentUrlInput"
                    labelClassName="tmx-emailAlerts__contentURLInputLabel"
                    name="contentUrl"
                    darkBorder
                    value={contentURL}
                    onChange={(event) => {
                      setContentURL(event.target.value);
                    }}
                    type="text"
                    label={'Content URL'}
                    htmlFor={'contentUrl'}
                    disabled={emailAlertLoading}
                  />
                </div>

                <div className="tmx-emailAlerts__updateContentBtnContainer">
                  <Button
                    className="tmx-emailAlerts__updateContentBtn"
                    buttonIcon={updatingAlerts !== 'save' && 'plus-circled'}
                    iconSize="small"
                    spinner={updatingAlerts === 'save'}
                    handleClick={() => {
                      setUpdatingAlerts('save');
                      dispatch(
                        emailAlerts({ add_url: contentURL }, () => {
                          setUpdatingAlerts('');
                          setShowAddEmail(false);
                          setContentURL('');
                        })
                      );
                    }}
                    disabled={contentURL === '' || emailAlertLoading || updatingAlerts}>
                    Save
                  </Button>
                  <Button
                    className="tmx-emailAlerts__updateContentBtn"
                    buttonIcon="minus-circled"
                    iconSize="small"
                    handleClick={() => setShowAddEmail(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div className="tmx-emailAlerts__sendButtonContainer">
            {!showAddEmail && (
              <Button
                className="tmx-emailAlerts__btn"
                buttonIcon={!showAddEmail && 'plus'}
                handleClick={() => setShowAddEmail(!showAddEmail)}
                disabled={false}>
                Add new video
              </Button>
            )}
            <Button
              className="tmx-emailAlerts__btn"
              handleClick={() => {
                setUpdatingAlerts('test');
                dispatch(
                  emailAlerts({ test_email: amOrPmAlert }, () => {
                    setUpdatingAlerts('');
                  })
                );
              }}
              spinner={updatingAlerts === 'test'}>
              Test email
            </Button>
            <Button
              className="tmx-emailAlerts__btn"
              handleClick={() => {
                setShowConfirmPanel(true);
              }}>
              Send to users
            </Button>
          </div>
          {showConfirmPanel ? (
            <Panel
              closeIcon
              close={() => {
                setShowConfirmPanel(false);
                setEmailSent(false);
              }}>
              {!emailSent && (
                <div className="tmx-emailAlerts__panelText">
                  <h1>Ready to send?</h1>
                  <p className="tmx-emailAlerts__panelParagraph">
                    Does the test email look OK? Do the links work? Are you sure you&apos;re ready
                    to send to everyone on the distribution list?{' '}
                  </p>

                  <Button
                    buttonType="blue"
                    className="tmx-emailAlerts__joinTMXButton rounded"
                    handleClick={() => {
                      setUpdatingAlerts('send');
                      setShowConfirmPanel(true);
                      dispatch(
                        emailAlerts({ send_email: amOrPmAlert }, () => {
                          setUpdatingAlerts('');
                          setEmailSent(true);
                        })
                      );
                    }}
                    spinner={updatingAlerts === 'send'}>
                    Yes, send
                  </Button>
                </div>
              )}
              {emailSent && (
                <div className="tmx-emailAlerts__accountSuccess">
                  <div className="tmx-emailAlerts__successCheckMarkContainer">
                    <Icon name="check1" size="huge" className="tmx-emailAlerts__check" />
                  </div>
                  <p className="tmx-emailAlerts__accountCreated">Successfully sent.</p>
                </div>
              )}
            </Panel>
          ) : null}
        </section>
      </section>
    </>
  );
};

export default EmailAlerts;

import React from 'react';

export const RecommendContext = React.createContext({
  closePanel: null,
  dispatch: null,
  file: {},
  setFile: null,
  uploadState: {},
  userData: null
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';

const NewLocationInputs = ({ setLocations }) => {
  const [newCity, setNewCity] = useState('');
  const [newStateOrCountry, setNewStateOrCountry] = useState('');

  const handleClick = (
    newCity,
    newStateOrCountry,
    setLocations,
    setNewCity,
    setNewStateOrCountry
  ) => {
    let newLocation = '';

    if (newCity.trim() !== '') {
      newLocation = newCity;
    }
    if (newStateOrCountry.trim() !== '') {
      if (newLocation !== '') {
        newLocation += ', ';
      }
      newLocation += newStateOrCountry;
    }

    if (newLocation !== '') {
      setLocations((prevLocations) => [...prevLocations, newLocation]);
      setNewCity('');
      setNewStateOrCountry('');
    } else {
      // You can handle the case when all input fields are empty here (e.g., show an error message or prevent adding empty locations).
    }
  };

  return (
    <div>
      <Input
        className=""
        htmlFor="NewCity"
        type="text"
        label="City*"
        greyBorder
        onChange={(e) => setNewCity(e.target.value)}
        value={newCity}
      />
      <Input
        className=""
        htmlFor="State or Country"
        type="text"
        label="State or Country*"
        greyBorder
        onChange={(e) => setNewStateOrCountry(e.target.value)}
        value={newStateOrCountry}
      />
      <Button
        clasName=""
        type="button"
        buttonType="grey"
        handleClick={() =>
          handleClick(newCity, newStateOrCountry, setLocations, setNewCity, setNewStateOrCountry)
        }
        disabled={newCity === '' || newStateOrCountry === ''}>
        Add New Location
      </Button>
    </div>
  );
};

NewLocationInputs.propTypes = {
  setLocations: PropTypes.func
};

export default NewLocationInputs;

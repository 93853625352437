import React from 'react';
import { Link } from 'react-router-dom';

import './ContentNotFound.scss';

const ContentNotFound = () => {
  return (
    <section className="tmx-contentNotFound">
      <img src="/images/error.svg" alt="error" className="tmx-contentNotFound__image" />
      <div className="tmx-contentNotFound__textWrapper">
        <p className="tmx-contentNotFound__text">We apologize! Something went wrong. Please </p>
        <Link to="/" className="tmx-contentNotFound__link">
          go back to the home.
        </Link>
      </div>
    </section>
  );
};

export default ContentNotFound;

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { USER_POOL_ID, CLIENT_ID } from './networking';

let USER_POOL = null;

const getUserPool = () => {
  if (USER_POOL === null) {
    var poolData = {
      UserPoolId: USER_POOL_ID,
      ClientId: CLIENT_ID
    };
    USER_POOL = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }
  return USER_POOL;
};
const callAjax = (
  url,
  handleResponse,
  handleError,
  body,
  headers = {},
  method = 'POST',
  mode = 'cors'
) => {
  fetch(url, {
    method: method,
    mode: mode,
    body: body,
    headers: headers
  })
    .then(checkStatus)
    .then(handleResponse)
    .catch(handleError);
};

const callAjaxWCognito = (
  url,
  handleResponse,
  handleError,
  body,
  headers = {},
  method = 'POST',
  mode = 'cors'
) => {
  let cognitoUser = getUserPool().getCurrentUser();
  if (cognitoUser == null) {
    window.localStorage.setItem('prev_video', window.location.pathname);
    window.location.replace('/');
    return;
  }
  cognitoUser.getSession((err, session) => {
    if (err) {
      return;
    }
    cognitoUser.getUserAttributes((err) => {
      if (err) {
        handleError(err);
        if (err.message === 'User is disabled.') {
          window.location.replace('/signout/true');
        }
      } else {
        // user is valid
        headers.Authorization = session.getIdToken().getJwtToken();
        callAjax(url, handleResponse, handleError, body, headers, method, mode);
      }
    });
  });
};

/**
 * Check the status of an AJAX query reply before returning the appropriate
 * result to the next handler function in the AJAX handling chain.
 * based on: https://developers.google.com/web/updates/2015/03/introduction-to-fetch
 * @param {Response object} response   JSON response from the server
 * @return                             The body of the response.
 *                                     Resolves promise if reply successful, rejects if not
 */
const checkStatus = (response) => {
  let responseText = response.text();
  if (response.status >= 200 && response.status < 300) {
    return responseText;
  } else {
    return responseText.then(Promise.reject.bind(Promise));
  }
};

const login = (email, password, success = alert_id, failure = alert, newPass = alert) => {
  let authData = {
    Username: email,
    Password: password
  };
  let authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData);
  let userData = {
    Username: authData['Username'],
    Pool: getUserPool()
  };
  let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.authenticateUser(authDetails, {
    onSuccess: success,
    onFailure: failure,
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      newPass(userAttributes, requiredAttributes, cognitoUser);
    }
  });
};

const makeUserAttribute = (name, value) => {
  var data = {
    Name: name,
    Value: value
  };
  return new AmazonCognitoIdentity.CognitoUserAttribute(data);
};

const alert_id = (result) => {
  //Use the idToken for Logins Map when Federating User Pools with identity pools
  //or when passing through an Authorization Header to an API Gateway Authorizer
  let idToken = result.idToken.jwtToken;
  alert(idToken);
};

const isLoggedIn = () => {
  return getUserPool().getCurrentUser();
};

const refreshUserAttributes = () => {
  let cognitoUser = getUserPool().getCurrentUser();
  if (cognitoUser == null) {
    // not logged in
    window.localStorage.setItem('prev_video', window.location.pathname);
    return;
  }
  cognitoUser.getSession((err) => {
    if (err) {
      return;
    }
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        return;
      }
      result.forEach((attr) => {
        if (attr.Name === 'custom:license_for') {
          let user_data = JSON.parse(window.localStorage.getItem('user_data'));
          user_data.user.licenseFor = attr.Value;
          window.localStorage.setItem('user_data', JSON.stringify(user_data));
        }
      });
    });
  });
};

export {
  login,
  callAjaxWCognito,
  callAjax,
  getUserPool,
  makeUserAttribute,
  isLoggedIn,
  refreshUserAttributes
};

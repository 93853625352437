import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import './ContentSellers.scss';

import Search from '../../components/molecules/Search/Search';
import Button from '../../components/atoms/Button/Button';

import { getNonMemberSellers } from '../../api/actions/sellers-actions';

const ContentSellers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, sellers } = useSelector((state) => state.sellersReducer);

  const [search, setSearch] = useState(false);

  const nonMemberSellers = search ? sellers : sellers?.slice(-20).sort().reverse();

  useEffect(() => {
    dispatch(getNonMemberSellers());
  }, [dispatch]);

  const onSearch = (searchInput) => {
    setSearch(true);
    dispatch(getNonMemberSellers({ query: searchInput.search }));
  };

  const onChange = (setInputValue, inputValue) => {
    setInputValue(inputValue);
    if (!inputValue) {
      setSearch(false);
      dispatch(getNonMemberSellers());
    }
  };

  const renderMockContent = () => {
    let elements = [];
    for (let i = 1; i <= 20; i++) {
      elements.push(<div className="tmx-contentSellers__mockContent" key={i}></div>);
    }
    return elements;
  };

  return (
    <section className="tmx-contentSellers">
      <h3 className="tmx-contentSellers__title">Search Non-member Sellers</h3>
      <Button
        buttonType="blue"
        className="tmx-contentSellers__btn"
        buttonIcon="plus"
        iconSize="small"
        handleClick={() => window.location.replace('/seller-details')}>
        Add new seller
      </Button>
      <div className="tmx-contentSellers__searchWrapper">
        <Search onSearch={onSearch} onChange={onChange} />
      </div>
      {loading ? (
        <div className="tmx-contentSellers__mock"></div>
      ) : (
        <p className="tmx-contentSellers__count">
          {!search
            ? '20 most recently added Non-member Sellers'
            : `${nonMemberSellers?.length} Results`}
        </p>
      )}
      <div className="tmx-contentSellers__result">
        {loading ? (
          <>{renderMockContent()}</>
        ) : (
          <>
            {nonMemberSellers?.map((seller, index) => (
              <div
                className={classNames('tmx-contentSellers__content', { search: search })}
                key={index}>
                <Button
                  className={classNames('tmx-contentSellers__contentTitle', {
                    search: search
                  })}
                  handleClick={() =>
                    history.push('/seller-details', {
                      sellerId: seller?.seller_id
                    })
                  }>
                  <p className="tmx-contentSellers__contentSellerName">{seller?.seller_name}</p>
                  <p className="tmx-contentSellers__contentSellerEmail">{seller?.seller_email}</p>
                </Button>
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default ContentSellers;

import React from 'react';
import PropTypes from 'prop-types';

import './transfer.scss';

import Modal from '../../atoms/Modal/Modal';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import DropDown from '../../atoms/DropDown/DropDown';

const Transfer = ({
  closeModal,
  formValues,
  loadingTransfer,
  saleAmount,
  sellerId,
  setFormValues,
  stripePaymentId,
  transferFunds,
  transferResponse
}) => {
  return (
    <Modal close={closeModal} title="Transfer funds" closeIcon size="large" logo>
      <section className="tmx-transfer">
        <div className="tmx-transfer__sellerInfo">
          SellerId: <strong>{sellerId}</strong>
        </div>
        <div className="tmx-transfer__sellerInfo">
          Stripe Payment ID: <strong>{stripePaymentId}</strong>
        </div>

        <div className="tmx-transfer__sellerInfo">
          Sale Amount: <strong>{`$${saleAmount}`}</strong>
        </div>
        <div className="tmx-transfer__form">
          {transferResponse === 'error' && (
            <p className="tmx-transfer__errorMessage">Transfer error, funds already transferred</p>
          )}
          <Input
            className="tmx-transfer__formInput"
            id="Amount"
            name="Amount"
            darkBorder
            value={formValues.share_amount_cents}
            onChange={(event) => {
              setFormValues({ ...formValues, share_amount_cents: parseInt(event.target.value) });
            }}
            type="number"
            label={'Transfer amount'}
            htmlFor={'amount'}
            disabled={loadingTransfer}
          />
          <div className="tmx-transfer__dropDownContainer">
            <label className="tmx-input__uploadFormLabel" htmlFor="pricingModel">
              Payment source
            </label>
            <DropDown
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  source_type: event.target.value === 'Card' ? 'card' : 'bank_account'
                });
              }}
              optionSelected={formValues.source_type || 'Bank Account'}
              className="tmx-transfer__dropDown"
              data={['Bank Account', 'Card']}
              id="source"
              disabled={loadingTransfer}
            />
          </div>
          <Input
            className="tmx-transfer__formInput"
            id="description"
            name="Description"
            darkBorder
            value={formValues.description}
            onChange={(event) => {
              setFormValues({ ...formValues, description: event.target.value });
            }}
            type="text"
            label={'Description'}
            htmlFor={'Description'}
            disabled={loadingTransfer}
          />

          <Button
            buttonType="blue"
            className="tmx-transfer__btn"
            buttonIcon={transferResponse === 'success' ? 'check1' : ''}
            spinner={loadingTransfer}
            handleClick={() => transferFunds()}
            disabled={
              loadingTransfer ||
              transferResponse === 'success' ||
              formValues.share_amount_cents === '' ||
              formValues.description === ''
            }>
            {transferResponse === 'success' ? 'Payment sent' : 'Send payment'}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

Transfer.propTypes = {
  closeModal: PropTypes.func,
  formValues: PropTypes.object,
  loadingTransfer: PropTypes.bool,
  saleAmount: PropTypes.number,
  sellerId: PropTypes.number,
  setFormValues: PropTypes.func,
  stripePaymentId: PropTypes.string,
  transferFunds: PropTypes.func,
  transferResponse: PropTypes.string
};
export default Transfer;

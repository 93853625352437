import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import './TextArea.scss';

const TextArea = ({
  className,
  darkBorder,
  disabled,
  error,
  greyBorder,
  htmlFor,
  label,
  labelClassName,
  maxCharacters,
  onChange,
  placeholder,
  maxRows,
  statusBar,
  usageGuidelines,
  value,
  name,
  field
}) => {
  const [count, setCount] = useState(0);

  const classes = classNames('tmx-textArea', className, {
    grey: greyBorder,
    dark: darkBorder,
    usageGuidelines: usageGuidelines,
    disabled
  });

  const callback = useCallback(
    (event) => {
      if (maxCharacters === 'noLimit') {
        onChange(event);
      } else {
        setCount(event.target.value.length);
        onChange(event);
      }
    },
    [onChange, maxCharacters, setCount]
  );
  return (
    <div>
      <div className="tmx-textArea__labelWrapper">
        <div>
          {label && (
            <label
              className={classNames('tmx-textArea__uploadFormLabel', labelClassName)}
              htmlFor={htmlFor}>
              {label} {field ? <sup>*</sup> : null}
            </label>
          )}
        </div>
        {error && <div className="tmx-textArea__errorMessage">{error}</div>}
        <div>
          {statusBar && count ? (
            <div className="tmx-textArea__statusBar">
              <p className="tmx-textArea__counterPara">
                {count}/{maxCharacters}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <TextareaAutosize
        className={classes}
        onChange={callback}
        maxRows={maxRows}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name={name}
        maxLength={maxCharacters}
      />
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  darkBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  field: PropTypes.string,
  greyBorder: PropTypes.bool,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  maxCharacters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRows: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  statusBar: PropTypes.bool,
  usageGuidelines: PropTypes.bool,
  value: PropTypes.string
};

TextArea.defaultProps = {
  statusBar: false,
  greyBorder: false,
  value: ''
};

export default TextArea;

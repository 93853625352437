import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function AuthenticateUser(ComposedComponent) {
  const Authenticate = () => {
    const history = useHistory();
    const { loggedIn, userData } = useSelector((state) => state.signInReducer);

    useEffect(() => {
      if (!loggedIn) {
        history.push('/');
      } else {
        if (!userData.permissions.includes('Org.MODIFY_ORG')) {
          history.push('/no-access');
        }
      }
    });

    return <ComposedComponent />;
  };

  return Authenticate;
}

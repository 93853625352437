import {
  RECEIVE_LOCATIONS_NEWSROOMS,
  SENT_CONTENT_EMAIL_SUCCESS,
  SENT_CONTENT_EMAIL_ERROR,
  SENDING_EMAIL_DATA,
  RESET_INITAL_STATE
} from '../../constants/action-types';

import { RECOMMENDER_API } from '../../functionality/networking';

import { callAjaxWCognito } from '../../functionality/CognitoTMX';

function receiveLocationsNewsrooms(value) {
  return {
    type: RECEIVE_LOCATIONS_NEWSROOMS,
    value: value
  };
}

function sentContentEmailsSuccess() {
  return {
    type: SENT_CONTENT_EMAIL_SUCCESS
  };
}

function sentContentEmailsError() {
  return {
    type: SENT_CONTENT_EMAIL_ERROR
  };
}

function sendingEmailData() {
  return {
    type: SENDING_EMAIL_DATA
  };
}

export function resetInitialState() {
  return {
    type: RESET_INITAL_STATE
  };
}

export function getRecLists() {
  return (dispatch) => {
    const payLoad = {};

    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      if (parsedResponse.success) {
        dispatch(receiveLocationsNewsrooms(parsedResponse.emails));
      }
    };
    const handleError = (response) => {
      console.warn(response);
    };

    callAjaxWCognito(RECOMMENDER_API, handleSuccess, handleError, JSON.stringify(payLoad));
  };
}

export function sendContentData(url, sendToEmailList) {
  return (dispatch) => {
    dispatch(sendingEmailData());
    callAjaxWCognito(
      RECOMMENDER_API,
      (response) => {
        const parsedResponse = JSON.parse(response);

        if (parsedResponse.success) {
          dispatch(sentContentEmailsSuccess());
        }
      },
      () => {
        dispatch(sentContentEmailsError());
      },
      JSON.stringify({
        url: url,
        emails: sendToEmailList
      })
    );
  };
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import './ExpertProfileDetails.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import DropDown from '../../components/atoms/DropDown/DropDown';
import Icon from '../../components/atoms/Icon/Icon';
import useScrollDisabling from '../../functionality/Hooks/rootComponentScroll';
import useDetectDevice from '../../functionality/Hooks/detectDevice';
import TextArea from '../../components/atoms/TextArea/TextArea';
import FileUpload from '../../components/atoms/FileUpload/FileUpload';
import Tags from '../../components/atoms/Tags/Tags';

import { adminOrgs, shouldFetchLists } from '../../api/actions/org-actions';

import { ReactComponent as Delete } from '../../images/delete.svg';

const ExpertProfileDetails = () => {
  const route = useRouteMatch();
  const profile_id = route.params.id;
  const history = useHistory();
  const { editLoading, error, loading, loadingMulti, singleOrg, locationsArray } = useSelector(
    (state) => state.orgReducer
  );
  const org = singleOrg?.result ? singleOrg?.result : null;
  const dispatch = useDispatch();
  const isMobile = useDetectDevice(430);
  const { setDisabled } = useScrollDisabling(false);

  const [edit, setEdit] = useState(false);
  const [organization, setOrganization] = useState({});
  const [editedData, setEditedData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [save, setSaved] = useState(false);

  const [isProfileImage, setIsProfileImage] = useState(false);
  const [originalData, setOriginalData] = useState({});
  useEffect(() => {
    setDisabled(document.getElementsByClassName('tmx-modal')[0]);
  });

  useEffect(() => {
    dispatch(adminOrgs());
    dispatch(shouldFetchLists());
  }, [dispatch, profile_id]);

  useEffect(() => {
    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
    dispatch(shouldFetchLists());
  }, [dispatch, profile_id]);

  useEffect(() => {
    if (org) {
      setOrganization(org);
      setOriginalData(org);
      // setOCS(org.involved_in_ocs);
    }
  }, [org]);

  const callback = () => {
    setEditedData(null);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  // const enableBtn = () => {
  //   if (edit && NewsroomPaymentSuccess) {
  //     setEdit(!NewsroomPaymentSuccess);
  //   }
  // };

  const handleEditProfile = (accept) => {
    setIsProfileImage(false);
    if ((organization?.status === 'PENDING' || organization?.status === 'APPROVED') && accept) {
      // setOrganization({ ...organization, status: 'APPROVED' });
      const { ...rest } = organization;
      dispatch(
        adminOrgs(
          {
            ...rest,
            status: 'APPROVED'
          },
          callback,
          '',
          'update_profile_details',
          resetState
        )
      );
    } else if (organization?.status === 'PENDING' && !accept) {
      // setOrganization({ ...organization, status: 'REJECTED' });
      const { ...rest } = organization;
      dispatch(
        adminOrgs(
          {
            ...rest,
            status: 'REJECTED'
          },
          callback,
          '',
          'update_profile_details',
          resetState
        )
      );
    }
  };

  const resetState = () => {
    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
    dispatch(shouldFetchLists());
    setEditedData(null);
    setEdit(!edit);
    enableBtn();
  };

  const enableBtn = () => {
    if (!edit) {
      setSaved(false);
    } else if (edit && save) {
      setSaved(true);
    }
  };

  const addNewURLField = () => {
    const copy = { ...organization };
    copy.other_media.push('');
    setOrganization(copy);
  };

  const removeURLField = (index) => {
    const copy = { ...organization };
    copy.other_media.splice(index, 1);
    setOrganization(copy);
    setEditedData(copy);
  };

  const handleURLChange = (index, e) => {
    const copy = { ...organization };
    copy.other_media[index] = e.target.value;
    setOrganization(copy);
    setEditedData(copy);
  };
  return (
    <>
      <div>
        <section className="tmx-expertProfileDetails">
          <div className="tmx-expertProfileDetails__overallContainer">
            <div className="tmx-expertProfileDetails__backButtonContainer">
              <Button
                buttonIcon="arrow-right"
                buttonIconClassName="tmx-expertProfileDetails__backButton"
                iconSize="ginormous"
                className="tmx-expertProfileDetails__backContent black"
                handleClick={() => history.push('/expert-profile-settings')}>
                {isMobile ? '' : 'Back to Search'}
              </Button>
            </div>
            <div className="tmx-expertProfileDetails__companyName">{organization?.name}</div>
            <section className="tmx-expertProfileDetails__organizationInfo">
              <div className="tmx-expertProfileDetails__orgSettingsHeader">
                <h1>Information</h1>
                <Button
                  className="tmx-expertProfileDetails__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
                    dispatch(shouldFetchLists());
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Discard' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-expertProfileDetails__formInlineWrapper">
                <div className="tmx-expertProfileDetails__formInline space">
                  <div className="tmx-expertProfileDetails__formTitle">Profile Information</div>
                  <div className="tmx-expertProfileDetails__formDetails">
                    Enter the as much expert profile information to create a full profile page.
                  </div>
                </div>
                <div className="tmx-expertProfileDetails__inputGrid">
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="name"
                        name="name"
                        darkBorder
                        value={organization?.name}
                        onChange={(event) => {
                          setOrganization({ ...organization, name: event.target.value });
                          setEditedData({
                            ...editedData,
                            name: event.target.value
                          });
                        }}
                        type="text"
                        label={'Full Name *'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'name'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="occupation"
                        name="occupation"
                        darkBorder
                        value={organization.occupation}
                        onChange={(event) => {
                          setOrganization({ ...organization, occupation: event.target.value });
                          setEditedData({
                            ...editedData,
                            occupation: event.target.value
                          });
                        }}
                        type="text"
                        label={'Occupation *'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'occupation'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div className="tmx-expertProfileDetails__newsroomLabel">
                    <div className="tmx-uploadForm__label">
                      Location<sup> *</sup>
                    </div>
                    <Tags
                      notArray={true}
                      disabled={!edit}
                      tags={locationsArray || []}
                      limit={1}
                      initial={organization?.location}
                      count={organization?.location?.length}
                      onChange={(arr) => {
                        setOrganization({
                          ...organization,
                          location: arr[0]
                        });
                        setEditedData({
                          ...organization,
                          location: arr[0]
                        });
                      }}
                    />
                  </div>
                  <div className="tmx-expertProfileDetails__newsroomLabel">
                    <TextArea
                      id="newsroomDesc"
                      name="newsroomDesc"
                      darkBorder
                      disabled={!edit}
                      className="tmx-expertProfileDetails__inputForm"
                      rowsMax={5}
                      value={organization.bio}
                      maxCharacters="1000"
                      onChange={(event) => {
                        setSaved(false);
                        setOrganization({ ...organization, bio: event.target.value });
                        setEditedData({
                          ...editedData,
                          bio: event.target.value
                        });
                      }}
                      type="text"
                      htmlFor={'publicistPrice'}
                      label={'Bio *'}
                      labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                      statusBar={true}
                      placeholder={
                        'If you have an official bio, just paste it here.  If not, just add a few sentences.'
                      }
                    />
                  </div>

                  <div className="tmx-expertProfileDetails__newsroomLabel">
                    <div className="tmx-uploadForm__label">
                      Tags<sup> *</sup>
                    </div>

                    <Tags
                      disabled={!edit}
                      label={'Select one or more tags'}
                      onChange={(arr) => {
                        setOrganization({
                          ...organization,
                          tags: arr.map((obj) => obj.title)
                        });
                        setEditedData({
                          ...organization,
                          tags: arr.map((obj) => obj.title)
                        });
                      }}
                      initial={organization?.tags}
                      view={'prOrg'}
                    />
                  </div>

                  {!loading && !loadingMulti ? (
                    <div className="tmx-expertProfileDetails__newsroomLabel">
                      <FileUpload
                        isProfile={true}
                        profilePhotoVideoPresent={!!organization.profile_file}
                        isDownloadableContent={false}
                        setIsThumbnail={setIsProfileImage}
                        //   setThumbNailList={setThumbNailList}
                        //   thumbNailList={thumbNailList}
                        view={'article'}
                        isThumbnail={isProfileImage}
                        onChange={(uploads) => {
                          if (uploads.length > 0) {
                            setIsProfileImage(true);
                            setOrganization({ ...organization, profile_file: uploads[0].key });
                            setEditedData({ ...organization, profile_file: uploads[0].key });
                          } else {
                            setIsProfileImage(false);
                            setOrganization({
                              ...organization,
                              profile_file: originalData.profile_file
                            });
                            setEditedData({
                              ...organization,
                              profile_file: originalData.profile_file
                            });
                          }
                        }}
                        diabled={!edit}
                        page={'ExpertProfileDetails'}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
            <section className="tmx-expertProfileDetails__organizationInfo">
              <div className="tmx-expertProfileDetails__orgSettingsHeader">
                <h1>Contact Method</h1>
                <Button
                  className="tmx-expertProfileDetails__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
                    dispatch(shouldFetchLists());
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Discard' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-expertProfileDetails__formInlineWrapper">
                <div className="tmx-expertProfileDetails__formInline space">
                  <div className="tmx-expertProfileDetails__formTitle">
                    Preferred Method and Contact Info
                  </div>
                  <div className="tmx-expertProfileDetails__formDetails">
                    Chose the prefered method for a journalist to contact the profile subject.
                  </div>
                </div>
                <div className="tmx-expertProfileDetails__inputGrid">
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    <label
                      className="tmx-expertProfileDetails__uploadFormLabel"
                      htmlFor="selectFrequency">
                      Preferred Contact Method *
                    </label>
                    <DropDown
                      disableOnBlur
                      // enableValue
                      onChange={(event) => {
                        if (event.target.value !== 'Select Contact Method') {
                          const copy = { ...organization };
                          copy.contacts[0].method =
                            event.target.value === 'Contact this person directly'
                              ? 'directly'
                              : event.target.value === 'Contact their representative'
                              ? 'representative'
                              : 'assistant';
                          setOrganization(copy);
                          setEditedData(organization);

                          setEditedData(organization);
                        }
                      }}
                      optionSelected={
                        organization?.contacts?.length > 0
                          ? organization?.contacts[0]?.method === 'directly'
                            ? 'Contact this person directly'
                            : organization?.contacts[0]?.method === 'representative'
                            ? 'Contact their representative'
                            : organization?.contacts[0]?.method === 'assistant'
                            ? 'Contact their assistant'
                            : ''
                          : ''
                      }
                      className="tmx-expertProfileDetails__dropDown grey"
                      data={[
                        'Contact this person directly',
                        'Contact their representative',
                        'Contact their assistant'
                      ]}
                      name="Select Contact Method"
                      id="permission"
                      disabled={!edit}
                    />
                  </div>
                  {organization?.contacts &&
                  (organization?.contacts[0]?.method === 'representative' ||
                    organization?.contacts[0]?.method === 'assistant') ? (
                    <div
                      className={classNames('tmx-expertProfileDetails__formInline', {
                        'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                      })}>
                      {!loading && !loadingMulti && (
                        <Input
                          id="occupation"
                          name="occupation"
                          darkBorder
                          value={organization?.contacts ? organization?.contacts[0].name : ''}
                          onChange={(event) => {
                            const copy = { ...organization };
                            copy.contacts[0].name = event.target.value;
                            setOrganization(copy);
                            setEditedData(organization);
                          }}
                          type="text"
                          label={
                            organization?.contacts[0]?.method === 'representative'
                              ? 'Representatives Full Name *'
                              : organization?.contacts[0]?.method === 'assistant'
                              ? 'Assistant’s Full Name *'
                              : ''
                          }
                          labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                          htmlFor={'occupation'}
                          disabled={!edit}
                        />
                      )}
                    </div>
                  ) : null}

                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="occupation"
                        name="occupation"
                        darkBorder
                        value={organization?.contacts ? organization?.contacts[0].phone : ''}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.contacts[0].phone = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Phone *'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'occupation'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="location"
                        name="location"
                        darkBorder
                        value={organization?.contacts ? organization?.contacts[0].email : ''}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.contacts[0].email = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Email *'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'location'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  {organization?.contacts && organization?.contacts[0]?.method === 'directly' ? (
                    <div
                      className={classNames('tmx-expertProfileDetails__formInline', {
                        'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                      })}>
                      {!loading && !loadingMulti && (
                        <Input
                          id="location"
                          name="location"
                          darkBorder
                          value={
                            organization?.contacts ? organization?.contacts[0].scheduling_link : ''
                          }
                          onChange={(event) => {
                            const copy = { ...organization };
                            copy.contacts[0].scheduling_link = event.target.value;
                            setOrganization(copy);
                            setEditedData(organization);
                          }}
                          type="text"
                          label={'Scheduling link *'}
                          labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                          htmlFor={'location'}
                          disabled={!edit}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
            <section className="tmx-expertProfileDetails__organizationInfo">
              <div className="tmx-expertProfileDetails__orgSettingsHeader">
                <h1>Links</h1>
                <Button
                  className="tmx-expertProfileDetails__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
                    dispatch(shouldFetchLists());
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Discard' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-expertProfileDetails__formInlineWrapper">
                <div className="tmx-expertProfileDetails__formInline space">
                  <div className="tmx-expertProfileDetails__formTitle">External Profile Links</div>
                  <div className="tmx-expertProfileDetails__formDetails">
                    Journalists can link out of TMX to directly access external links for each
                    expert.
                  </div>
                </div>
                <div className="tmx-expertProfileDetails__inputGrid">
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.website}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['website'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Website'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.facebook}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['facebook'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Facebook'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.twitter}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['twitter'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Twitter'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.linkedin}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['linkedin'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'LinkedIn'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.instagram}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['instagram'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'Instagram'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.youtube}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['youtube'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'YouTube'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.links?.substack}
                        onChange={(event) => {
                          const copy = { ...organization };
                          copy.links['substack'] = event.target.value;
                          setOrganization(copy);
                          setEditedData(organization);
                        }}
                        type="text"
                        label={'SubStack'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={!edit}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="tmx-expertProfileDetails__organizationInfo">
              <div className="tmx-expertProfileDetails__orgSettingsHeader">
                <h1>Previous Media Appearances</h1>
                <Button
                  className="tmx-expertProfileDetails__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
                    dispatch(shouldFetchLists());
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Discard' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-expertProfileDetails__formInlineWrapper">
                <div className="tmx-expertProfileDetails__formInline space">
                  <div className="tmx-expertProfileDetails__formTitle">Media Appearances</div>
                  <div className="tmx-expertProfileDetails__formDetails">
                    Upload a video to feature on the profile page, with additional links to any
                    other media appearances to follow.
                  </div>
                </div>
                <div className="tmx-expertProfileDetails__inputGrid">
                  {!loading && !loadingMulti ? (
                    <div className="tmx-expertProfileDetails__newsroomLabel">
                      <FileUpload
                        //   setThumbNailList={setThumbNailList}
                        //   thumbNailList={thumbNailList}
                        view={'content'}
                        profilePhotoVideoPresent={!!organization.video_file}
                        isUploadingImage={false}
                        onChange={(uploads) => {
                          if (uploads.length > 0) {
                            setOrganization({ ...organization, video_file: uploads[0].key });
                            setEditedData({ ...organization, video_file: uploads[0].key });
                          } else {
                            setOrganization({
                              ...organization,
                              video_file: originalData.video_file
                            });
                            setEditedData({
                              ...organization,
                              video_file: originalData.video_file
                            });
                          }
                        }}
                        diabled={!edit}
                        page={'ExpertProfileDetails'}
                      />
                    </div>
                  ) : null}

                  <div className={classNames('tmx-uploadForm__credits')}>
                    <div className="tmx-expertProfileDetails__urlLable">
                      Link to Other Media Appearances
                    </div>
                    {organization?.other_media?.map((url, index) => (
                      <div
                        className="tmx-uploadForm__graphicContent tmx-uploadForm__URLField"
                        key={index}>
                        <div className="tmx-uploadForm__creatorDescription tmx-uploadForm__postURL">
                          <TextArea
                            id="postUrl"
                            maxRows={30}
                            onChange={(e) => handleURLChange(index, e)}
                            darkBorder
                            placeholder={'Enter a link to any previous media appearances'}
                            value={url}
                            statusBar
                            // maxCharacters={100000}
                            label="Enter url where content appeared"
                            htmlFor={'Link to original post'}
                            disabled={!edit}
                          />
                          {index > 0 ? (
                            <Delete
                              disabled={!edit}
                              className={
                                !edit
                                  ? 'tmx-uploadForm__postURL--disable'
                                  : 'tmx-uploadForm__postURL--delete'
                              }
                              onClick={() => {
                                if (edit) {
                                  removeURLField(index);
                                }
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}

                    <button
                      disabled={!edit}
                      className="tmx-uploadForm__URL"
                      onClick={addNewURLField}>
                      <Icon name="linked-blk" className="tmx-uploadForm__iconAttach" />
                      <span>Add another URL</span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <section className="tmx-expertProfileDetails__organizationInfo">
              <div className="tmx-expertProfileDetails__orgSettingsHeader">
                <h1>Organization</h1>
                <Button
                  className="tmx-expertProfileDetails__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ profile_id: profile_id }, '', '', 'get_profile_details'));
                    dispatch(shouldFetchLists());
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Discard' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-expertProfileDetails__formInlineWrapper">
                <div className="tmx-expertProfileDetails__formInline space">
                  <div className="tmx-expertProfileDetails__formTitle">
                    Org this profile belongs too
                  </div>
                  <div className="tmx-expertProfileDetails__formDetails">
                    Choose an Access Org to add this profile too.
                  </div>
                </div>
                <div className="tmx-expertProfileDetails__inputGrid">
                  <div
                    className={classNames('tmx-expertProfileDetails__formInline', {
                      'tmx-expertProfileDetails__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="organizationName"
                        name="organizationName"
                        darkBorder
                        value={organization?.company_name}
                        // onChange={(event) => {
                        //   const copy = { ...organization };
                        //   copy.links['website'] = event.target.value;
                        //   setOrganization(copy);
                        //   setEditedData(organization);
                        // }}
                        type="text"
                        label={'Profile belongs to this Org'}
                        labelClassName="tmx-expertProfileDetails__uploadFormLabel"
                        htmlFor={'organizationName'}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>

            {error && <p className="tmx-expertProfileDetails__error">Something went wrong</p>}
            {success && <p className="tmx-expertProfileDetails__success">Saved successful</p>}
            <Button
              type="button"
              className="tmx-expertProfileDetails__saveBtn"
              spinner={editLoading}
              disabled={
                (editLoading || !editedData || loadingMulti) && organization?.status !== 'PENDING'
              }
              handleClick={(event) => {
                event.preventDefault();
                handleEditProfile(true);
              }}
              buttonType="blue">
              {organization?.status === 'PENDING' ? 'Approve and Publish Profile' : 'Save Changes'}
            </Button>
            {organization?.status === 'PENDING' ? (
              <Button
                type="button"
                className="tmx-expertProfileDetails__saveBtn"
                spinner={editLoading}
                disabled={
                  (editLoading || !editedData || loadingMulti) && organization?.status !== 'PENDING'
                }
                handleClick={(event) => {
                  event.preventDefault();
                  handleEditProfile(false);
                }}
                buttonType="blue">
                {organization?.status === 'PENDING' ? 'Reject Profile' : ''}
              </Button>
            ) : null}
            <Button
              type="button"
              className="tmx-expertProfileDetails__saveBtn"
              handleClick={() => {
                window.open('https://usetmx.com/profile?id=' + profile_id, '_blank');
              }}
              buttonType="blue">
              Open Profile
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default ExpertProfileDetails;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './KPIDashboard.scss';

import { emdbedQuickSight } from '../../api/actions/org-actions';

const KPIDashboard = () => {
  const dispatch = useDispatch();
  const { quickSight } = useSelector((state) => state.orgReducer);

  useEffect(() => {
    dispatch(emdbedQuickSight());
  }, [dispatch]);

  return (
    <section className="tmx-dashboard">
      <div className="tmx-dashboard__overallContainer">
        <h3 className="tmx-dashboard__title">Executive Summary</h3>
        <iframe
          src={quickSight?.executive_summary}
          className="tmx-dashboard__iframe"
          title="dashbord"
        />

        <h3 className="tmx-dashboard__title tmx-dashboard__title--titleBelow">
          Additional Performance Indicators
        </h3>

        <iframe
          src={quickSight?.additional_performance_indicators}
          className="tmx-dashboard__iframe"
          title="dashbord"
        />
      </div>
    </section>
  );
};

export default KPIDashboard;

import React from 'react';
import PropTypes from 'prop-types';

import './ContactForm.scss';

import TextArea from '../../atoms/TextArea/TextArea';
import DropDown from '../../atoms/DropDown/DropDown';
import Button from '../../atoms/Button/Button';

import { ReactComponent as Add } from '../../../images/plusIcon.svg';
import { ReactComponent as Delete } from '../../../images/delete.svg';

const ContactForm = ({
  contactField,
  handleBasicContactChange,
  handleAddNewField,
  handleContactDetailChange,
  handleRemoveField,
  index1,
  view
}) => {
  return (
    <div className="tmx-contactContainer">
      <div className="tmx-contactInput">
        <TextArea
          id="contactTitle"
          maxRows={3}
          onChange={(e) => {
            handleBasicContactChange(index1, e);
          }}
          darkBorder
          placeholder="Examples: media contact, publicist, manager, etc."
          name="contactTitle"
          value={contactField.contactTitle}
          statusBar
          field={'required'}
          maxCharacters={100}
          label="Contact title"
          htmlFor="contactTitle"
        />
      </div>

      <div className="tmx-contactInput">
        <TextArea
          id="title"
          maxRows={3}
          onChange={(e) => {
            handleBasicContactChange(index1, e);
          }}
          darkBorder
          placeholder="First and last name"
          name="contactName"
          value={contactField.contactName}
          statusBar
          field={'required'}
          maxCharacters={100}
          label="Contact name"
          htmlFor="Contact name"
        />
      </div>

      <div className="tmx-contactForm__contactData">
        {contactField.contactDetails.map((contactDetail, index2) => (
          <div className="tmx-contactForm" key={index2}>
            <div className="tmx-contactForm_email">
              <div className="tmx-contactForm_label">
                Type <sup>*</sup>
              </div>
              <DropDown
                onChange={(event) => {
                  handleContactDetailChange(index1, index2, event, 'contactFieldTypes');
                }}
                optionSelected={contactDetail.contactFieldTypes}
                name="Type"
                className="tmx-contactForm__orgDropDown grey"
                data={
                  view === 'contact'
                    ? [
                        'Cell',
                        'Likely Cell',
                        'Office',
                        'Direct Office',
                        'Media Line',
                        'Home',
                        'On-call PIO',
                        'Email',
                        'Personal Email',
                        'Press Email',
                        'General Email',
                        'Note'
                      ]
                    : ['Cell', 'Office', 'Direct Office', 'Email', 'General Email', 'Note']
                }
              />
            </div>

            <div className="textInput">
              <div className="number_notes">
                <TextArea
                  id="title"
                  className={'tmx-contactForm__contactInfo'}
                  maxRows={3}
                  name="contactFieldData"
                  onChange={(event) => {
                    handleContactDetailChange(index1, index2, event, 'contactFieldData');
                  }}
                  darkBorder
                  value={contactDetail.contactFieldData}
                  statusBar
                  // field={'required'}
                  maxCharacters={500}
                  label="Contact info (email, phone, etc.)"
                  htmlFor="Number/Notes"
                />
                {index2 > 0 ? (
                  <Button handleClick={() => handleRemoveField(index1, index2)}>
                    <Delete />
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Button handleClick={() => handleAddNewField(index1)} className="add_field">
        <Add /> Add new field
      </Button>
    </div>
  );
};

ContactForm.propTypes = {
  contactField: PropTypes.object,
  handleBasicContactChange: PropTypes.func,
  handleAddNewField: PropTypes.func,
  handleContactDetailChange: PropTypes.func,
  handleRemoveField: PropTypes.func,
  index1: PropTypes.any,
  view: PropTypes.string
};
export default ContactForm;

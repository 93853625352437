export const LOG_OUT = 'LOG_OUT';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const RECEIVE_LOGIN_ERROR = 'RECEIVE_LOGIN_ERROR';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RESET_SIGN_IN_ERROR = 'RESET_SIGN_IN_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const ADMIN_ORGS_LOADING = 'ADMIN_ORGS_LOADING';
export const ADMIN_ORGS_RESPONSE = 'ADMIN_ORGS_RESPONSE';
export const ADMIN_ORGS_ERROR = 'ADMIN_ORGS_ERROR';
export const EMBED_QUICKSIGHT_LOADING = 'EMBED_QUICKSIGHT_LOADING';
export const EMBED_QUICKSIGHT_RESPONSE = 'EMBED_QUICKSIGHT_RESPONSE';
export const EMBED_QUICKSIGHT_ERROR = 'EMBED_QUICKSIGHT_ERROR';
export const SENDING_INVITATIONS = 'SENDING_INVITATIONS';
export const INVITES_SENT_ERROR = 'INVITES_SENT_ERROR';
export const INVITES_SENT_SUCCESS = 'INVITES_SENT_SUCCESS';
export const NUMBER_OF_INVITES = 'NUMBER_OF_INVITES';
export const RESET_INVITES_SENT_SUCCESS = 'RESET_INVITES_SENT_SUCCESS';
export const EDIT_ORG_LOADING = 'EDIT_ORG_LOADING';
export const EDIT_ORG_RESPONSE = 'EDIT_ORG_RESPONSE';
export const INVITE_LOADING = 'INVITE_LOADING';
export const INVITE_ERROR = 'INVITE_ERROR';
export const ADMIN_ORG_RESPONSE = 'ADMIN_ORG_RESPONSE';
export const RECEIVE_ALL_MULTI_SELECT_LISTS = 'RECEIVE_ALL_MULTI_SELECT_LISTS';
export const LOADING_MULTISELECT_LIST = 'LOADING_MULTISELECT_LIST';
export const CREATE_ADMIN_ORG_ERROR = 'CREATE_ADMIN_ORG_ERROR';
export const CLEAR_CREATE_ADMIN_ORG_ERROR = 'CLEAR_CREATE_ADMIN_ORG_ERROR';
export const PAYMENT_STATUS_LOADING = 'PAYMENT_STATUS_LOADING';
export const PAYMENT_STATUS_ERROR = 'PAYMENT_STATUS_ERROR';
export const NEWSROOM_PAYMENT_STATUS_RESPONSE = 'NEWSROOM_PAYMENT_STATUS_RESPONSE';
export const NEWSROOM_PAYMENT_STATUS_LOADING = 'NEWSROOM_PAYMENT_STATUS_LOADING';
export const NEWSROOM_PAYMENT_STATUS_ERROR = 'NEWSROOM_PAYMENT_STATUS_ERROR';
export const PAYMENT_STATUS_RESPONSE = 'PAYMENT_STATUS_RESPONSE';
export const EDIT_MEMBERSHIP_LOADING = 'EDIT_MEMBERSHIP_LOADING';
export const EDIT_MEMBERSHIP_RESPONSE = 'EDIT_MEMBERSHIP_RESPONSE';
export const EDIT_MEMBERSHIP_ERROR = 'EDIT_MEMBERSHIP_ERROR';
export const PAYMENT_INVITE_LOADING = 'PAYMENT_INVITE_LOADING';
export const PAYMENT_INVITE_ERROR = 'PAYMENT_INVITE_ERROR';
export const PAYMENT_INVITE_RESPONSE = 'PAYMENT_INVITE_RESPONSE';
export const VERIFY_BANK_RESPONSE = 'VERIFY_BANK_RESPONSE';
export const VERIFY_BANK_ERROR = 'VERIFY_BANK_ERROR';
export const VERIFY_BANK_LOADING = 'VERIFY_BANK_LOADING';
export const RECEIVE_LOCATIONS_NEWSROOMS = 'RECEIVE_LOCATIONS_NEWSROOMS';
export const SENT_CONTENT_EMAIL_SUCCESS = 'SENT_CONTENT_EMAIL_SUCCESS';
export const SENT_CONTENT_EMAIL_ERROR = 'SENT_CONTENT_EMAIL_ERROR';
export const SENDING_EMAIL_DATA = 'SENDING_EMAIL_DATA';
export const RESET_INITAL_STATE = 'RESET_INITAL_STATE';
export const GET_SELLERS = 'GET_SELLERS';
export const RECEIVE_SELLERS = 'RECEIVE_SELLERS';
export const GET_SELLER = 'GET_SELLER';
export const RECEIVE_SELLER = 'RECEIVE_SELLER';
export const GET_SELLERS_ERROR = 'GET_SELLERS_ERROR';
export const GET_SELLER_ERROR = 'GET_SELLER_ERROR';
export const CREATE_SELLER = 'CREATE_SELLER';
export const CREATE_SELLER_ERROR = 'CREATE_SELLER_ERROR';
export const SEND_SELLER_ACCOUNT_INVITE = 'SEND_SELLER_ACCOUNT_INVITE';
export const COMPLETE_SELLER_ACCOUNT_INVITE = 'COMPLETE_SELLER_ACCOUNT_INVITE';
export const GET_SELLER_MEDIA_DETAILS = 'GET_SELLER_MEDIA_DETAILS';
export const GET_SELLER_MEDIA_DETAILS_ERROR = 'GET_SELLER_MEDIA_DETAILS_ERROR';
export const RECEIVE_SELLER_MEDIA_DETAILS = 'RECEIVE_SELLER_MEDIA_DETAILS';
export const GET_CAROUSEL = 'GET_CAROUSEL';
export const RECEIVE_CAROUSEL = 'RECEIVE_CAROUSEL';
export const GET_CAROUSEL_ERROR = 'GET_CAROUSEL_ERROR';
export const GET_EXPERTLIST = 'GET_EXPERTLIST';
export const RECEIVE_EXPERTLIST = 'RECEIVE_EXPERTLIST';
export const GET_EXPERTLIST_ERROR = 'GET_EXPERTLIST_ERROR';
export const GET_EMAIL_ALERT_ITEMS = 'GET_EMAIL_ALERT_ITEMS';
export const GET_EMAIL_ALERTS_ERROR = 'GET_EMAIL_ALERTS_ERROR';
export const RECEIVE_EMAIL_ALERT_ITEMS = 'RECEIVE_EMAIL_ALERT_ITEMS';

//UNIVERSAL UPLOADER
export const IS_UPLOADING = 'IS_UPLOADING';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const MEDIA_ID = 'MEDIA_ID';
export const LOADING_DETAILS = 'LOADING_DETAILS';
export const SET_CONTENT_DETAILS = 'SET_CONTENT_DETAILS';
//UNIVERSAL UPLOADER

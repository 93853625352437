import React from 'react';

export const inviteContext = React.createContext({
  dispatch: null,
  closeModal: null,
  sendingInviteLoading: false,
  invitesSentSuccess: false,
  numberOfInvites: 0,
  invitesSentError: false,
  companyId: null
});

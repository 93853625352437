import { IS_UPLOADING, UPLOAD_SUCCESS, UPLOAD_FAILURE, MEDIA_ID, LOADING_DETAILS, SET_CONTENT_DETAILS } from '../../constants/action-types';

const initialState = {
  isLoading: false,
  uploadSuccess: false,
  uploadFailure: false,
  media_id: null,
  loadingDetails: false,
  contentDetails: {}
};

export default function universalUploader(state = initialState, action) {
  switch (action.type) {
    case IS_UPLOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case UPLOAD_FAILURE:
      return {
        ...state,
        uploadFailure: action.payload
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadSuccess: action.payload
      };
    case MEDIA_ID:
      return{
        ...state,
        media_id: action.payload
      };
    case LOADING_DETAILS:
      return{
        ...state,
        loadingDetails: action.payload
      };
    case SET_CONTENT_DETAILS:
      return{
        ...state,
        contentDetails: action.payload
      }

    default:
      return state;
  }
}

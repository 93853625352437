import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import './SellerDetails.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import Icon from '../../components/atoms/Icon/Icon';
import useScrollDisabling from '../../functionality/Hooks/rootComponentScroll';
import DropDown from '../../components/atoms/DropDown/DropDown';

import {
  getNonMemberSellersDetails,
  createNonMemberSeller,
  sendBankAccountInvite,
  addNewAssociateContent,
  addRevenueShare
} from '../../api/actions/sellers-actions';

const SellerDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { loadingDetails, sellerDetails, sendingBankInvite } = useSelector(
    (state) => state.sellersReducer
  );

  const dispatch = useDispatch();

  const { setDisabled } = useScrollDisabling(false);
  const [addAssociateContent, setAddAssociateContent] = useState(false);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState(null);
  const [addAssociateContentForm, setAddAssociateContentForm] = useState({});
  const [addingContent, setAddingContent] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaRecords, setMediaRecords] = useState([
    { source: null, media_id: null, title: null },
    { source: null, media_id: null, title: null },
    { source: null, media_id: null, title: null },
    { source: null, media_id: null, title: null }
  ]);
  const [seller, setSeller] = useState({});

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    if (sellerDetails.media_records) {
      setMediaRecords(sellerDetails.media_records);
    }
  }, [sellerDetails.media_records]);

  useEffect(() => {
    setDisabled(document.getElementsByClassName('tmx-modal')[0]);
  });

  useEffect(() => {
    if (location?.state?.sellerId) {
      dispatch(getNonMemberSellersDetails({ seller_id: location?.state?.sellerId }));
    }
  }, [dispatch, location]);

  const sortMediaRecords = (by) => {
    setOrderBy(by);
    setOrder(order === -1 ? 1 : -1);

    if (by === 'title' && order === -1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.title > b.title ? -1 : 1)));
    } else if (by === 'title' && order === 1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.title > b.title ? 1 : -1)));
    } else if (by === 'contentId' && order === -1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.media_id > b.media_id ? -1 : 1)));
    } else if (by === 'contentId' && order === 1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.media_id > b.media_id ? 1 : -1)));
    } else if (by === 'source' && order === -1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.source > b.source ? -1 : 1)));
    } else if (by === 'source' && order === 1) {
      setMediaRecords([...mediaRecords].sort((a, b) => (a.source > b.source ? 1 : -1)));
    }
  };

  return (
    <>
      <div className="tmx-sellerDetail__backButtonContainer">
        <div
          className={classNames('tmx-sellerDetail__backBtnContainer', {
            'tmx-sellerDetail__backBtnContainer--loading': loadingDetails
          })}>
          {!loadingDetails && (
            <Button
              buttonIcon="chevron"
              iconSize="medium"
              className="tmx-sellerDetail__backContent black"
              handleClick={() => history.push('/non-member-sellers')}>
              {sellerDetails.stripe_account_id ? 'Back to Search' : 'Cancel and return to Search'}
            </Button>
          )}
        </div>
      </div>
      <section className="tmx-sellerDetail">
        <section className="tmx-sellerDetail__organizationInfo">
          <div className="tmx-sellerDetail__orgSettingsHeader">
            <h1>Seller Account Information</h1>
            {!loadingDetails && !loading && (
              <Button
                className="tmx-sellerDetail__createButton"
                handleClick={() => {
                  if (!sellerDetails.stripe_account_id) {
                    dispatch(createNonMemberSeller(seller));
                  } else if (!edit || (edit && !formValues.revenue_share)) {
                    setEdit(!edit);
                  } else if (edit) {
                    setLoading(true);
                    dispatch(
                      addRevenueShare(
                        {
                          revenue_share: parseInt(formValues.revenue_share),
                          seller_id: sellerDetails.sellerId
                        },
                        () => {
                          setEdit(false);
                          setLoading(false);
                          dispatch(
                            getNonMemberSellersDetails({ seller_id: sellerDetails.sellerId })
                          );
                        }
                      )
                    );
                  }
                }}
                disabled={
                  (!sellerDetails.stripe_account_id &&
                    (!seller.seller_name || !seller.seller_email)) ||
                  loading
                }>
                {!sellerDetails.stripe_account_id
                  ? 'Create Seller'
                  : !edit
                  ? 'Edit'
                  : edit && !formValues.revenue_share
                  ? 'Cancel'
                  : 'Save'}
              </Button>
            )}
          </div>
          <div className="tmx-sellerDetail__formInlineWrapper">
            <div className="tmx-sellerDetail__formInline space">
              <div className="tmx-sellerDetail__formTitle">Name</div>
              <div className="tmx-sellerDetail__formDetails">Actual name of content seller. </div>
            </div>
            <div
              className={classNames('tmx-sellerDetail__formInline', {
                'tmx-sellerDetail__formInline--loading': loadingDetails
              })}>
              {!loadingDetails && (
                <Input
                  id="sellerName"
                  name="sellerName"
                  darkBorder
                  value={seller.seller_name || sellerDetails.seller_name}
                  onChange={(event) => {
                    setSeller({ ...seller, seller_name: event.target.value });
                  }}
                  type="text"
                  label={'Seller Name'}
                  htmlFor={'sellerName'}
                  disabled={!!sellerDetails.stripe_account_id}
                />
              )}
            </div>
          </div>

          <div className="tmx-sellerDetail__formInlineWrapper">
            <div className="tmx-sellerDetail__formInline space">
              <div className="tmx-sellerDetail__formTitle">Email</div>
              <div className="tmx-sellerDetail__formDetails">
                Deposit receipts will go to this email address.{' '}
              </div>
            </div>
            <div
              className={classNames('tmx-sellerDetail__formInline', {
                'tmx-sellerDetail__formInline--loading': loadingDetails
              })}>
              {!loadingDetails && (
                <Input
                  id="sellerEmail"
                  name="sellerEmail"
                  darkBorder
                  value={seller.seller_email || sellerDetails.seller_email}
                  onChange={(event) => {
                    setSeller({ ...seller, seller_email: event.target.value });
                  }}
                  type="text"
                  label={'Seller Email'}
                  htmlFor={'sellerEmail'}
                  disabled={!!sellerDetails.stripe_account_id}
                />
              )}
            </div>
          </div>

          <div className="tmx-sellerDetail__accountInfo">
            <h1>Receiving account information</h1>

            <>
              <p>
                {' '}
                Status:{' '}
                <b>
                  {sellerDetails.stripe_account_id &&
                  sellerDetails.stripe_account_id !== 'No Stripe Account'
                    ? 'Receiving account on file'
                    : 'No account on file'}
                </b>{' '}
              </p>
              {sellerDetails.stripe_account_id &&
                sellerDetails.stripe_account_id !== 'No Stripe Account' && (
                  <p>
                    Stripe ID: <b>{sellerDetails.stripe_account_id}</b>
                  </p>
                )}
            </>
            {!loadingDetails && (
              <Button
                type="button"
                buttonIcon={!sendingBankInvite && 'envelope'}
                iconSize="micro"
                className="tmx-sellerDetail__btn"
                spinner={sendingBankInvite}
                disabled={
                  (!loadingDetails && !sellerDetails.stripe_account_id) || sendingBankInvite
                }
                handleClick={(event) => {
                  event.preventDefault();
                  dispatch(sendBankAccountInvite({ seller_id: sellerDetails.sellerId }));
                }}
                buttonType="blue">
                {sellerDetails.stripe_account_id &&
                sellerDetails.stripe_account_id !== 'No Stripe Account'
                  ? 'Resend Link'
                  : 'Send account request'}
              </Button>
            )}
          </div>
          {sellerDetails.stripe_account_id && (
            <div className="tmx-sellerDetail__formInlineWrapper">
              <div className="tmx-sellerDetail__formInline space">
                <div className="tmx-sellerDetail__formTitle">Rev share</div>
                <div className="tmx-sellerDetail__formDetails">
                  Seller will receive this percentage as a default when their content is purchase.{' '}
                </div>
              </div>
              <div
                className={classNames('tmx-sellerDetail__formInline', {
                  'tmx-sellerDetail__formInline--loading': loadingDetails || loading
                })}>
                {!loadingDetails && !loading && (
                  <Input
                    id="revShare"
                    name="revShare"
                    darkBorder
                    value={`${
                      edit ? formValues.revenue_share || '' : sellerDetails.revenue_share
                    }%`}
                    onChange={(event) => {
                      setFormValues({
                        ...formValues,
                        revenue_share: event.target.value.replace('%', '')
                      });
                    }}
                    type="text"
                    label={'Seller receives'}
                    htmlFor={'revShare'}
                    disabled={!edit || loading}
                  />
                )}
              </div>
            </div>
          )}
        </section>
        {(location?.state?.sellerId || sellerDetails.sellerId) && (
          <section className="tmx-sellerDetail__userSettings">
            <div className="tmx-sellerDetail__orgSettingsHeader">
              <h1>Associated Content</h1>{' '}
            </div>
            <div className="tmx-sellerDetail__mediaContainer">
              <div className="tmx-sellerDetail__mediaContainerName header">
                ContentId{' '}
                <Icon
                  name={
                    orderBy === 'contentId' && order === 1
                      ? 'sort-up'
                      : orderBy === 'contentId' && order === -1
                      ? 'sort-down'
                      : 'sort-default'
                  }
                  size="micro"
                  className="tmx-sellerDetail__sortIcon"
                  onClick={() => sortMediaRecords('contentId')}
                />
              </div>
              <div className="tmx-sellerDetail__mediaContainerEmail header">
                Source{' '}
                <Icon
                  name={
                    orderBy === 'source' && order === 1
                      ? 'sort-up'
                      : orderBy === 'source' && order === -1
                      ? 'sort-down'
                      : 'sort-default'
                  }
                  size="micro"
                  className="tmx-sellerDetail__sortIcon"
                  onClick={() => sortMediaRecords('source')}
                />
              </div>

              <div className="tmx-sellerDetail__mediaContainerEmail header">
                Title{' '}
                <Icon
                  name={
                    orderBy === 'title' && order === 1
                      ? 'sort-up'
                      : orderBy === 'title' && order === -1
                      ? 'sort-down'
                      : 'sort-default'
                  }
                  size="micro"
                  className="tmx-sellerDetail__sortIcon"
                  onClick={() => sortMediaRecords('title')}
                />
              </div>
              {/* <div className="tmx-sellerDetail__mediaContainerEmail header">
            Unpaid{' '}
            <Icon
              name={
                orderBy === 'title' && order === 1
                  ? 'sort-up'
                  : orderBy === 'title' && order === -1
                  ? 'sort-down'
                  : 'sort-default'
              }
              size="micro"
              className="tmx-sellerDetail__sortIcon"
              onClick={() => sortMediaRecords('title')}
            />
          </div> */}
            </div>
            <div className="tmx-sellerDetail__media">
              {mediaRecords.map((media, key) => (
                <div
                  className={classNames('tmx-sellerDetail__mediaContainer', {
                    'tmx-sellerDetail__mediaContainer--loading': loadingDetails
                  })}
                  key={key}>
                  {!loadingDetails && (
                    <>
                      <div
                        className={classNames('tmx-sellerDetail__mediaContainerName', {
                          'tmx-sellerDetail__mediaContainerName--bold': media.media_id
                        })}>
                        {media.media_id}
                      </div>
                      <div className="tmx-sellerDetail__mediaContainerEmail">{media.source}</div>
                      <div className="tmx-sellerDetail__mediaContainerEmail">{media.title}</div>
                      {/* <div className="tmx-sellerDetail__mediaContainerEmail">{media.unpaid}</div> */}
                      <div className="tmx-sellerDetail__mediaContainerAction">
                        <Button
                          type="button"
                          handleClick={() =>
                            history.push('/seller-media-details', {
                              sellerId: sellerDetails?.sellerId,
                              mediaId: media.media_id,
                              source: media.source
                            })
                          }>
                          Settings
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ))}

              {addAssociateContent && (
                <div className="tmx-sellerDetail__mediaContainer priceOverrides">
                  <div>
                    <Input
                      id="media_id"
                      name="media_id"
                      darkBorder
                      value={addAssociateContentForm.media_id}
                      onChange={(event) => {
                        setAddAssociateContentForm({
                          ...addAssociateContentForm,
                          media_id: event.target.value
                        });
                      }}
                      type="text"
                      label={'Content Id'}
                      htmlFor={'media_id'}
                      disabled={addingContent}
                    />
                  </div>
                  <div className="tmx-sellerDetail__priceInputContainer">
                    <div className="tmx-sellerDetail__dropDownContainer">
                      <label className="tmx-input__uploadFormLabel" htmlFor="source">
                        Source
                      </label>
                      <DropDown
                        onChange={(event) => {
                          setAddAssociateContentForm({
                            ...addAssociateContentForm,
                            source: event.target.value
                          });
                        }}
                        optionSelected={addAssociateContentForm.source || 'CONTACT'}
                        className="tmx-sellerDetail__dropDown"
                        data={['CONTACT', 'EXCHANGE']}
                        id="source"
                        disabled={addingContent}
                      />
                    </div>
                    <Button
                      buttonType="blue"
                      className="tmx-sellerDetail__btn"
                      spinner={addingContent}
                      handleClick={() => {
                        setAddingContent(true);
                        dispatch(
                          addNewAssociateContent(
                            {
                              seller_id: location?.state?.sellerId,
                              media_id: addAssociateContentForm.media_id,
                              source: addAssociateContentForm.source
                            },
                            () => {
                              setAddingContent(false);
                              setAddAssociateContentForm({});
                              setTimeout(() => {
                                setAddAssociateContent(false);
                              }, 1000);
                            }
                          )
                        );
                      }}
                      disabled={addingContent}>
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="tmx-sellerDetail__mediaContainerInviteBtn">
              <Button
                buttonType="blue"
                className="secondary tmx-sellerDetail__btn"
                buttonIcon={!addAssociateContent ? 'plus' : ''}
                handleClick={() => setAddAssociateContent(!addAssociateContent)}
                disabled={!sellerDetails.stripe_account_id}>
                {addAssociateContent ? 'Cancel' : 'Add content'}
              </Button>
            </div>
          </section>
        )}
      </section>
    </>
  );
};

export default SellerDetails;

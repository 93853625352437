import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import './MarketingCarousel.scss';

import { marketingCarousel } from '../../api/actions/tools-actions';
import SliderContent from '../../components/atoms/SliderContent/SliderContent';
import DropDown from '../../components/atoms/DropDown/DropDown';
import useDetectDevice from '../../functionality/Hooks/detectDevice';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';

const MarketingCarousel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAddCarousel, setShowAddCarousel] = useState(false);
  const [updatingCarousel, setUpdatingCarousel] = useState(false);
  const [contentType, setContentType] = useState('CONTACT');
  const [formValues, setFormValues] = useState({ category: 'Featured', contentUrl: '' });
  const isMobile = useDetectDevice(768);
  const isSmallSmallDesktop = useDetectDevice(1125);
  const isMediumSmallDesktop = useDetectDevice(1189);
  const isLargeSmallDesktop = useDetectDevice(1270);
  const isSmallMediumDesktop = useDetectDevice(1349);
  const isMediumMediumDesktop = useDetectDevice(1457);
  const isLargeMediumDesktop = useDetectDevice(1565);
  const isSmallLargeDesktop = useDetectDevice(1692);
  const isMediumLargeDesktop = useDetectDevice(1850);
  const isLargeDesktop = useDetectDevice(2004);

  const [carousels, setCarousels] = useState([
    { brick_id: null, title: null, content_type: null },
    { brick_id: null, title: null, content_type: null },
    { brick_id: null, title: null, content_type: null }
  ]);

  const { marketingCarousels, loading } = useSelector((state) => state.toolsReducer);
  useEffect(() => {
    dispatch(marketingCarousel({ content_type: contentType }));
  }, [dispatch, contentType]);

  useEffect(() => {
    if (marketingCarousels.length) {
      setCarousels(marketingCarousels);
    }
  }, [marketingCarousels]);

  return (
    <>
      <div className="tmx-marketingCarousel__backButtonContainer">
        <div
          className={classNames('tmx-marketingCarousel__backBtnContainer', {
            'tmx-marketingCarousel__backBtnContainer--loading': loading
          })}>
          {!loading && (
            <Button
              buttonIcon="arrow-right"
              iconSize="ginormous"
              className="tmx-marketingCarousel__backContent black"
              handleClick={() => history.push('/tools')}>
              Back
            </Button>
          )}
        </div>
      </div>
      <div className="tmx-marketingCarousel__previewContainer"></div>
      <div className="tmx-marketingCarousel__sliderSection">
        {loading ? (
          <Slider
            dots={true}
            infinite={true}
            centerMode={true}
            centerPadding={
              isMobile
                ? '8%'
                : isSmallSmallDesktop
                ? '12%'
                : isMediumSmallDesktop
                ? '14%'
                : isLargeSmallDesktop
                ? '16%'
                : isSmallMediumDesktop
                ? '18%'
                : isMediumMediumDesktop
                ? '20%'
                : isLargeMediumDesktop
                ? '22%'
                : isSmallLargeDesktop
                ? '24%'
                : isMediumLargeDesktop
                ? '26%'
                : isLargeDesktop
                ? '25%'
                : '25%'
            }
            slidesToShow={1}
            slidesToScroll={1}
            touchThreshold={1000}>
            <SliderContent loading={loading} />
            <SliderContent loading={loading} />
            <SliderContent loading={loading} />
          </Slider>
        ) : (
          <Slider
            dots={true}
            autoplay={true}
            infinite={true}
            centerMode={true}
            centerPadding={
              isMobile
                ? '8%'
                : isSmallSmallDesktop
                ? '12%'
                : isMediumSmallDesktop
                ? '14%'
                : isLargeSmallDesktop
                ? '16%'
                : isSmallMediumDesktop
                ? '18%'
                : isMediumMediumDesktop
                ? '20%'
                : isLargeMediumDesktop
                ? '22%'
                : isSmallLargeDesktop
                ? '24%'
                : isMediumLargeDesktop
                ? '26%'
                : isLargeDesktop
                ? '25%'
                : '25%'
            }
            autoplaySpeed={5000}
            slidesToShow={1}
            slidesToScroll={1}
            touchThreshold={1000}>
            {!loading
              ? marketingCarousels.map((data, index) => (
                  <div key={index}>
                    <SliderContent
                      key={index}
                      cleared={data.cleared}
                      contentType={data.content_type}
                      displayName={data.display_name}
                      featureType={data.feature_type}
                      isInternational={data.is_international}
                      location={data.location}
                      platform={data.platform}
                      thumbnail={data.thumbnail}
                      title={data.title}
                      urlId={data.url_id}
                      loading={loading}
                    />
                  </div>
                ))
              : null}
          </Slider>
        )}
      </div>

      <div className="tmx-marketingCarousel">
        <div className="tmx-marketingCarousel__header">
          <h1>Marketing Carousel</h1>{' '}
        </div>
        <section className="tmx-marketingCarousel__detailsInfo">
          <div className="tmx-marketingCarousel__dropDownContainer">
            <p>Choose Endpoint</p>
            <DropDown
              onChange={(event) => {
                const type =
                  event.target.value === 'TMX Inbox'
                    ? 'INBOX'
                    : event.target.value === 'SocialSpotter'
                    ? 'SCRAPER'
                    : event.target.value === 'TMX Marketplace'
                    ? 'NEWSROOM'
                    : event.target.value === 'Need an Expert?'
                    ? 'PITCHES'
                    : event.target.value === 'Story Contacts'
                    ? 'TOPSTORIES'
                    : event.target.value === 'Cleared Articles'
                    ? 'CLEAREDARTICLES'
                    : 'CONTACT';
                setContentType(type);
              }}
              optionSelected={
                contentType === 'INBOX'
                  ? 'TMX Inbox'
                  : contentType === 'CONTACT'
                  ? 'Cleared Videos'
                  : contentType === 'SCRAPER'
                  ? 'SocialSpotter'
                  : contentType === 'TOPSTORIES'
                  ? 'Story Contacts'
                  : contentType === 'PITCHES'
                  ? 'Need an Expert?'
                  : contentType === 'CLEAREDARTICLES'
                  ? 'Cleared Articles'
                  : 'TMX Marketplace'
              }
              className="tmx-marketingCarousel__dropDown grey"
              data={[
                'TMX Inbox',
                'Cleared Videos',
                'Cleared Articles',
                'TMX Marketplace',
                'SocialSpotter',
                'Need an Expert?',
                'Story Contacts'
              ]}
              id="endpoint"
              disabled={loading}
            />
          </div>

          <div className="tmx-marketingCarousel__mediaContainer headers">
            <div className="tmx-marketingCarousel__mediaContainerName header">Position </div>
            <div className="tmx-marketingCarousel__mediaSubContainer">
              <div className="tmx-marketingCarousel__mediaContainerTitle header">Title </div>

              <div className="tmx-marketingCarousel__mediaContainerCategory header">Category </div>

              <div className="tmx-marketingCarousel__mediaContainerTitle header">Action </div>
            </div>
          </div>
          <div className="tmx-marketingCarousel__media">
            {carousels.map((carousel, key) => (
              <div
                className={classNames('tmx-marketingCarousel__mediaContainer', {
                  'tmx-marketingCarousel__mediaContainer--loading': loading
                })}
                key={key}>
                {!loading && (
                  <>
                    <div
                      className={classNames('tmx-marketingCarousel__mediaContainerPosition', {
                        'tmx-marketingCarousel__mediaContainerPosition--bold': true
                      })}>
                      {key + 1}
                      <Button
                        handleClick={() => {
                          dispatch(
                            marketingCarousel({
                              move_brick_up: carousel.brick_id,
                              content_type: contentType
                            })
                          );
                        }}
                        disabled={key === 0}>
                        up
                      </Button>
                      <Button
                        handleClick={() => {
                          dispatch(
                            marketingCarousel({
                              move_brick_down: carousel.brick_id,
                              content_type: contentType
                            })
                          );
                        }}
                        disabled={key === carousels.length - 1}>
                        down
                      </Button>
                    </div>
                    <div className="tmx-marketingCarousel__mediaSubContainer">
                      <div className="tmx-marketingCarousel__mediaContainerTitle">
                        {carousel.title}
                      </div>
                      <div className="tmx-marketingCarousel__mediaContainerCategory">
                        {carousel.feature_type}
                      </div>
                      <div className="tmx-marketingCarousel__mediaContainerAction">
                        <div className="tmx-marketingCarousel__sortButtonsContainer">
                          <Button
                            className="tmx-marketingCarousel__UpSortBtn"
                            handleClick={() => {
                              dispatch(
                                marketingCarousel({
                                  move_brick_up: carousel.brick_id,
                                  content_type: contentType
                                })
                              );
                            }}
                            disabled={key === 0}>
                            up
                          </Button>
                          <Button
                            className="tmx-marketingCarousel__DownSortBtn"
                            handleClick={() => {
                              dispatch(
                                marketingCarousel({
                                  move_brick_down: carousel.brick_id,
                                  content_type: contentType
                                })
                              );
                            }}
                            disabled={key === carousels.length - 1}>
                            down
                          </Button>
                        </div>
                        <Button
                          type="button"
                          className={`tmx-marketingCarousel__deleteBrick`}
                          handleClick={() => {
                            dispatch(
                              marketingCarousel({
                                delete_brick: carousel.brick_id,
                                content_type: contentType
                              })
                            );
                          }}
                          disabled={loading}>
                          Delete
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}

            {showAddCarousel && (
              <div className="tmx-marketingCarousel__mediaContainer newCarousel">
                <p
                  className={classNames('tmx-marketingCarousel__mediaContainerPosition', {
                    'tmx-marketingCarousel__mediaContainerPosition--bold': true
                  })}>
                  {carousels.length + 1}
                </p>
                <div className="tmx-marketingCarousel__mediaSubContainer addNewCarousel">
                  <div>
                    <Input
                      id="contentUrl"
                      className="tmx-marketingCarousel__contentUrlInput"
                      labelClassName="tmx-marketingCarousel__contentURLInputLabel"
                      name="contentUrl"
                      darkBorder
                      value={formValues.contentUrl}
                      onChange={(event) => {
                        setFormValues({ ...formValues, contentUrl: event.target.value });
                      }}
                      type="text"
                      label={'Content URL'}
                      htmlFor={'contentUrl'}
                      disabled={loading || updatingCarousel}
                    />
                  </div>

                  <div className="tmx-marketingCarousel__updateContentBtnContainer">
                    <Button
                      className="tmx-marketingCarousel__updateContentBtn"
                      buttonIcon={!updatingCarousel && 'plus-circled'}
                      iconSize="small"
                      spinner={updatingCarousel}
                      handleClick={() => {
                        setUpdatingCarousel(true);
                        dispatch(
                          marketingCarousel(
                            { add_url: formValues.contentUrl, content_type: contentType },
                            () => {
                              setUpdatingCarousel(false);
                              setShowAddCarousel(false);
                              setFormValues({ category: 'Featured', contentUrl: '' });
                            }
                          )
                        );
                      }}
                      disabled={formValues.contentUrl === '' || loading || updatingCarousel}>
                      Save
                    </Button>
                    <Button
                      className="tmx-marketingCarousel__updateContentBtn"
                      buttonIcon="minus-circled"
                      iconSize="small"
                      handleClick={() => setShowAddCarousel(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="tmx-marketingCarousel__createCarouselBtn">
            {!showAddCarousel && (
              <Button
                className="tmx-marketingCarousel__btn"
                buttonIcon={!showAddCarousel && 'plus-circled'}
                handleClick={() => setShowAddCarousel(!showAddCarousel)}
                disabled={loading}>
                {isMobile ? 'New Brick' : 'Create New Brick'}
              </Button>
            )}

            <Button
              className="tmx-marketingCarousel__refreshButton"
              buttonIcon="refresh"
              iconSize="ginormous"
              handleClick={() => {
                dispatch(marketingCarousel({ content_type: contentType }));
              }}
              disabled={loading}>
              Refresh Preview
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default MarketingCarousel;

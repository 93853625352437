import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import TextArea from '../../components/atoms/TextArea/TextArea';

import './OrganizationDetails.scss';

import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import DropDown from '../../components/atoms/DropDown/DropDown';
import SlideToggle from '../../components/atoms/SlideToggle/SlideToggle.js';
import Icon from '../../components/atoms/Icon/Icon';
import useScrollDisabling from '../../functionality/Hooks/rootComponentScroll';
import useDetectDevice from '../../functionality/Hooks/detectDevice';

import {
  adminOrgs,
  editMembership,
  paymentStatus,
  sendPaymentInvite,
  setClearCreateAdminOrgError,
  shouldFetchLists,
  verifyBankAccount,
  sendNewsroomPayment
} from '../../api/actions/org-actions';
import { inviteContext } from '../../api/context/invite-context';
import { organizationContext } from '../../api/context/organization-context';

const Invitation = React.lazy(() => import('../../components/organisms/Invitation/Invitation'));
const OrganizationModal = React.lazy(() =>
  import('../../components/organisms/OrganizationModal/OrganizationModal')
);

const OrganizationDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    createError,
    editLoading,
    editMembershipLoading,
    error,
    inviteError,
    inviteLoading,
    invitesSentSuccess,
    loading,
    loadingMulti,
    locationsArray,
    numberOfInvites,
    organizations,
    paymentInviteError,
    paymentInviteLoading,
    paymentLoading,
    paymentStatusData,
    singleOrg,
    verifyBankError,
    verifyBankLoading,
    verifyError,
    paymentInviteErrorMsg,
    NewsroomPaymentSuccess,
    NewsroomPaymentLoading
  } = useSelector((state) => state.orgReducer);
  const { org } = singleOrg;
  const dispatch = useDispatch();
  const isMobile = useDetectDevice(430);
  const { setDisabled } = useScrollDisabling(false);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState(null);
  const [orgUsers, setOrgUsers] = useState([
    { user_name: null, user_email: null },
    { user_name: null, user_email: null },
    { user_name: null, user_email: null },
    { user_name: null, user_email: null }
  ]);
  const [dateInputType, setDateInputType] = useState('text');
  const [showInvite, setShowInvite] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [changeOwner, setChangeOwner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [organization, setOrganization] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [newsroomPayment, setNewsroomPayment] = useState({});
  const [editedData, setEditedData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [changeOwnerLoading, setChangeOwnerLoading] = useState(false);
  const [moveUserLoading, setMoveUserLoading] = useState(false);
  const [addOrgAndMoveUserLoading, setAddOrgAndMoveUserLoading] = useState(false);
  const [moveUserSuccess, setMoveUserSuccess] = useState(false);
  const [isPaymentInvite, setIsPaymentInvite] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [depositData, setDepositData] = useState(null);
  const [selectedUserID, setSelectedUserID] = useState('');
  const [email, setEmail] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [depositDollarData, setDepositDollarData] = useState({});
  const [membershipSuccess, setMembershipSuccess] = useState(false);
  const [save, setSaved] = useState(false);

  useEffect(() => {
    setDisabled(document.getElementsByClassName('tmx-modal')[0]);
  });

  useEffect(() => {
    dispatch(adminOrgs());
    dispatch(shouldFetchLists());
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
  }, [dispatch, location]);

  useEffect(() => {
    setPaymentData(paymentStatusData?.result);
  }, [paymentStatusData]);

  useEffect(() => {
    setNewsroomPayment(org);
  }, [org]);

  useEffect(() => {
    setSaved(NewsroomPaymentSuccess);
  }, [NewsroomPaymentSuccess]);

  useEffect(() => {
    dispatch(adminOrgs({ get: location?.state?.orgId }));
  }, [dispatch, location]);

  useEffect(() => {
    if (org) {
      setOrgUsers(org?.users);
      setOrganization(org);

      // setOCS(org.involved_in_ocs);
    }
  }, [org]);

  const callback = () => {
    setEditedData(null);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  const changeOwnercallback = () => {
    setSelectedUserID('');
    setChangeOwner(false);
    dispatch(shouldFetchLists());
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
    dispatch(adminOrgs({ get: location?.state?.orgId }));
  };

  // const enableBtn = () => {
  //   if (edit && NewsroomPaymentSuccess) {
  //     setEdit(!NewsroomPaymentSuccess);
  //   }
  // };
  const moveUserCallback = () => {
    setSelectedUserID('');
    setMoveUserSuccess(true);
    dispatch(adminOrgs());
    dispatch(shouldFetchLists());
    dispatch(adminOrgs({ get: location?.state?.orgId }));
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
  };

  const createAndMoveUserCallback = (orgId) => {
    dispatch(
      adminOrgs(
        { update: { company_id: parseInt(orgId, 10), add_users: [selectedUserID] } },
        moveUserCallback,
        loadingMoveUserCallBack
      )
    );
  };

  const loadingCallBack = (param) => {
    setChangeOwnerLoading(param);
  };

  const loadingMoveUserCallBack = (param) => {
    setMoveUserLoading(param);
  };

  const loadingCreateAndMoveUserCallBack = (param) => {
    setAddOrgAndMoveUserLoading(param);
  };

  const sortUsers = (by) => {
    setOrderBy(by);
    setOrder(order === -1 ? 1 : -1);

    if (by === 'email' && order === -1) {
      setOrgUsers([...orgUsers].sort((a, b) => (a.user_email > b.user_email ? -1 : 1)));
    } else if (by === 'email' && order === 1) {
      setOrgUsers([...orgUsers].sort((a, b) => (a.user_email > b.user_email ? 1 : -1)));
    } else if (by === 'name' && order === -1) {
      setOrgUsers([...orgUsers].sort((a, b) => (a.user_name > b.user_name ? -1 : 1)));
    } else if (by === 'name' && order === 1) {
      setOrgUsers([...orgUsers].sort((a, b) => (a.user_name > b.user_name ? 1 : -1)));
    }
  };

  const handleEditOrg = () => {
    let paymentSetting;
    const { ...rest } = organization;

    if (Array.isArray(organization?.premium_membership_settings)) {
      paymentSetting = organization?.premium_membership_settings[0];
    }
    dispatch(
      adminOrgs(
        {
          update: {
            ...rest,
            premium_membership_settings: paymentSetting,
            company_id: location?.state?.orgId
          }
        },
        callback
      )
    );
  };

  const handleChangeOwner = (userId) => {
    setSelectedUserID(userId);
    dispatch(
      adminOrgs(
        { update: { company_id: location?.state?.orgId, owner: userId } },
        changeOwnercallback,
        loadingCallBack
      )
    );
  };

  const handleMoveUser = (orgId) => {
    dispatch(
      adminOrgs(
        { update: { company_id: parseInt(orgId, 10), add_users: [selectedUserID] } },
        moveUserCallback,
        loadingMoveUserCallBack
      )
    );
  };

  const handleCreateAndMoveUser = (companyName, location) => {
    dispatch(setClearCreateAdminOrgError());
    dispatch(
      adminOrgs(
        {
          create: {
            company_name: companyName || selectedUserName,
            location,
            add_users: [selectedUserID],
            domains: ['usetmx.com']
          }
        },
        createAndMoveUserCallback,
        loadingCreateAndMoveUserCallBack
      )
    );
  };

  const clearCreateError = () => {
    dispatch(setClearCreateAdminOrgError());
  };

  const haveSameData = (obj1, obj2) => {
    if (obj1 && obj2) {
      const obj1Length = Object.keys(obj1).length;
      const obj2Length = Object.keys(obj2).length;

      if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
          // eslint-disable-next-line no-prototype-builtins
          (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
        );
      }
      return false;
    }
  };

  const membershipCallback = () => {
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
    setMembershipSuccess(true);
    setTimeout(() => {
      setMembershipSuccess(false);
    }, 2000);
  };

  const handleEditMembershipPayment = () => {
    dispatch(
      editMembership({ ...paymentData, company_id: location?.state?.orgId }, membershipCallback)
    );
  };

  const paymentCallback = () => {
    setEmail('');
    setIsPaymentInvite(false);
    setPaymentSuccess(true);
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
    setTimeout(() => {
      setPaymentSuccess(false);
    }, 2000);
  };

  const verifyCallback = () => {
    setDepositData(null);
    setVerifySuccess(true);
    dispatch(paymentStatus({ company_id: location?.state?.orgId }));
    setTimeout(() => {
      setVerifySuccess(false);
    }, 2000);
  };

  const handleSendPaymentInvite = () => {
    dispatch(
      sendPaymentInvite(
        {
          ...paymentData,
          company_id: location?.state?.orgId,
          contact_person_email: email
        },
        paymentCallback
      )
    );
  };

  const handleNewsroomPayment = () => {
    dispatch(
      sendNewsroomPayment(
        {
          company_id: location?.state?.orgId,
          ...newsroomPayment
        },
        paymentCallback
      )
    );
  };

  const enableBtn = () => {
    if (!edit) {
      setSaved(false);
    } else if (edit && save) {
      setSaved(true);
    }
  };

  const handleVerifyBank = () => {
    dispatch(
      verifyBankAccount(
        {
          stripe_customer_id: paymentData?.stripe_customer_id,
          payment_id: paymentData?.payment_id,
          ...depositData
        },
        verifyCallback
      )
    );
  };

  return (
    <>
      {showInvite ? (
        <inviteContext.Provider
          value={{
            dispatch: dispatch,
            sendingInviteLoading: inviteLoading,
            invitesSentSuccess: invitesSentSuccess,
            numberOfInvites: numberOfInvites,
            closeModal: () => setShowInvite(false),
            invitesSentError: inviteError,
            companyId: location?.state?.orgId
          }}>
          <Suspense fallback={<></>}>
            <Invitation />
          </Suspense>
        </inviteContext.Provider>
      ) : null}
      {showOrgModal ? (
        <organizationContext.Provider
          value={{
            dispatch: dispatch,
            closeModal: () => {
              setShowOrgModal(false);
              setMoveUserSuccess(false);
            }
          }}>
          <Suspense fallback={<></>}>
            <OrganizationModal
              organizations={organizations?.orgs || []}
              loading={moveUserLoading}
              handleMoveUser={handleMoveUser}
              success={moveUserSuccess}
              locationsArray={locationsArray || []}
              createError={createError}
              clearCreateError={clearCreateError}
              handleCreateAndMoveUser={handleCreateAndMoveUser}
              createAndMoveUserLoading={addOrgAndMoveUserLoading || moveUserLoading}
            />
          </Suspense>
        </organizationContext.Provider>
      ) : null}
      <div>
        <section className="tmx-organizationDetail">
          <div className="tmx-organizationDetail__overallContainer">
            <div className="tmx-organizationDetail__backButtonContainer">
              <Button
                buttonIcon="arrow-right"
                buttonIconClassName="tmx-organizationDetail__backButton"
                iconSize="ginormous"
                className="tmx-organizationDetail__backContent black"
                handleClick={() => history.push('/organization-settings')}>
                {isMobile ? '' : 'Back to Search'}
              </Button>
            </div>
            <div className="tmx-organizationDetail__companyName">{organization?.company_name}</div>
            <section className="tmx-organizationDetail__organizationInfo">
              <div className="tmx-organizationDetail__orgSettingsHeader">
                <h1>Information</h1>
                <Button
                  className="tmx-organizationDetail__editButon"
                  handleClick={() => {
                    dispatch(adminOrgs({ get: location?.state?.orgId }));
                    setEditedData(null);
                    setEdit(!edit);
                    enableBtn();
                  }}>
                  {edit ? 'Done' : 'Edit'}
                </Button>
              </div>
              <div className="tmx-organizationDetail__formInlineWrapper">
                <div className="tmx-organizationDetail__formInline space">
                  <div className="tmx-organizationDetail__formTitle">Organization Name</div>
                  <div className="tmx-organizationDetail__formDetails">
                    Actual name of organization in TMX database, not display name.{' '}
                  </div>
                </div>
                <div
                  className={classNames('tmx-organizationDetail__formInline', {
                    'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                  })}>
                  {!loading && !loadingMulti && (
                    <Input
                      id="organizationName"
                      name="organizationName"
                      darkBorder
                      value={organization.company_name}
                      onChange={(event) => {
                        setOrganization({ ...organization, company_name: event.target.value });
                        setEditedData({
                          ...editedData,
                          premium_membership_settings:
                            organization.premium_membership_settings &&
                            organization.premium_membership_settings[0],
                          company_name: event.target.value
                        });
                      }}
                      type="text"
                      label={'Organization name'}
                      labelClassName="tmx-organizationDetail__uploadFormLabel"
                      htmlFor={'organizationName'}
                      disabled={!edit}
                    />
                  )}
                </div>
              </div>
              <div className="tmx-organizationDetail__formInlineWrapper">
                <div className="tmx-organizationDetail__formInline space">
                  <div className="tmx-organizationDetail__formTitle">Verification</div>
                  <div className="tmx-organizationDetail__formDetails">
                    Toggle verified status to turn on or off.
                  </div>
                </div>
                <div
                  className={classNames('tmx-organizationDetail__formInline', {
                    'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                  })}>
                  {!loading && !loadingMulti && (
                    <SlideToggle
                      id="verified"
                      className="tmx-organizationDetail__slider"
                      label={'Verified'}
                      value={organization.verified}
                      onClick={() => {
                        setOrganization({ ...organization, verified: !organization.verified });
                        setEditedData({
                          ...editedData,
                          premium_membership_settings:
                            organization.premium_membership_settings &&
                            organization.premium_membership_settings[0],
                          verified: !organization.verified
                        });
                      }}
                      disabled={!edit}
                      showText
                    />
                  )}
                </div>
              </div>
              <div className="tmx-organizationDetail__formInlineWrapper">
                <div className="tmx-organizationDetail__formInline space">
                  <div className="tmx-organizationDetail__formTitle">Downloads</div>
                  <div className="tmx-organizationDetail__formDetails">
                    Toggle download permission to turn on or off.
                  </div>
                </div>
                <div
                  className={classNames('tmx-organizationDetail__formInline', {
                    'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                  })}>
                  {!loading && !loadingMulti && (
                    <SlideToggle
                      id="allow_download"
                      className="tmx-organizationDetail__slider"
                      label={'Allow downloads'}
                      value={organization.allow_download}
                      onClick={() => {
                        setOrganization({
                          ...organization,
                          allow_download: !organization.allow_download
                        });
                        setEditedData({
                          ...editedData,
                          allow_download: !organization.allow_download
                        });
                      }}
                      disabled={!edit}
                      showText
                    />
                  )}
                  <div className="tmx-organizationDetail__newsroomLabel">
                    <TextArea
                      id="download_error_message"
                      name="download_error_message"
                      darkBorder
                      disabled={!edit}
                      className="tmx-organizationDetail__inputForm"
                      rowsMax={5}
                      value={organization?.download_error_message}
                      maxCharacters="1000"
                      onChange={(event) => {
                        setOrganization({
                          ...organization,
                          download_error_message: event.target.value
                        });
                        setEditedData({
                          ...editedData,
                          download_error_message: event.target.value
                        });
                      }}
                      type="text"
                      htmlFor={'publicistPrice'}
                      label={'Custom error message'}
                      labelClassName="tmx-organizationDetail__uploadFormLabel"
                      statusBar={true}
                      placeholder={'Error message'}
                    />
                  </div>
                </div>
              </div>
              <div className="tmx-organizationDetail__formInlineWrapper">
                <div className="tmx-organizationDetail__formInline space">
                  <div className="tmx-organizationDetail__formTitle">User Permission</div>
                  <div className="tmx-organizationDetail__formDetails">
                    Select the level of access.
                  </div>
                </div>
                <div
                  className={classNames('tmx-organizationDetail__formInline', {
                    'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                  })}>
                  {!loading && !loadingMulti && (
                    <>
                      <div
                        className={classNames('tmx-organizationDetail__formInline', {
                          'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                        })}>
                        <label
                          className="tmx-organizationDetail__uploadFormLabel"
                          htmlFor="selectFrequency">
                          Org Type
                        </label>
                        <DropDown
                          disableOnBlur
                          // enableValue
                          onChange={(event) => {
                            if (event.target.value !== 'Select level of access') {
                              setOrganization({
                                ...organization,
                                permission: event.target.value === 'Access' ? 'B' : 'C'
                              });
                              setEditedData({
                                ...editedData,
                                premium_membership_settings:
                                  organization.premium_membership_settings &&
                                  organization.premium_membership_settings[0],
                                permission: event.target.value === 'Access' ? 'B' : 'C'
                              });
                            }
                          }}
                          optionSelected={organization?.permission === 'B' ? 'Access' : 'Newsroom'}
                          className="tmx-organizationDetail__dropDown grey"
                          data={['Access', 'Newsroom']}
                          name="Select level of access"
                          id="permission"
                          disabled={!edit}
                        />
                      </div>
                      <br />

                      {organization?.permission === 'B' && (
                        <>
                          <div className="tmx-organizationDetail__formInlineWrapper">
                            <div className="tmx-organizationDetail__formInline space">
                              <div className="tmx-organizationDetail__formTitle">
                                Access Account type
                              </div>
                            </div>
                            <div
                              className={classNames('tmx-organizationDetail__formInline', {
                                'tmx-organizationDetail__formInline--loading':
                                  loading || loadingMulti
                              })}>
                              {organization?.access_plan_paid === 'paid' ? 'Paid' : 'Free'}
                            </div>
                          </div>

                          <div className="tmx-organizationDetail__formInlineWrapper">
                            <div className="tmx-organizationDetail__formInline space">
                              <div className="tmx-organizationDetail__formTitle">
                                Access Plan type
                              </div>
                            </div>
                            <div
                              className={classNames('tmx-organizationDetail__formInline', {
                                'tmx-organizationDetail__formInline--loading':
                                  loading || loadingMulti
                              })}>
                              <DropDown
                                disableOnBlur
                                // enableValue
                                onChange={(event) => {
                                  if (event.target.value !== 'Select Access Plan type') {
                                    setOrganization({
                                      ...organization,
                                      access_plan_type:
                                        event.target.value === 'Basic'
                                          ? 'basic'
                                          : event.target.value === 'Starter'
                                          ? 'starter'
                                          : 'premium',
                                      premium_trial: {
                                        active:
                                          event.target.value === 'Starter' ||
                                          event.target.value === 'Premium'
                                            ? true
                                            : false,
                                        end_date: '2099-01-01 00:00:00'
                                      }
                                    });
                                    setEditedData({
                                      ...editedData,
                                      premium_membership_settings:
                                        organization.premium_membership_settings &&
                                        organization.premium_membership_settings[0],
                                      access_plan_type:
                                        event.target.value === 'Basic'
                                          ? 'basic'
                                          : event.target.value === 'Starter'
                                          ? 'starter'
                                          : 'premium',
                                      premium_trial: {
                                        active:
                                          event.target.value === 'Starter' ||
                                          event.target.value === 'Premium'
                                            ? true
                                            : false,
                                        end_date: '2099-01-01 00:00:00'
                                      }
                                    });
                                  }
                                }}
                                optionSelected={
                                  organization?.access_plan_type === 'basic'
                                    ? 'Basic'
                                    : organization?.access_plan_type === 'starter'
                                    ? 'Starter'
                                    : organization?.access_plan_type === 'premium'
                                    ? 'Premium'
                                    : ''
                                }
                                className="tmx-organizationDetail__dropDown grey"
                                data={['Basic', 'Starter', 'Premium']}
                                name="Select Access Plan type"
                                id="type"
                                disabled={!edit || organization?.access_plan_paid === 'paid'}
                              />
                            </div>
                          </div>

                          {(organization?.access_plan_type === 'starter' ||
                            organization?.access_plan_type === 'premium') &&
                          organization?.access_plan_period ? (
                            <div className="tmx-organizationDetail__formInlineWrapper">
                              <div className="tmx-organizationDetail__formInline space">
                                <div className="tmx-organizationDetail__formTitle">
                                  Access Plan period
                                </div>
                              </div>
                              <div
                                className={classNames('tmx-organizationDetail__formInline', {
                                  'tmx-organizationDetail__formInline--loading':
                                    loading || loadingMulti
                                })}>
                                {organization?.access_plan_period === 'monthly'
                                  ? 'Monthly'
                                  : 'Yearly'}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}

                      {organization?.permission === 'B' && (
                        <div className="tmx-organizationDetail__formInlineWrapper">
                          <div className="tmx-organizationDetail__formInline space">
                            <div className="tmx-organizationDetail__formTitle">
                              Find New Business <br></br> (Contacts) Collection
                            </div>
                          </div>
                          <div
                            className={classNames('tmx-organizationDetail__formInline', {
                              'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                            })}>
                            {!loading && !loadingMulti && (
                              <SlideToggle
                                id="access_contacts"
                                className="tmx-organizationDetail__slider"
                                label={'Premium'}
                                value={organization?.access_contacts}
                                onClick={() => {
                                  setOrganization({
                                    ...organization,
                                    access_contacts: !organization.access_contacts
                                  });
                                  setEditedData({
                                    ...editedData,
                                    access_contacts: !organization.access_contacts
                                  });
                                }}
                                disabled={!edit}
                                showText
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {organization?.permission !== 'B' && (
                        <div className="tmx-organizationDetail__OCScontainer">
                          <Input
                            type="checkbox"
                            checked={newsroomPayment?.sell_content}
                            onChange={() => {
                              setSaved(false);
                              setNewsroomPayment({
                                ...newsroomPayment,
                                sell_content: !newsroomPayment?.sell_content
                              });
                            }}
                            disabled={!edit}
                          />
                          <p>{organization?.permission !== 'B' ? 'Sell content to TMX' : null}</p>
                        </div>
                      )}
                      {organization?.permission !== 'B' && newsroomPayment?.sell_content && (
                        <>
                          <div className="tmx-organizationDetail__feesLabel">
                            <Input
                              id="newsroomPrice"
                              name="newsroomPrice"
                              darkBorder
                              disabled={!edit}
                              value={newsroomPayment?.fee_newsroom}
                              onChange={(event) => {
                                setSaved(false);
                                setNewsroomPayment({
                                  ...newsroomPayment,
                                  fee_newsroom: parseInt(event.target.value)
                                });
                              }}
                              type="number"
                              htmlFor={'newsroomPrice'}
                              label={'Amount to pay newsroom*'}
                              labelClassName="tmx-organizationDetail__uploadFormLabel"
                              placeholder={'Enter fee amount'}
                            />
                          </div>
                          <div>
                            <Input
                              id="publicistPrice"
                              name="publicistPrice"
                              darkBorder
                              disabled={!edit}
                              value={newsroomPayment?.fee_publicist}
                              onChange={(event) => {
                                setSaved(false);
                                setNewsroomPayment({
                                  ...newsroomPayment,
                                  fee_publicist: parseInt(event.target.value)
                                });
                              }}
                              type="number"
                              htmlFor={'publicistPrice'}
                              label={'Amount to charge publicist*'}
                              labelClassName="tmx-organizationDetail__uploadFormLabel"
                              placeholder={'Enter fee amount'}
                            />
                          </div>
                          <div className="tmx-organizationDetail__newsroomLabel">
                            <Input
                              id="newsroomUrl"
                              name="newsroomUrl"
                              darkBorder
                              disabled={!edit}
                              value={newsroomPayment?.newsroom_url}
                              onChange={(event) => {
                                setSaved(false);
                                setNewsroomPayment({
                                  ...newsroomPayment,
                                  newsroom_url: event.target.value
                                });
                              }}
                              type="text"
                              maxLength="1000"
                              htmlFor={'publicistPrice'}
                              label={'Newsroom URL (recommended)'}
                              labelClassName="tmx-organizationDetail__uploadFormLabel"
                              placeholder={'Enter URL'}
                            />
                          </div>
                          <div className="tmx-organizationDetail__newsroomLabel">
                            <TextArea
                              id="newsroomDesc"
                              name="newsroomDesc"
                              darkBorder
                              disabled={!edit}
                              className="tmx-organizationDetail__inputForm"
                              rowsMax={5}
                              value={newsroomPayment?.newsroom_description}
                              maxCharacters="1000"
                              onChange={(event) => {
                                setSaved(false);
                                setNewsroomPayment({
                                  ...newsroomPayment,
                                  newsroom_description: event.target.value
                                });
                              }}
                              type="text"
                              htmlFor={'publicistPrice'}
                              label={'Newsroom description'}
                              labelClassName="tmx-organizationDetail__uploadFormLabel"
                              statusBar={true}
                              placeholder={'Description of organizatiom'}
                            />
                          </div>
                        </>
                      )}
                      {organization?.permission !== 'B' && (
                        <Button
                          type="button"
                          iconSize="small"
                          spinner={NewsroomPaymentLoading}
                          buttonIconClassName="tmx-tools__icon tmx-tools__icon--marginChange"
                          buttonIcon={!NewsroomPaymentLoading && 'payment'}
                          className="tmx-organizationDetail__paymentBtn"
                          disabled={NewsroomPaymentLoading || !edit || save}
                          handleClick={(event) => {
                            event.preventDefault();
                            handleNewsroomPayment();
                          }}
                          buttonType="blue">
                          {save ? 'Saved' : 'Save fee'}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
            <section className="tmx-organizationDetail__userSettings">
              <div className="tmx-organizationDetail__orgSettingsHeader">
                <h1>Users</h1>{' '}
              </div>
              <div
                className={classNames(
                  'tmx-organizationDetail__UserContainer tmx-organizationDetail__UserContainer--heading',
                  {
                    'tmx-organizationDetail__UserContainer--newsRoom':
                      organization?.permission === 'C'
                  }
                )}>
                <div className="tmx-organizationDetail__UserContainerName header">
                  User Name{' '}
                  <Icon
                    name={
                      orderBy === 'name' && order === 1
                        ? 'sort-up'
                        : orderBy === 'name' && order === -1
                        ? 'sort-down'
                        : 'sort-default'
                    }
                    size="micro"
                    className="tmx-organizationDetail__sortIcon"
                    onClick={() => sortUsers('name')}
                  />
                </div>
                <div className="tmx-organizationDetail__UserContainerEmail header">
                  User Email Address{' '}
                  <Icon
                    name={
                      orderBy === 'email' && order === 1
                        ? 'sort-up'
                        : orderBy === 'email' && order === -1
                        ? 'sort-down'
                        : 'sort-default'
                    }
                    size="micro"
                    className="tmx-organizationDetail__sortIcon"
                    onClick={() => sortUsers('email')}
                  />
                </div>
                {/* {organization?.permission === 'C' ? (
                  <div className="tmx-organizationDetail__UserContainerEmail header">Boost</div>
                ) : null} */}
              </div>
              <div className="tmx-organizationDetail__users">
                {orgUsers?.map((user, key) => (
                  <div
                    className={classNames(
                      'tmx-organizationDetail__UserContainer',
                      {
                        'tmx-organizationDetail__UserContainer--loading': loading || loadingMulti
                      },
                      {
                        'tmx-organizationDetail__UserContainer--newsRoom':
                          organization?.permission === 'C'
                      }
                    )}
                    key={key}>
                    {!loading && !loadingMulti && (
                      <>
                        <div
                          className={classNames('tmx-organizationDetail__UserContainerName', {
                            'tmx-organizationDetail__UserContainerName--bold': user.owner
                          })}>
                          {user.user_name}
                        </div>
                        <div className="tmx-organizationDetail__UserContainerEmail">
                          {user.user_email}
                        </div>
                        {/* {organization?.permission === 'C' ? (
                          <div className="tmx-organizationDetail__UserContainerEmail">
                            <Input
                              type="checkbox"
                              checked={user.boost}
                              onChange={(event) => {
                                const values = [...organization.users];
                                values[key]['boost'] = event.target.checked;
                                setOrganization({
                                  ...organization,
                                  users: values
                                });
                                const editValues =
                                  editedData?.users !== null &&
                                  editedData?.users !== undefined &&
                                  editedData !== null
                                    ? [...editedData.users]
                                    : [...organization.users];
                                editValues[key]['boost'] = event.target.checked;
                                setEditedData({
                                  ...editedData,
                                  users: editValues
                                });
                              }}
                              disabled={!edit}
                            />
                          </div>
                        ) : null} */}

                        <div className="tmx-organizationDetail__UserContainerAction">
                          {user.owner ? (
                            <div>
                              <span className="tmx-organizationDetail__orgOwner">Org Owner</span>
                              <Button
                                type="button"
                                handleClick={() => setChangeOwner(!changeOwner)}>
                                {changeOwner ? 'Keep as owner' : 'Change'}
                              </Button>
                            </div>
                          ) : (
                            <Button
                              type="button"
                              handleClick={() => {
                                if (changeOwner) {
                                  handleChangeOwner(user?.user_id);
                                } else {
                                  setShowOrgModal(true);
                                  setSelectedUserID(user?.user_id);
                                  setSelectedUserName(user?.user_name);
                                }
                              }}>
                              {changeOwner
                                ? changeOwnerLoading && user?.user_id === selectedUserID
                                  ? 'Changing...'
                                  : 'Make owner'
                                : 'Move user'}
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="tmx-organizationDetail__UserContainerInviteBtn">
                <Button
                  buttonType="blue"
                  className="tmx-organizationDetail__invitBtn"
                  buttonIcon="plus-circled"
                  buttonIconClassName="tmx-organizationDetail__invitBtnIcon"
                  handleClick={() => setShowInvite(true)}>
                  Invite
                </Button>
              </div>
            </section>
            <section className="tmx-organizationDetail__premiumMembership">
              <div className="tmx-organizationDetail__orgSettingsHeader">
                <h1>Membership</h1>{' '}
                {!loading && (
                  <SlideToggle
                    id="premiumMembership"
                    className="tmx-organizationDetail__slider"
                    value={paymentData?.membership_status}
                    onClick={() => {
                      setPaymentData({
                        ...paymentData,
                        membership_status: !paymentData?.membership_status
                      });
                    }}
                    showText
                  />
                )}
              </div>
              <div
                className={classNames({
                  'tmx-organizationDetail__fade': !paymentData?.membership_status
                })}>
                <div className="tmx-organizationDetail__formInlineWrapper">
                  <div className="tmx-organizationDetail__formInline space">
                    <div className="tmx-organizationDetail__formTitle">
                      Monthly database searches
                    </div>
                    <div className="tmx-organizationDetail__formDetails">
                      Set number of monthly database searches allowed to the organization. Searches
                      reset every 30 days.
                    </div>
                  </div>
                  <div
                    className={classNames('tmx-organizationDetail__formInline', {
                      'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <Input
                        id="searchAlloted"
                        name="searchAlloted"
                        darkBorder
                        value={
                          organization?.premium_membership_settings
                            ? organization?.premium_membership_settings[0]?.database_searches
                            : ''
                        }
                        onChange={() => {}}
                        type="number"
                        label={'Searches allotted'}
                        labelClassName="tmx-organizationDetail__uploadFormLabel"
                        htmlFor={'searchAlloted'}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>
                <div className="tmx-organizationDetail__formInlineWrapper">
                  <div className="tmx-organizationDetail__formInline space">
                    <div className="tmx-organizationDetail__formTitle">Pricing</div>
                    <div className="tmx-organizationDetail__formDetails">
                      Set price, relative billing cadence, and specify start date.
                    </div>
                    <div className="tmx-organizationDetail__subFormDetails">
                      Next bill: <b>{paymentData?.membership_next_date}</b>
                    </div>
                  </div>
                  <div
                    className={classNames('tmx-organizationDetail__formInline', {
                      'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                    })}>
                    {!loading && !loadingMulti && (
                      <>
                        <Input
                          id="monthlyPrice"
                          name="monthlyPrice"
                          darkBorder
                          value={paymentData?.membership_price_cents / 100 || ''}
                          onChange={(event) => {
                            setPaymentData({
                              ...paymentData,
                              membership_price_cents: parseInt(event.target.value, 10) * 100
                            });
                          }}
                          type="number"
                          label={'Price'}
                          labelClassName="tmx-organizationDetail__uploadFormLabel"
                          htmlFor={'monthlyPrice'}
                          disabled={!paymentData?.membership_status}
                        />
                        <br />
                        <div
                          className={classNames('tmx-organizationDetail__formInline', {
                            'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                          })}>
                          <label
                            className="tmx-organizationDetail__uploadFormLabel"
                            htmlFor="selectFrequency">
                            Frequency to debit
                          </label>
                          <DropDown
                            onChange={(event) => {
                              setPaymentData({
                                ...paymentData,
                                membership_period: event.target.value
                              });
                            }}
                            optionSelected={paymentData?.membership_period}
                            className="tmx-organizationDetail__dropDown grey"
                            data={['DAILY', 'WEEKLY', 'MONTHLY', 'QUARTERLY', 'YEARLY']}
                            name="Select Frequency"
                            id="selectFrequency"
                            disabled={!paymentData?.membership_status}
                          />
                        </div>
                        <br />
                        <div
                          className={classNames('tmx-organizationDetail__formInline', {
                            'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                          })}>
                          {!loading && !loadingMulti && (
                            <Input
                              id="startDate"
                              name="startDate"
                              darkBorder
                              value={
                                paymentData?.membership_start_date
                                  ? paymentData?.membership_start_date
                                  : paymentData?.membership_start_date_current
                                  ? paymentData?.membership_start_date_current
                                  : ''
                              }
                              onChange={(event) => {
                                setPaymentData({
                                  ...paymentData,
                                  membership_start_date: event.target.value
                                });
                              }}
                              type={dateInputType}
                              min={new Date().toISOString().split('T')[0]}
                              label={'Start Date'}
                              labelClassName="tmx-organizationDetail__uploadFormLabel"
                              htmlFor={'startDate'}
                              onFocus={() => setDateInputType('date')}
                              disabled={!paymentData?.membership_status}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="tmx-organizationDetail__formInlineWrapper">
                  <div className="tmx-organizationDetail__formInline space">
                    <div className="tmx-organizationDetail__formTitle">
                      Collect payment information
                    </div>
                    <div className="tmx-organizationDetail__formDetails">
                      Status:{' '}
                      <span>
                        {paymentData?.payment_method_status
                          ? paymentData?.payment_method_status
                          : 'No payment on file'}
                      </span>
                    </div>
                    <div className="tmx-organizationDetail__subFormDetailsPayment">
                      Type:{' '}
                      <span>
                        {paymentData?.payment_method_type
                          ? paymentData?.payment_method_type
                          : 'None'}
                      </span>
                    </div>
                    <div className="tmx-organizationDetail__formTitleOrg">
                      Payment invite information
                    </div>
                    <div className="tmx-organizationDetail__formDetails">
                      Invited Person Email:{' '}
                      {paymentData?.invited_person_email
                        ? paymentData?.invited_person_email
                        : 'None'}
                    </div>

                    <div className="tmx-organizationDetail__subFormDetailsPayment">
                      Request Status:{' '}
                      {paymentData?.still_pending_request
                        ? paymentData?.still_pending_request
                        : 'None'}
                    </div>
                  </div>
                  {!isPaymentInvite && (
                    <div
                      className={classNames('tmx-organizationDetail__formInline', {
                        'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                      })}>
                      {!loading && !loadingMulti && (
                        <Button
                          type="button"
                          iconSize="huge"
                          buttonIconClassName="tmx-tools__icon tmx-tools__icon--marginChange"
                          buttonIcon="send"
                          className="tmx-organizationDetail__requestBtn"
                          disabled={!paymentData?.membership_status}
                          handleClick={(event) => {
                            event.preventDefault();
                            setIsPaymentInvite(true);
                          }}
                          buttonType="blue">
                          Request ACH/CC
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                {isPaymentInvite && (
                  <div className="tmx-organizationDetail__formInlineWrapper">
                    <div className="tmx-organizationDetail__formInline space">
                      <div className="tmx-organizationDetail__formDetails">
                        Auto-payment receipts will go to this email address.
                      </div>
                    </div>
                    <div
                      className={classNames('tmx-organizationDetail__formInline', {
                        'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                      })}>
                      {!loading && !loadingMulti && (
                        <>
                          <Input
                            id="paymentEmail"
                            name="paymentEmail"
                            darkBorder
                            value={email}
                            labelClassName="tmx-organizationDetail__uploadFormLabel"
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            type="email"
                            label={'Email'}
                            htmlFor={'paymentEmail'}
                            placeholder="example@work-email.com"
                            disabled={!paymentData?.membership_status}
                          />
                          <div className="tmx-organizationDetail__paymentEmail">
                            {!loading && !loadingMulti && (
                              <Button
                                type="button"
                                className="tmx-organizationDetail__customBtn"
                                spinner={paymentInviteLoading}
                                disabled={
                                  paymentInviteLoading || !email || !paymentData?.membership_status
                                }
                                handleClick={(event) => {
                                  event.preventDefault();
                                  handleSendPaymentInvite();
                                }}
                                buttonType="blue">
                                Send payment email
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {paymentData?.stripe_customer_id &&
                  paymentData?.payment_id &&
                  paymentData?.payment_method_status === 'pending_verify' && (
                    <div className="tmx-organizationDetail__formInlineWrapper">
                      <div className="tmx-organizationDetail__formInline space">
                        <div className="tmx-organizationDetail__formTitle">
                          Micro Deposit Amounts
                        </div>
                        <div className="tmx-organizationDetail__formDetails">
                          What are the two amounts that appeared in user&apos;s bank account?
                        </div>
                        <div className="tmx-organizationDetail__paymentEmail">
                          <Button
                            type="button"
                            spinner={verifyBankLoading}
                            disabled={
                              verifyBankLoading || !depositData || !paymentData?.membership_status
                            }
                            handleClick={(event) => {
                              event.preventDefault();
                              handleVerifyBank();
                            }}
                            buttonType="blue">
                            Verify deposits
                          </Button>
                        </div>
                      </div>
                      <div
                        className={classNames('tmx-organizationDetail__formInline', {
                          'tmx-organizationDetail__formInline--loading': loading || loadingMulti
                        })}>
                        {!loading && !loadingMulti && (
                          <>
                            <Input
                              id="depositOne"
                              name="depositOne"
                              darkBorder
                              value={depositDollarData?.amount_1_cents}
                              onChange={(event) => {
                                setDepositData({
                                  ...depositData,
                                  amount_1_cents: parseFloat(event.target.value) * 100
                                });
                                setDepositDollarData({
                                  ...depositDollarData,
                                  amount_1_cents: event.target.value
                                });
                              }}
                              type="text"
                              label={'Deposit 1'}
                              htmlFor={'depositOne'}
                              placeholder="e.g. $0.73"
                              disabled={!paymentData?.membership_status}
                            />
                            <br />
                            <Input
                              id="depositTwo"
                              name="depositTwo"
                              darkBorder
                              value={depositDollarData?.amount_2_cents}
                              onChange={(event) => {
                                setDepositData({
                                  ...depositData,
                                  amount_2_cents: parseFloat(event.target.value) * 100
                                });
                                setDepositDollarData({
                                  ...depositDollarData,
                                  amount_2_cents: event.target.value
                                });
                              }}
                              type="text"
                              label={'Deposit 2'}
                              htmlFor={'depositTwo'}
                              placeholder="e.g. $0.24"
                              disabled={!paymentData?.membership_status}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
              </div>
              {paymentSuccess && (
                <p className="tmx-organizationDetail__success">Email sent successfully</p>
              )}
              {paymentInviteError && (
                <p className="tmx-organizationDetail__error">{paymentInviteErrorMsg}</p>
              )}
              {verifySuccess && (
                <p className="tmx-organizationDetail__success">Verified successfully</p>
              )}
              {verifyBankError && <p className="tmx-organizationDetail__error">{verifyError}</p>}
            </section>

            {error && <p className="tmx-organizationDetail__error">Something went wrong</p>}
            {success && membershipSuccess && (
              <p className="tmx-organizationDetail__success">Saved successful</p>
            )}
            <Button
              type="button"
              className="tmx-organizationDetail__saveBtn"
              spinner={editLoading || editMembershipLoading}
              disabled={
                editLoading ||
                (!editedData && haveSameData(paymentData, paymentStatusData?.result)) ||
                editMembershipLoading ||
                paymentLoading ||
                loadingMulti
              }
              handleClick={(event) => {
                event.preventDefault();
                handleEditMembershipPayment();
                handleEditOrg();
              }}
              buttonType="blue">
              Save Changes
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrganizationDetails;

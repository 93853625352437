import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Input.scss';

import Button from '../Button/Button';

const Input = ({
  autoComplete,
  checked,
  className,
  darkBorder,
  dateMax,
  disabled,
  disablePastTime,
  error,
  greyBorder,
  htmlFor,
  iconSize,
  id,
  inputIcon,
  inputPrefix,
  inputPrefixClassName,
  inputRef,
  label,
  labelClassName,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  placeholder,
  textarea,
  type,
  value
}) => {
  const [inputType, setInputType] = useState('');
  const [iconType, setIconType] = useState('eye-slash-solid');
  const classes = classNames('tmx-input', className, {
    grey: greyBorder,
    checkbox: type === 'checkbox',
    dark: darkBorder,
    dateTime: disablePastTime,
    error
  });
  useEffect(() => {
    if (disablePastTime && !disabled) {
      var today = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(':'));
      document.getElementsByClassName('dateTime')[0].setAttribute('min', today);
    }
  }, [disablePastTime, disabled]);

  const callback = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange]
  );
  const keyDownCallback = useCallback(
    (event) => {
      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [onKeyDown]
  );
  const keyUpCallback = useCallback(
    (event) => {
      if (onKeyUp) {
        onKeyUp(event);
      }
    },
    [onKeyUp]
  );
  const blurCallback = useCallback(
    (event) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );
  const focusCallback = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={classNames('tmx-input__wrapper', {
        'tmx-input__wrapper--checkbox': type === 'checkbox',
        'tmx-input__wrapper--radio': type === 'radio'
      })}>
      {(label || error) && (
        <div className="tmx-input__labelWrapper">
          <div>
            {label && (
              <label
                className={classNames('tmx-input__uploadFormLabel', labelClassName)}
                htmlFor={htmlFor}>
                {label}
              </label>
            )}
          </div>
          {error && <div className="tmx-input__errorMessage">{error}</div>}
        </div>
      )}
      <div className={classNames('tmx-input__inputContainer', { showPrefix: inputPrefix })}>
        {inputPrefix && (
          <div className={classNames('tmx-input__inputPrefix', inputPrefixClassName)}>
            {inputPrefix}
          </div>
        )}
        {textarea ? (
          <textarea
            className={classes}
            type={inputType}
            placeholder={placeholder}
            onChange={callback}
            onKeyDown={keyDownCallback}
            onKeyUp={keyUpCallback}
            onBlur={blurCallback}
            onFocus={focusCallback}
            id={id}
            name={name}
            value={value}
            autoComplete={autoComplete}
            ref={inputRef}
            max={max}
            maxLength={maxLength}
            // checked={checked}
            disabled={disabled}
          />
        ) : (
          <input
            className={classes}
            type={inputType}
            placeholder={placeholder}
            onChange={callback}
            onKeyDown={keyDownCallback}
            onKeyUp={keyUpCallback}
            onBlur={blurCallback}
            onFocus={focusCallback}
            id={id}
            name={name}
            value={value}
            autoComplete={autoComplete}
            ref={inputRef}
            max={disablePastTime ? '' : dateMax ? dateMax : max}
            min={min}
            maxLength={maxLength}
            checked={checked}
            disabled={disabled}
          />
        )}
      </div>

      {type === 'password' && (
        <Button
          className="tmx-input__viewIconCta"
          type="button"
          buttonIcon={iconType}
          iconSize="small"
          handleClick={(event) => {
            event.preventDefault();
            setIconType(iconType === 'eye-slash-solid' ? 'views' : 'eye-slash-solid');
            setInputType(iconType === 'views' ? 'password' : 'text');
          }}></Button>
      )}
      {inputIcon ? (
        <Button
          className="tmx-input__viewIconCta"
          type="button"
          buttonIcon={inputIcon}
          iconSize={iconSize}></Button>
      ) : (
        <></>
      )}
    </div>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  darkBorder: PropTypes.bool,
  dateMax: PropTypes.any,
  disabled: PropTypes.bool,
  disablePastTime: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  greyBorder: PropTypes.bool,
  htmlFor: PropTypes.string,
  iconSize: PropTypes.string,
  id: PropTypes.string,
  inputIcon: PropTypes.string,
  inputPrefix: PropTypes.string,
  inputPrefixClassName: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    })
  ]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  textarea: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'checkbox',
    'password',
    'date',
    'radio',
    'number',
    'email',
    'datetime-local'
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
};

Input.defaultProps = {
  greyBorder: false,
  type: 'text',
  id: '',
  name: '',
  value: '',
  autoComplete: 'off',
  dateMax: ''
};

export default Input;

import { useEffect, useState } from 'react';

export default function useScrollDisabling(value) {
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(value);
  }, [value]);

  useEffect(() => {
    if (isDisabled) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }

    const initialHeight = window.innerHeight;

    const metaViewport = document.querySelector('meta[name=viewport]');
    metaViewport.setAttribute(
      'content',
      `height=${initialHeight}px, width=device-width, initial-scale=1.0`
    );
  }, [isDisabled]);

  return {
    isDisabled,
    setDisabled
  };
}

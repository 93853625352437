import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import './SliderContent.scss';
import Icon from '../Icon/Icon';

const SliderContent = ({
  cleared,
  contentType,
  displayName,
  featureType,
  isInternational,
  location,
  platform,
  thumbnail,
  title,
  urlId,
  bannersLoading
}) => {
  const { loggedIn } = useSelector((state) => state.signInReducer);
  const [mouseMoved, setMouseMoved] = useState(false);
  const handleClick = () => {
    !mouseMoved && window.open(`https://www.usetmx.com${link}`, '_blank');
  };
  const link =
    bannersLoading || !loggedIn
      ? '#'
      : contentType === 'CONTACT'
      ? `/details?cId=${urlId}`
      : contentType === 'SCRAPER'
      ? `/details?id=${urlId}${'&socialScraper=true'}`
      : contentType === 'NEWSROOM'
      ? `/details?id=${urlId}`
      : '#';
  return (
    <div className="tmx-sliderContent__container">
      <div
        onMouseMove={() => setMouseMoved(true)}
        onMouseDown={() => setMouseMoved(false)}
        onMouseUp={() => handleClick()}
        tabIndex="-1"
        role="button"
        className="tmx-sliderContent__linkItem">
        <div
          className={classNames('tmx-sliderContent', {
            'tmx-sliderContent--loading': bannersLoading
          })}>
          {!bannersLoading ? (
            <img src={thumbnail} alt="slide" className="tmx-sliderContent__image" />
          ) : null}
          <div className="tmx-sliderContent__profileContent">
            <div className="tmx-sliderContent__actionsWrapper">
              <div className="tmx-sliderContent__profilePic">
                {bannersLoading ? (
                  <span className="tmx-sliderContent__profileInitial tmx-sliderContent__profileInitial--loading"></span>
                ) : platform === 'twitter' ? (
                  <Icon name="twitter" size="huge" className="tmx-sliderContent__profileInitial" />
                ) : platform === 'youtube' ? (
                  <Icon name="youtube" size="huge" className="tmx-sliderContent__profileInitial" />
                ) : platform === 'tiktok' ? (
                  <Icon name="tiktok" size="huge" className="tmx-sliderContent__profileInitial" />
                ) : platform === 'facebook' ? (
                  <Icon
                    name="facebook_circle"
                    size="huge"
                    className="tmx-sliderContent__profileInitial"
                  />
                ) : platform === 'instagram' ? (
                  <Icon
                    name="instagram_circle"
                    size="huge"
                    className="tmx-sliderContent__profileInitial"
                  />
                ) : platform === 'reddit' ? (
                  <Icon
                    name="reddit_circle"
                    size="huge"
                    className="tmx-sliderContent__profileInitial"
                  />
                ) : platform === 'website' ? (
                  <Icon
                    name="logo-small"
                    size="micro"
                    className="tmx-sliderContent__profileInitial tmx-sliderContent__profileInitial--onlyText"
                  />
                ) : (
                  <span className="tmx-sliderContent__profileInitial tmx-sliderContent__profileInitial--onlyText">
                    {displayName ? displayName.split('')[0] : ''}
                  </span>
                )}

                {!bannersLoading ? (
                  <p className="tmx-sliderContent__companyName">
                    {platform === 'twitter' ? `${displayName}` : displayName}
                  </p>
                ) : (
                  <div className="tmx-sliderContent__dummyName"></div>
                )}
              </div>
            </div>
          </div>
          <div className="tmx-sliderContent__titleContent">
            {!bannersLoading ? (
              <p className="tmx-sliderContent__title">
                {cleared ? <span className="tmx-sliderContent__clearedTitle">CLEARED:</span> : null}{' '}
                {title}
              </p>
            ) : (
              <>
                <div className="tmx-sliderContent__dummyTitle1"></div>
                <div className="tmx-sliderContent__dummyTitle2"></div>
              </>
            )}
          </div>

          {!bannersLoading ? (
            <div className="tmx-sliderContent__locationContent">
              <div
                className={classNames('tmx-sliderContent__locationContainer', {
                  'tmx-sliderContent__noLocation': !location
                })}>
                <Icon
                  name={isInternational ? 'location-international' : 'locationBanner'}
                  className="tmx-sliderContent__locationIcon"
                  size="micro"
                />
                <div className="tmx-sliderContent__location">{location}</div>
              </div>

              <span className="tmx-sliderContent__bannerType">* {featureType}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

SliderContent.propTypes = {
  title: PropTypes.string,
  cleared: PropTypes.bool,
  contentType: PropTypes.string,
  displayName: PropTypes.string,
  featureType: PropTypes.string,
  isInternational: PropTypes.bool,
  location: PropTypes.any,
  platform: PropTypes.string,
  thumbnail: PropTypes.string,
  urlId: PropTypes.string,
  bannersLoading: PropTypes.bool
};

export default SliderContent;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import './OrganizationSetting.scss';

import Search from '../../components/molecules/Search/Search';
import Button from '../../components/atoms/Button/Button';

import { adminOrgs } from '../../api/actions/org-actions';

const OrganizationSetting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, organizations } = useSelector((state) => state.orgReducer);

  const [search, setSearch] = useState(false);

  const orgs = search ? organizations?.orgs : organizations?.orgs?.slice(-20).sort().reverse();

  useEffect(() => {
    dispatch(adminOrgs());
  }, [dispatch]);

  const onSearch = (searchInput) => {
    setSearch(true);
    dispatch(adminOrgs(searchInput));
  };

  const onChange = (setInputValue, inputValue) => {
    setInputValue(inputValue);
    if (!inputValue) {
      setSearch(false);
      dispatch(adminOrgs());
    }
  };

  const renderMockContent = () => {
    let elements = [];
    for (let i = 1; i <= 20; i++) {
      elements.push(<div className="tmx-organizationSetting__mockContent" key={i}></div>);
    }
    return elements;
  };

  return (
    <section className="tmx-organizationSetting">
      <h3 className="tmx-organizationSetting__title">Search Organizations</h3>
      <div className="tmx-organizationSetting__searchWrapper">
        <Search onSearch={onSearch} onChange={onChange} />
      </div>
      {loading ? (
        <div className="tmx-organizationSetting__mock"></div>
      ) : (
        <p className="tmx-organizationSetting__count">
          {!search ? '20 most recently created orgs' : `${orgs?.length} Results`}
        </p>
      )}
      <div className="tmx-organizationSetting__result">
        {loading ? (
          <>{renderMockContent()}</>
        ) : (
          <>
            {orgs?.map((org, index) => (
              <div
                className={classNames('tmx-organizationSetting__content', { search: search })}
                key={index}>
                <Button
                  className={classNames('tmx-organizationSetting__contentTitle', {
                    search: search
                  })}
                  handleClick={() =>
                    history.push('/organization-details', {
                      orgId: org?.company_id
                    })
                  }>
                  {search ? org?.org_name : org?.company_name}
                </Button>
                {search && (
                  <div
                    className={classNames('tmx-organizationSetting__userDetails', {
                      search: search
                    })}>
                    <div className="tmx-organizationSetting__userProperty">User: {org?.user}</div>
                    <div className="tmx-organizationSetting__userProperty">Email: {org?.email}</div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default OrganizationSetting;
